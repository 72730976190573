// src/core/alert.tsx
import clsx from "clsx";
import { forwardRef } from "react";
import { HiCheckCircle, HiExclamationTriangle, HiInformationCircle, HiXCircle } from "react-icons/hi2";
import { twMerge } from "tailwind-merge";
import { jsx, jsxs } from "react/jsx-runtime";
var colors = {
  default: {
    outlined: "bg-white border border-primary text-primary",
    filled: "bg-primary/5 text-primary border border-primary"
  },
  success: {
    outlined: "bg-white border border-success text-success",
    filled: "bg-success/10 text-success border border-success"
  },
  danger: {
    outlined: "bg-white border border-danger text-danger",
    filled: "bg-danger/10 text-danger border border-danger"
  },
  warning: {
    outlined: "bg-white border border-warning text-warning",
    filled: "bg-warning/10 text-warning border border-warning"
  }
};
var icons = {
  default: HiInformationCircle,
  secondary: HiInformationCircle,
  success: HiCheckCircle,
  danger: HiXCircle,
  warning: HiExclamationTriangle
};
var Root = forwardRef(({ children, className, color = "default", filled = false, ...props }, ref) => {
  const Icon = icons[color];
  return /* @__PURE__ */ jsxs(
    "div",
    {
      role: "alert",
      className: twMerge(
        clsx(
          "[&>svg]:text-foreground relative w-full rounded-lg border p-4 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg~*]:pl-7",
          filled ? colors[color].filled : colors[color].outlined
        ),
        className
      ),
      ref,
      ...props,
      children: [
        /* @__PURE__ */ jsx(Icon, {}),
        children
      ]
    }
  );
});
var Title = forwardRef(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx("h5", { ref, className: twMerge("mb-1.5 font-medium leading-5 tracking-tight", className), ...props });
});
var Description = forwardRef(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx("div", { ref, className: twMerge("text-sm", className), ...props });
});
var Alert = {
  Root,
  Title,
  Description
};

// src/core/avatar.tsx
import * as RiAvatar from "@radix-ui/react-avatar";
import clsx2 from "clsx";
import { createContext, useContext } from "react";
import { twMerge as twMerge2 } from "tailwind-merge";
import { jsx as jsx2, jsxs as jsxs2 } from "react/jsx-runtime";
var sizes = {
  xs: "h-7 w-7 text-xs",
  sm: "h-8 w-8 text-sm",
  md: "h-10 w-10 text-md",
  lg: "h-12 w-12 text-lg",
  xl: "h-14 w-14 text-xl"
};
var textSizes = {
  xs: {
    title: "text-xs",
    subtitle: "text-xs"
  },
  sm: {
    title: "text-sm",
    subtitle: "text-xs"
  },
  md: {
    title: "text-base",
    subtitle: "text-sm"
  },
  lg: {
    title: "text-base",
    subtitle: "text-sm"
  },
  xl: {
    title: "text-lg",
    subtitle: "text-base"
  }
};
var RootContext = createContext({ size: "md" });
var Root3 = ({ children, className, size = "md", isSquare = false, src, fallback = /* @__PURE__ */ jsx2(Fallback2, {}) }) => {
  return /* @__PURE__ */ jsx2(RootContext.Provider, { value: { size }, children: /* @__PURE__ */ jsxs2("div", { className: "flex items-center", children: [
    /* @__PURE__ */ jsxs2(
      RiAvatar.Root,
      {
        className: twMerge2(
          clsx2(
            "flex flex-shrink-0 select-none items-center justify-center overflow-hidden align-middle",
            sizes[size],
            isSquare ? "rounded-md" : "rounded-full"
          ),
          className
        ),
        children: [
          /* @__PURE__ */ jsx2(RiAvatar.Image, { className: "h-full w-full rounded-[inherit] object-contain", src, alt: "Avatar" }),
          /* @__PURE__ */ jsx2(
            RiAvatar.Fallback,
            {
              className: "leading-1 bg-primary-950 flex h-full w-full items-center justify-center font-medium text-white",
              delayMs: 600,
              children: fallback
            }
          )
        ]
      }
    ),
    children && /* @__PURE__ */ jsx2("div", { className: "ml-2 text-left", children })
  ] }) });
};
var Title2 = ({ className, children }) => {
  const { size } = useContext(RootContext);
  return /* @__PURE__ */ jsx2("p", { className: twMerge2(clsx2(textSizes[size].title, "font-medium text-gray-700"), className), children });
};
var Subtitle = ({ className, children }) => {
  const { size } = useContext(RootContext);
  return /* @__PURE__ */ jsx2("p", { className: twMerge2(clsx2(textSizes[size].subtitle, "font-medium text-gray-500"), className), children });
};
var Fallback2 = () => {
  return /* @__PURE__ */ jsx2("svg", { className: "h-full w-full text-white opacity-80", fill: "currentColor", viewBox: "0 0 24 24", children: /* @__PURE__ */ jsx2("path", { d: "M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" }) });
};
var Avatar = {
  Root: Root3,
  Title: Title2,
  Subtitle
};

// src/core/badge.tsx
import clsx3 from "clsx";
import { twMerge as twMerge3 } from "tailwind-merge";
import { jsx as jsx3, jsxs as jsxs3 } from "react/jsx-runtime";
var sizes2 = {
  sm: "px-1.5 py-0.5 text-xs"
};
var colors2 = {
  primary: "bg-primary text-white",
  secondary: "bg-secondary text-white",
  success: "bg-success text-white",
  danger: "bg-danger text-white",
  warning: "bg-warning text-white"
};
var variants = {
  solid: colors2,
  soft: {
    primary: "bg-primary-50 ring-1 ring-inset ring-primary/10 text-primary",
    secondary: "bg-secondary-50 ring-1 ring-inset ring-secondary/10 text-secondary",
    success: "bg-success-50 ring-1 ring-inset ring-success/10 text-success",
    danger: "bg-danger-50 ring-1 ring-inset ring-danger/10 text-danger",
    warning: "bg-warning-50 ring-1 ring-inset ring-warning/10 text-warning"
  }
};
var Badge = ({
  children,
  className,
  color = "primary",
  variant = "soft",
  size,
  icon: Icon,
  iconPosition = "left",
  iconClassName,
  ...props
}) => {
  return /* @__PURE__ */ jsxs3(
    "span",
    {
      className: twMerge3(
        clsx3(
          variants[variant][color],
          size != null ? sizes2[size] : "px-2 py-1 text-xs",
          "inline-flex items-center justify-center space-x-1.5 rounded-md font-medium"
        ),
        className
      ),
      ...props,
      children: [
        Icon != null && iconPosition === "left" && /* @__PURE__ */ jsx3(Icon, { className: iconClassName }),
        children != null && /* @__PURE__ */ jsx3("span", { children }),
        Icon != null && iconPosition === "right" && /* @__PURE__ */ jsx3(Icon, { className: iconClassName })
      ]
    }
  );
};

// src/core/button.tsx
import { Slot } from "@radix-ui/react-slot";
import clsx5 from "clsx";
import { forwardRef as forwardRef2 } from "react";
import { twMerge as twMerge4 } from "tailwind-merge";

// src/core/loader.tsx
import clsx4 from "clsx";
import { jsx as jsx4, jsxs as jsxs4 } from "react/jsx-runtime";
var sizes3 = {
  xs: "w-3 h-3",
  sm: "w-4 h-4",
  md: "w-5 h-5",
  lg: "w-6 h-6",
  xl: "w-7 h-7"
};
var Loader = ({ size = "md" }) => {
  return /* @__PURE__ */ jsx4("div", { className: clsx4(sizes3[size]), children: /* @__PURE__ */ jsxs4("svg", { className: "animate-spin text-inherit", xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", children: [
    /* @__PURE__ */ jsx4("circle", { className: "opacity-25", cx: "12", cy: "12", r: "10", stroke: "currentColor", strokeWidth: "4" }),
    /* @__PURE__ */ jsx4("path", { className: "opacity-75", fill: "currentColor", d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z" })
  ] }) });
};

// src/core/button.tsx
import { jsx as jsx5, jsxs as jsxs5 } from "react/jsx-runtime";
var sizes4 = {
  xs: "px-2 py-1 text-xs",
  sm: "px-2 py-1 text-sm",
  md: "px-2.5 py-1.5 text-sm",
  lg: "px-3 py-2 text-sm",
  xl: "px-3.5 py-2.5 text-sm"
};
var colors3 = {
  primary: "bg-primary border border-primary hover:bg-primary/80 hover:border-primary/80 text-white focus:ring-primary",
  secondary: "bg-secondary border border-secondary hover:bg-secondary/80 hover:border-secondary/80 text-white focus:ring-secondary",
  gray: "bg-gray-300 border border-gray-300 hover:bg-gray-400 hover:border-gray-400 text-gray-900 focus:ring-gray-950",
  success: "bg-success border border-success hover:bg-success/80 hover:border-success/80 text-white focus:ring-success",
  danger: "bg-danger border border-danger hover:bg-danger/80 hover:border-danger/80 text-white focus:ring-danger",
  warning: "bg-warning border border-warning hover:bg-warning/80 hover:border-warning/80 text-white focus:ring-warning"
};
var variants2 = {
  solid: colors3,
  soft: {
    primary: "bg-primary-100 border border-primary-300 hover:bg-primary-200 hover:border-primary-200 text-primary-700 focus:ring-primary",
    secondary: "bg-secondary-100 border border-secondary-300 hover:bg-secondary-200 hover:border-secondary-200 text-secondary-700 focus:ring-secondary",
    gray: "bg-gray-100 border border-gray-300 hover:bg-gray-200 hover:border-gray-200 text-gray-700 focus:ring-gray-950",
    success: "bg-success-100 border border-success-300 hover:bg-success-200 hover:border-success-200 text-success-700 focus:ring-success",
    danger: "bg-danger-100 border border-danger-300 hover:bg-danger-200 hover:border-danger-200 text-danger-700 focus:ring-danger",
    warning: "bg-warning-100 border border-warning-300 hover:bg-warning-200 hover:border-warning-200 text-warning-700 focus:ring-warning"
  },
  outline: {
    primary: "bg-transparent border border-primary hover:bg-primary/10 text-primary focus:ring-primary",
    secondary: "bg-transparent border border-secondary hover:bg-secondary/10 text-secondary focus:ring-secondary",
    gray: "bg-transparent border border-gray-300 hover:bg-gray/10 text-gray-950 focus:ring-gray-950",
    success: "bg-transparent border border-success hover:bg-success/10 text-success focus:ring-success",
    danger: "bg-transparent border border-danger hover:bg-danger/10 text-danger focus:ring-danger",
    warning: "bg-transparent border border-warning hover:bg-warning/10 text-warning focus:ring-warning"
  },
  ghost: {
    primary: "bg-transparent border border-transparent hover:bg-primary/10 hover:border-primary/10 text-primary focus:ring-primary",
    secondary: "bg-transparent border border-transparent hover:bg-secondary/10 hover:border-secondary/10 text-secondary focus:ring-secondary",
    gray: "bg-transparent border border-transparent hover:bg-gray/10 hover:border-gray/10 text-gray-950 focus:ring-gray-950",
    success: "bg-transparent border border-transparent hover:bg-success/10 hover:border-success/10 text-success focus:ring-success",
    danger: "bg-transparent border border-transparent hover:bg-danger/10 hover:border-danger/10 text-danger focus:ring-danger",
    warning: "bg-transparent border border-transparent hover:bg-warning/10 hover:border-warning/10 text-warning focus:ring-warning"
  }
};
var Button = forwardRef2(
  ({
    className,
    size = "md",
    variant = "solid",
    color = "primary",
    isFullWidth = false,
    isLoading = false,
    icon: Icon,
    iconPosition = "left",
    asChild = false,
    ...props
  }, ref) => {
    const Comp = asChild ? Slot : "button";
    return /* @__PURE__ */ jsx5(
      Comp,
      {
        className: twMerge4(
          clsx5(
            sizes4[size],
            variants2[variant][color],
            isFullWidth && "w-full",
            "inline-block rounded font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          ),
          className
        ),
        ref,
        ...props,
        children: /* @__PURE__ */ jsxs5("div", { className: "relative", children: [
          isLoading && /* @__PURE__ */ jsx5("div", { className: "absolute inset-0 left-0 flex items-center justify-center", children: /* @__PURE__ */ jsx5(Loader, { size }) }),
          /* @__PURE__ */ jsx5("div", { className: clsx5(isLoading && "invisible"), children: /* @__PURE__ */ jsxs5("div", { className: "flex items-center justify-center space-x-1.5", children: [
            Icon != null && iconPosition === "left" && /* @__PURE__ */ jsx5(Icon, {}),
            props.children != null && /* @__PURE__ */ jsx5("span", { className: "text-nowrap", children: props.children }),
            Icon != null && iconPosition === "right" && /* @__PURE__ */ jsx5(Icon, {})
          ] }) })
        ] })
      }
    );
  }
);

// src/core/card.tsx
import clsx6 from "clsx";
import { createContext as createContext3, forwardRef as forwardRef11, useContext as useContext3 } from "react";
import { twMerge as twMerge10 } from "tailwind-merge";

// src/typography/code.tsx
import { forwardRef as forwardRef4 } from "react";

// src/typography/prose.tsx
import { forwardRef as forwardRef3 } from "react";
import { twMerge as twMerge5 } from "tailwind-merge";
import { jsx as jsx6 } from "react/jsx-runtime";
var Prose = forwardRef3(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx6("div", { ref, className: twMerge5("prose prose-sm marker:text-black", className), ...props });
});

// src/typography/code.tsx
import { jsx as jsx7 } from "react/jsx-runtime";
var Code = forwardRef4(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx7(Prose, { className, children: /* @__PURE__ */ jsx7("code", { ref, ...props }) });
});

// src/typography/description-list.tsx
import { createContext as createContext2, forwardRef as forwardRef5, useContext as useContext2 } from "react";
import { twMerge as twMerge6 } from "tailwind-merge";
import { jsx as jsx8 } from "react/jsx-runtime";
var justifies = {
  start: "flex flex-col items-start space-y-1",
  center: "flex flex-col items-center space-y-1",
  end: "flex flex-col items-end space-y-1",
  between: "flex justify-between items-center space-x-4"
};
var DescriptionListContext = createContext2({
  justify: "start"
});
var Root4 = forwardRef5(({ className, justify = "start", ...props }, ref) => {
  return /* @__PURE__ */ jsx8(DescriptionListContext.Provider, { value: { justify }, children: /* @__PURE__ */ jsx8("dl", { ref, className: twMerge6("space-y-4 text-sm", className), ...props }) });
});
var Item = forwardRef5(({ className, ...props }, ref) => {
  const { justify } = useContext2(DescriptionListContext);
  return /* @__PURE__ */ jsx8("div", { ref, className: twMerge6(justifies[justify ?? "start"], className), ...props });
});
var Term = forwardRef5(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx8("dt", { ref, className: twMerge6("text-muted", className), ...props });
});
var Detail = forwardRef5(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx8("dd", { ref, className: twMerge6("font-medium", className), ...props });
});

// src/typography/heading.tsx
import { forwardRef as forwardRef6 } from "react";
import { jsx as jsx9, jsxs as jsxs6 } from "react/jsx-runtime";
var Heading = forwardRef6(({ className, size, ...props }, ref) => {
  return /* @__PURE__ */ jsxs6(Prose, { className, children: [
    size === "1" && /* @__PURE__ */ jsx9("h1", { ref, className: "!my-0", ...props }),
    size === "2" && /* @__PURE__ */ jsx9("h2", { ref, className: "!my-0", ...props }),
    size === "3" && /* @__PURE__ */ jsx9("h3", { ref, className: "!my-0", ...props }),
    size === "4" && /* @__PURE__ */ jsx9("h4", { ref, className: "!my-0", ...props })
  ] });
});

// src/typography/label.tsx
import { forwardRef as forwardRef7 } from "react";
import { twMerge as twMerge7 } from "tailwind-merge";
import { jsx as jsx10 } from "react/jsx-runtime";
var Label = forwardRef7(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx10("label", { ref, className: twMerge7("block text-sm font-medium leading-6", className), ...props });
});

// src/typography/muted.tsx
import { forwardRef as forwardRef8 } from "react";
import { twMerge as twMerge8 } from "tailwind-merge";
import { jsx as jsx11 } from "react/jsx-runtime";
var Muted = forwardRef8(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx11("span", { ref, className: twMerge8("!text-muted-foreground", className), ...props });
});

// src/typography/paragraph.tsx
import { forwardRef as forwardRef9 } from "react";
import { jsx as jsx12 } from "react/jsx-runtime";
var Paragraph = forwardRef9(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx12(Prose, { className, children: /* @__PURE__ */ jsx12("p", { ref, className: "!my-0", ...props }) });
});

// src/typography/unordered-list.tsx
import { forwardRef as forwardRef10 } from "react";
import { twMerge as twMerge9 } from "tailwind-merge";
import { jsx as jsx13 } from "react/jsx-runtime";
var Root5 = forwardRef10(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx13("ul", { ref, className: twMerge9("space-y-2.5 text-sm marker:text-black", className), ...props });
});
var Item2 = forwardRef10(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx13("li", { ref, className: twMerge9(className), ...props });
});

// src/core/card.tsx
import { jsx as jsx14 } from "react/jsx-runtime";
var paddings = {
  sm: {
    top: "pt-4",
    bottom: "pb-4",
    left: "pl-4",
    right: "pr-4",
    x: "px-4",
    y: "py-4",
    all: "p-4",
    none: "p-0"
  },
  lg: {
    top: "pt-6",
    bottom: "pb-6",
    left: "pl-6",
    right: "pr-6",
    x: "px-6",
    y: "py-6",
    all: "p-6",
    none: "p-0"
  }
};
var insets = {
  sm: {
    top: "top-4",
    bottom: "bottom-4",
    left: "left-4",
    right: "right-4",
    x: "inset-x-4",
    y: "inset-y-4",
    all: "inset-4",
    none: "inset-0"
  },
  lg: {
    top: "top-6",
    bottom: "bottom-6",
    left: "left-6",
    right: "right-6",
    x: "inset-x-6",
    y: "inset-y-6",
    all: "inset-6",
    none: "inset-0"
  }
};
function transpilePadding(padding, size) {
  if (Array.isArray(padding)) {
    return padding.map((p) => paddings[size][p]).join(" ");
  }
  return paddings[size][padding];
}
function transpileInset(position, size) {
  if (Array.isArray(position)) {
    return position.map((p) => insets[size][p]).join(" ");
  }
  return insets[size][position];
}
var Root6 = forwardRef11(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx14("div", { ref, className: twMerge10("rounded-lg border bg-white shadow-sm", className), ...props });
});
var HeaderContext = createContext3({ padding: "all", paddingSize: "lg" });
var Header = forwardRef11(({ className, padding = "all", paddingSize = "lg", ...props }, ref) => {
  return /* @__PURE__ */ jsx14(HeaderContext.Provider, { value: { padding, paddingSize }, children: /* @__PURE__ */ jsx14("div", { ref, className: twMerge10(clsx6(transpilePadding(padding, paddingSize), "relative flex flex-col"), className), ...props }) });
});
var Title3 = forwardRef11(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx14(Heading, { ref, size: "2", className: twMerge10("mb-1.5", className), ...props });
});
var Description2 = forwardRef11(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx14(Paragraph, { ref, className, ...props });
});
var Corner = forwardRef11(({ className, position = ["top", "right"], ...props }, ref) => {
  const { paddingSize = "lg" } = useContext3(HeaderContext);
  return /* @__PURE__ */ jsx14("div", { ref, className: twMerge10(clsx6(transpileInset(position, paddingSize), "absolute"), className), ...props });
});
var Section = forwardRef11(({ className, padding = "x", paddingSize = "lg", ...props }, ref) => {
  return /* @__PURE__ */ jsx14("div", { ref, className: twMerge10(transpilePadding(padding, paddingSize), className), ...props });
});
var SectionTitle = ({ children, className }) => {
  return /* @__PURE__ */ jsx14(Heading, { size: "3", className: twMerge10("mb-3", className), children });
};
var Separator = forwardRef11(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx14("hr", { ref, className: twMerge10("mx-6 my-6", className), ...props });
});
var Footer = forwardRef11(({ className, padding = "all", paddingSize = "lg", ...props }, ref) => {
  return /* @__PURE__ */ jsx14("div", { ref, className: twMerge10(clsx6(transpilePadding(padding, paddingSize), "flex items-center"), className), ...props });
});
var PaddingContainer = ({ children, padding = "x", paddingSize = "lg" }) => {
  return /* @__PURE__ */ jsx14("div", { className: transpilePadding(padding, paddingSize), children });
};
var Card = {
  Root: Root6,
  Header,
  Title: Title3,
  Description: Description2,
  Corner,
  Section,
  SectionTitle,
  Separator,
  Footer,
  Padding: PaddingContainer
};

// src/core/carousel.tsx
import clsx8 from "clsx";
import useEmblaCarousel from "embla-carousel-react";
import { createContext as createContext4, forwardRef as forwardRef13, useCallback, useContext as useContext4, useEffect, useState } from "react";
import { HiArrowLeft, HiArrowRight } from "react-icons/hi2";
import { twMerge as twMerge12 } from "tailwind-merge";

// src/core/icon-button.tsx
import { Slot as Slot2 } from "@radix-ui/react-slot";
import clsx7 from "clsx";
import { forwardRef as forwardRef12 } from "react";
import { twMerge as twMerge11 } from "tailwind-merge";
import { jsx as jsx15 } from "react/jsx-runtime";
var sizes5 = {
  xxs: "p-0.5",
  xs: "p-0.5",
  sm: "p-1",
  md: "p-1.5",
  lg: "p-2"
};
var iconSizes = {
  xxs: "h-3 w-3",
  xs: "h-4 w-4",
  sm: "h-5 w-5",
  md: "h-5 w-5",
  lg: "h-6 w-6"
};
var colors4 = {
  primary: "bg-primary border border-primary hover:bg-primary/80 hover:border-primary/80 text-white focus:ring-primary",
  secondary: "bg-secondary border border-secondary hover:bg-secondary/80 hover:border-secondary/80 focus:ring-secondary text-white",
  danger: "bg-danger border border-danger hover:bg-danger/80 hover:border-danger/80 focus:ring-danger text-white",
  warning: "bg-warning border border-warning hover:bg-warning/80 hover:border-warning/80 focus:ring-warning text-white",
  success: "bg-success border border-success hover:bg-success/80 hover:border-success/80 focus:ring-success text-white"
};
var variants3 = {
  solid: colors4,
  ghost: {
    primary: "bg-transparent border border-transparent hover:bg-primary/10 hover:border-primary/10 text-primary focus:ring-primary",
    secondary: "bg-transparent border border-transparent hover:bg-secondary/10 hover:border-secondary/10 text-secondary focus:ring-secondary",
    danger: "bg-transparent border border-transparent hover:bg-danger/10 hover:border-danger/10 text-danger focus:ring-danger",
    warning: "bg-transparent border border-transparent hover:bg-warning/10 hover:border-warning/10 text-warning focus:ring-warning",
    success: "bg-transparent border border-transparent hover:bg-success/10 hover:border-success/10 text-success focus:ring-success"
  }
};
var IconButton = forwardRef12(
  ({ className, icon: Icon, size = "md", variant = "solid", color = "primary", asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot2 : "button";
    return /* @__PURE__ */ jsx15(
      Comp,
      {
        ref,
        type: "button",
        className: twMerge11(
          clsx7(
            "inline-flex items-center justify-center rounded-full font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            sizes5[size],
            variants3[variant][color]
          ),
          className
        ),
        ...props,
        children: /* @__PURE__ */ jsx15(Icon, { className: iconSizes[size] })
      }
    );
  }
);

// src/core/carousel.tsx
import { jsx as jsx16 } from "react/jsx-runtime";
var CarouselContext = createContext4(null);
function useCarousel() {
  const context = useContext4(CarouselContext);
  if (!context) {
    throw new Error("useCarousel must be used within a <Carousel />");
  }
  return context;
}
var Root7 = forwardRef13(
  ({ orientation = "horizontal", opts, setApi, plugins, className, children, ...props }, ref) => {
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        axis: orientation === "horizontal" ? "x" : "y"
      },
      plugins
    );
    const [canScrollPrev, setCanScrollPrev] = useState(false);
    const [canScrollNext, setCanScrollNext] = useState(false);
    const onSelect = useCallback((api2) => {
      if (!api2) {
        return;
      }
      setCanScrollPrev(api2.canScrollPrev());
      setCanScrollNext(api2.canScrollNext());
    }, []);
    const scrollPrev = useCallback(() => {
      api?.scrollPrev();
    }, [api]);
    const scrollNext = useCallback(() => {
      api?.scrollNext();
    }, [api]);
    const handleKeyDown = useCallback(
      (event) => {
        if (event.key === "ArrowLeft") {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === "ArrowRight") {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext]
    );
    useEffect(() => {
      if (!api || !setApi) {
        return;
      }
      setApi(api);
    }, [api, setApi]);
    useEffect(() => {
      if (!api) {
        return;
      }
      onSelect(api);
      api.on("reInit", onSelect);
      api.on("select", onSelect);
      return () => {
        api?.off("select", onSelect);
      };
    }, [api, onSelect]);
    return /* @__PURE__ */ jsx16(
      CarouselContext.Provider,
      {
        value: {
          carouselRef,
          api,
          opts,
          orientation: orientation || (opts?.axis === "y" ? "vertical" : "horizontal"),
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext
        },
        children: /* @__PURE__ */ jsx16(
          "div",
          {
            ref,
            onKeyDownCapture: handleKeyDown,
            className: twMerge12("relative", className),
            role: "region",
            "aria-roledescription": "carousel",
            ...props,
            children
          }
        )
      }
    );
  }
);
var Content = forwardRef13(({ className, ...props }, ref) => {
  const { carouselRef, orientation } = useCarousel();
  return /* @__PURE__ */ jsx16("div", { ref: carouselRef, className: "overflow-hidden", children: /* @__PURE__ */ jsx16("div", { ref, className: twMerge12(clsx8(orientation === "horizontal" ? "-ml-4" : "-mt-4 flex-col", "flex"), className), ...props }) });
});
var Item3 = forwardRef13(({ className, ...props }, ref) => {
  const { orientation } = useCarousel();
  return /* @__PURE__ */ jsx16(
    "div",
    {
      ref,
      role: "group",
      "aria-roledescription": "slide",
      className: twMerge12(clsx8(orientation === "horizontal" ? "pl-4" : "pt-4", "min-w-0 shrink-0 grow-0 basis-full"), className),
      ...props
    }
  );
});
var Previous = forwardRef13(
  ({ className, icon: Icon = HiArrowLeft, ...props }, ref) => {
    const { orientation, scrollPrev, canScrollPrev } = useCarousel();
    return /* @__PURE__ */ jsx16(
      IconButton,
      {
        ref,
        icon: Icon,
        variant: "ghost",
        className: twMerge12(
          clsx8(
            orientation === "horizontal" ? "-left-12 top-1/2 -translate-y-1/2" : "-top-12 left-1/2 -translate-x-1/2 rotate-90",
            "absolute  h-8 w-8 rounded-full"
          ),
          className
        ),
        disabled: !canScrollPrev,
        onClick: scrollPrev,
        ...props
      }
    );
  }
);
var Next = forwardRef13(
  ({ className, icon: Icon = HiArrowRight, ...props }, ref) => {
    const { orientation, scrollNext, canScrollNext } = useCarousel();
    return /* @__PURE__ */ jsx16(
      IconButton,
      {
        ref,
        icon: Icon,
        variant: "ghost",
        className: twMerge12(
          clsx8(
            orientation === "horizontal" ? "-right-12 top-1/2 -translate-y-1/2" : "-bottom-12 left-1/2 -translate-x-1/2 rotate-90",
            "absolute h-8 w-8 rounded-full"
          ),
          className
        ),
        disabled: !canScrollNext,
        onClick: scrollNext,
        ...props
      }
    );
  }
);
var Carousel = {
  Root: Root7,
  Content,
  Item: Item3,
  Previous,
  Next
};

// src/core/combobox.tsx
import clsx11 from "clsx";
import { createContext as createContext5, useContext as useContext5, useState as useState2 } from "react";
import { HiCheck as HiCheck2, HiXCircle as HiXCircle2 } from "react-icons/hi2";
import { twMerge as twMerge17 } from "tailwind-merge";

// src/core/command.tsx
import clsx10 from "clsx";
import { Command as CMDK } from "cmdk";
import { forwardRef as forwardRef15 } from "react";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { twMerge as twMerge14 } from "tailwind-merge";

// src/core/dialog.tsx
import * as RxDialog from "@radix-ui/react-dialog";
import clsx9 from "clsx";
import { forwardRef as forwardRef14 } from "react";
import { HiXMark } from "react-icons/hi2";
import { twMerge as twMerge13 } from "tailwind-merge";
import { jsx as jsx17, jsxs as jsxs7 } from "react/jsx-runtime";
var sizes6 = {
  xs: "max-w-xs",
  sm: "max-w-sm",
  md: "max-w-md",
  lg: "max-w-lg",
  xl: "max-w-xl",
  "2xl": "max-w-2xl",
  "3xl": "max-w-3xl",
  "4xl": "max-w-4xl",
  "5xl": "max-w-5xl",
  "6xl": "max-w-6xl",
  "7xl": "max-w-7xl",
  full: "h-screen w-screen rounded-none"
};
var paddings2 = {
  top: "pt-6",
  bottom: "pb-6",
  left: "pl-6",
  right: "pr-6",
  x: "px-6",
  y: "py-6",
  all: "p-6",
  none: "p-0"
};
function transpilePadding2(padding) {
  if (Array.isArray(padding)) {
    return padding.map((p) => paddings2[p]).join(" ");
  }
  return paddings2[padding];
}
var Overlay2 = forwardRef14(
  ({ className, ...props }, ref) => /* @__PURE__ */ jsx17(
    RxDialog.Overlay,
    {
      ref,
      className: twMerge13(
        "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 bg-black/80",
        className
      ),
      ...props
    }
  )
);
var Content3 = forwardRef14(
  ({ className, size = "md", children, ...props }, ref) => {
    return /* @__PURE__ */ jsxs7(RxDialog.Portal, { children: [
      /* @__PURE__ */ jsx17(Overlay2, {}),
      /* @__PURE__ */ jsxs7(
        RxDialog.Content,
        {
          ref,
          className: twMerge13(
            clsx9(
              "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] fixed left-[50%] top-[50%] z-50 grid w-full translate-x-[-50%] translate-y-[-50%] rounded-md border bg-white shadow-lg",
              sizes6[size]
            ),
            className
          ),
          ...props,
          children: [
            children,
            /* @__PURE__ */ jsxs7(RxDialog.Close, { className: "absolute right-4 top-4 rounded-sm opacity-70 hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none", children: [
              /* @__PURE__ */ jsx17(HiXMark, { className: "h-4 w-4" }),
              /* @__PURE__ */ jsx17("span", { className: "sr-only", children: "Close" })
            ] })
          ]
        }
      )
    ] });
  }
);
var Section2 = ({ className, padding = "x", ...props }) => {
  return /* @__PURE__ */ jsx17("div", { className: twMerge13(transpilePadding2(padding), className), ...props });
};
var Header2 = ({ className, padding = "all", ...props }) => {
  return /* @__PURE__ */ jsx17("div", { className: twMerge13(clsx9(transpilePadding2(padding), "flex flex-col space-y-1.5"), className), ...props });
};
var Title5 = forwardRef14(
  ({ className, ...props }, ref) => {
    return /* @__PURE__ */ jsx17(RxDialog.Title, { ref, asChild: true, children: /* @__PURE__ */ jsx17(Heading, { size: "3", className, ...props }) });
  }
);
var Description3 = forwardRef14(
  ({ className, ...props }, ref) => {
    return /* @__PURE__ */ jsx17(RxDialog.Title, { ref, asChild: true, children: /* @__PURE__ */ jsx17(Paragraph, { className, ...props }) });
  }
);
var Separator2 = forwardRef14(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx17("hr", { ref, className: twMerge13("mx-6 my-6", className), ...props });
});
var Footer2 = forwardRef14(({ className, padding = "all", ...props }, ref) => {
  return /* @__PURE__ */ jsx17(
    "div",
    {
      ref,
      className: twMerge13(clsx9(transpilePadding2(padding), "flex w-full items-center justify-end space-x-2 bg-white"), className),
      ...props
    }
  );
});
var Dialog = {
  Root: RxDialog.Root,
  Trigger: RxDialog.Trigger,
  Content: Content3,
  Section: Section2,
  Header: Header2,
  Title: Title5,
  Description: Description3,
  Separator: Separator2,
  Footer: Footer2,
  Close: RxDialog.Close
};

// src/core/command.tsx
import { jsx as jsx18, jsxs as jsxs8 } from "react/jsx-runtime";
var sizes7 = {
  sm: "[&_[cmdk-input-wrapper]_svg]:h-4 [&_[cmdk-input-wrapper]_svg]:w-4 [&_[cmdk-input]]:h-9 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-1.5 [&_[cmdk-item]_svg]:h-4 [&_[cmdk-item]_svg]:w-4",
  normal: "[&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5"
};
var Dialog2 = forwardRef15(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx18(CMDK, { className: twMerge14("flex h-full w-full flex-col rounded-md", className), ref, ...props });
});
var Root9 = ({
  children,
  size = "normal",
  asDialog = false,
  ...props
}) => {
  const comp = /* @__PURE__ */ jsx18(
    Dialog2,
    {
      className: clsx10(
        sizes7[size],
        "[&_[cmdk-group-heading]]:text-muted-foreground [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2"
      ),
      children
    }
  );
  if (!asDialog) {
    return comp;
  }
  return /* @__PURE__ */ jsx18(Dialog.Root, { ...props, children: /* @__PURE__ */ jsx18(Dialog.Content, { className: "overflow-hidden p-0 shadow-lg", children: comp }) });
};
var Input = forwardRef15(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsxs8("div", { className: twMerge14("flex items-center border-b px-3", className), children: [
    /* @__PURE__ */ jsx18(HiMagnifyingGlass, { className: "mr-2 h-4 w-4 shrink-0 opacity-50" }),
    /* @__PURE__ */ jsx18(
      CMDK.Input,
      {
        className: "flex h-11 w-full rounded-md border-none bg-transparent py-3 text-sm outline-none ring-0 focus:border-none focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50",
        ref,
        ...props
      }
    )
  ] });
});
var Field = forwardRef15(({ className, left, right, ...props }, ref) => {
  return /* @__PURE__ */ jsxs8("div", { className: "relative rounded", children: [
    left != null && /* @__PURE__ */ jsx18("div", { className: "pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3", children: /* @__PURE__ */ jsx18("span", { className: "text-gray-500 sm:text-sm", children: left }) }),
    /* @__PURE__ */ jsx18(
      CMDK.Input,
      {
        className: twMerge14(
          clsx10(
            left == null ? "pl-3" : "pl-8",
            right == null ? "pr-3" : "pr-12",
            "focus:ring-primary-950 block w-full rounded border-0 py-1.5 text-gray-900 ring-1 ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm sm:leading-6"
          ),
          className
        ),
        ref,
        ...props
      }
    ),
    right != null && /* @__PURE__ */ jsx18("div", { className: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3", children: /* @__PURE__ */ jsx18("span", { className: "text-gray-500 sm:text-sm", children: right }) })
  ] });
});
var List = forwardRef15(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx18(CMDK.List, { ref, className: twMerge14("max-h-[300px] overflow-y-auto overflow-x-hidden", className), ...props });
});
var Empty = forwardRef15((props, ref) => {
  return /* @__PURE__ */ jsx18(CMDK.Empty, { ref, className: "py-6 text-center text-sm", ...props });
});
var Group = forwardRef15(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx18(
    CMDK.Group,
    {
      ref,
      className: twMerge14(
        "text-foreground [&_[cmdk-group-heading]]:text-muted-foreground overflow-hidden p-1 [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-normal [&_[cmdk-group-heading]]:uppercase",
        className
      ),
      ...props
    }
  );
});
var Separator3 = forwardRef15(
  ({ className, ...props }, ref) => {
    return /* @__PURE__ */ jsx18(CMDK.Separator, { ref, className: twMerge14("-mx-1 my-1 h-px bg-gray-200", className), ...props });
  }
);
var Item4 = forwardRef15(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx18(
    CMDK.Item,
    {
      ref,
      className: twMerge14(
        "aria-selected:bg-primary-950 text-primary-950 relative flex cursor-default select-none items-center rounded-md px-2 py-1.5 text-sm outline-none aria-selected:text-white data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
        className
      ),
      ...props
    }
  );
});
var Shortcut = ({ className, ...props }) => {
  return /* @__PURE__ */ jsx18("span", { className: twMerge14("ml-auto text-xs tracking-widest", className), ...props });
};
var Command = {
  Root: Root9,
  Input,
  Field,
  List,
  Empty,
  Group,
  Separator: Separator3,
  Item: Item4,
  Shortcut
};

// src/core/form/checkbox.tsx
import * as RxCheckbox from "@radix-ui/react-checkbox";
import { forwardRef as forwardRef16 } from "react";
import { HiCheck } from "react-icons/hi2";
import { twMerge as twMerge15 } from "tailwind-merge";
import { jsx as jsx19, jsxs as jsxs9 } from "react/jsx-runtime";
var Root11 = forwardRef16(
  ({ children, className, ...props }, ref) => {
    return /* @__PURE__ */ jsxs9("div", { className: twMerge15("flex items-center space-x-2.5", className), children: [
      /* @__PURE__ */ jsx19(
        RxCheckbox.Root,
        {
          ref,
          className: "data-[state=checked]:bg-primary-950 focus:ring-primary-950 data-[state=checked]:border-primary-950 peer h-4 w-4 shrink-0 rounded-sm border border-gray-300 ring-offset-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:text-white",
          ...props,
          children: /* @__PURE__ */ jsx19(RxCheckbox.Indicator, { className: twMerge15("flex items-center justify-center text-current"), children: /* @__PURE__ */ jsx19(HiCheck, { className: "h-4 w-4" }) })
        }
      ),
      /* @__PURE__ */ jsx19("div", { className: "flex flex-col", children })
    ] });
  }
);
var Label2 = forwardRef16(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx19(Label, { ref, ...props });
});
var Description4 = forwardRef16(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx19(Paragraph, { ref, className: twMerge15("text-xs", className), ...props });
});
var Checkbox = {
  Root: Root11,
  Label: Label2,
  Description: Description4
};

// src/core/popover.tsx
import * as RxPopover from "@radix-ui/react-popover";
import { forwardRef as forwardRef17 } from "react";
import { twMerge as twMerge16 } from "tailwind-merge";
import { jsx as jsx20 } from "react/jsx-runtime";
var Content5 = forwardRef17(
  ({ className, align = "center", sideOffset = 4, ...props }, ref) => {
    return /* @__PURE__ */ jsx20(RxPopover.Portal, { children: /* @__PURE__ */ jsx20(
      RxPopover.Content,
      {
        align,
        sideOffset,
        className: twMerge16("z-50 w-72 rounded-md border bg-white p-4 shadow-md outline-none", className),
        ...props,
        ref
      }
    ) });
  }
);
var Popover = {
  Root: RxPopover.Root,
  Trigger: RxPopover.Trigger,
  Content: Content5
};

// src/core/combobox.tsx
import { Fragment, jsx as jsx21, jsxs as jsxs10 } from "react/jsx-runtime";
var RootContext2 = createContext5({
  label: "Select",
  selected: [],
  items: [],
  multiple: false,
  closeOnSelect: false,
  onSelect: (value) => value,
  onClear: () => {
  }
});
var Root13 = ({ children, label, selected = [], items, multiple = false, closeOnSelect = false, onSelect }) => {
  const [open, setOpen] = useState2(false);
  function handleOnSelect(value) {
    if (multiple) {
      onSelect(selected.includes(value) ? selected.filter((item) => item !== value) : [...selected, value]);
    } else {
      onSelect([value]);
    }
    closeOnSelect && setOpen(false);
  }
  function handleOnClear() {
    onSelect([]);
  }
  return /* @__PURE__ */ jsx21(RootContext2.Provider, { value: { label, selected, items, multiple, closeOnSelect, onSelect: handleOnSelect, onClear: handleOnClear }, children: /* @__PURE__ */ jsx21(Popover.Root, { open, onOpenChange: setOpen, children }) });
};
var Trigger3 = (props) => {
  const { label, selected, items, multiple } = useContext5(RootContext2);
  return /* @__PURE__ */ jsx21(Popover.Trigger, { asChild: true, children: /* @__PURE__ */ jsxs10(Button, { ...props, className: props.className?.(selected.length > 0), children: [
    selected.length === 0 && label,
    multiple && selected.length > 0 && label,
    !multiple && selected[0] && items.find((item) => item.value === selected[0])?.label
  ] }) });
};
var List2 = ({ className }) => {
  const { items, multiple, selected, onSelect, onClear } = useContext5(RootContext2);
  return /* @__PURE__ */ jsx21(Popover.Content, { className: twMerge17("w-[200px] p-0", className), children: /* @__PURE__ */ jsxs10(Command.Root, { size: "sm", children: [
    /* @__PURE__ */ jsx21(Command.Input, { placeholder: "Search" }),
    /* @__PURE__ */ jsxs10(Command.List, { children: [
      /* @__PURE__ */ jsx21(Command.Empty, { children: "No result found." }),
      /* @__PURE__ */ jsx21(Command.Group, { children: items.map(({ icon: Icon, ...rest }) => {
        const isSelected = selected.includes(rest.value);
        return /* @__PURE__ */ jsx21(Command.Item, { onSelect: () => onSelect(rest.value), children: multiple ? /* @__PURE__ */ jsxs10(Fragment, { children: [
          /* @__PURE__ */ jsx21(Checkbox.Root, { checked: isSelected }),
          Icon && /* @__PURE__ */ jsx21(Icon, { className: "mr-2" }),
          /* @__PURE__ */ jsx21("span", { className: "capitalize", children: rest.label })
        ] }) : /* @__PURE__ */ jsxs10(Fragment, { children: [
          /* @__PURE__ */ jsx21(HiCheck2, { className: clsx11("mr-3", isSelected ? "opacity-100" : "opacity-0") }),
          Icon && /* @__PURE__ */ jsx21(Icon, { className: "mr-2" }),
          /* @__PURE__ */ jsx21("span", { className: "capitalize", children: rest.label })
        ] }) }, rest.value);
      }) }),
      selected.length > 0 && /* @__PURE__ */ jsxs10(Fragment, { children: [
        /* @__PURE__ */ jsx21(Command.Separator, {}),
        /* @__PURE__ */ jsx21(Command.Group, { children: /* @__PURE__ */ jsxs10(Command.Item, { onSelect: () => onClear(), children: [
          /* @__PURE__ */ jsx21(HiXCircle2, { className: "mr-2" }),
          "Clear"
        ] }) })
      ] })
    ] })
  ] }) });
};
var Error2 = ({ children }) => {
  return /* @__PURE__ */ jsx21(Paragraph, { className: "text-danger-600 text-xs", children });
};
var Combobox = {
  Root: Root13,
  Trigger: Trigger3,
  List: List2,
  Error: Error2
};

// src/core/copy-to-clipboard.tsx
import { toast } from "sonner";
import { twMerge as twMerge19 } from "tailwind-merge";

// src/core/tooltip.tsx
import * as RxTooltip from "@radix-ui/react-tooltip";
import { forwardRef as forwardRef18 } from "react";
import { twMerge as twMerge18 } from "tailwind-merge";
import { jsx as jsx22, jsxs as jsxs11 } from "react/jsx-runtime";
var Content7 = forwardRef18(
  ({ className, sideOffset = 4, ...props }, ref) => {
    return /* @__PURE__ */ jsxs11(
      RxTooltip.Content,
      {
        className: twMerge18("select-none rounded bg-black px-2 py-1.5 text-xs leading-none text-white shadow-md", className),
        sideOffset,
        ref,
        ...props,
        children: [
          props.children,
          /* @__PURE__ */ jsx22(RxTooltip.Arrow, { className: "fill-black" })
        ]
      }
    );
  }
);
var Tooltip = {
  Provider: RxTooltip.Provider,
  Root: RxTooltip.Root,
  Trigger: RxTooltip.Trigger,
  Content: Content7
};

// src/core/copy-to-clipboard.tsx
import { jsx as jsx23, jsxs as jsxs12 } from "react/jsx-runtime";
var CopyToClipboard = ({ children, className, value, showToast = true }) => {
  async function copyToClipboard() {
    const copy = await navigator.clipboard.writeText(value);
    if (showToast) {
      toast.success(`Copied to clipboard!`);
    }
    return copy;
  }
  return /* @__PURE__ */ jsx23(Tooltip.Provider, { delayDuration: 80, children: /* @__PURE__ */ jsxs12(Tooltip.Root, { children: [
    /* @__PURE__ */ jsx23(Tooltip.Trigger, { asChild: true, children: /* @__PURE__ */ jsx23("button", { className: twMerge19("cursor-pointer", className), onClick: () => copyToClipboard(), children }) }),
    /* @__PURE__ */ jsx23(Tooltip.Content, { children: "Copy to clipboard" })
  ] }) });
};

// src/core/data-item.tsx
import { twMerge as twMerge20 } from "tailwind-merge";
import { Fragment as Fragment2, jsx as jsx24, jsxs as jsxs13 } from "react/jsx-runtime";
var Root15 = ({ children, className }) => {
  return /* @__PURE__ */ jsx24("div", { className, children });
};
var Title6 = ({ children, className }) => {
  return /* @__PURE__ */ jsx24("span", { className: twMerge20("text-muted mb-1 block text-xs", className), children });
};
var Value = ({
  children,
  className,
  unit: _unit,
  fallback
}) => {
  const unit = (() => {
    switch (_unit) {
      case "celsius":
        return "\xB0C";
      case "pressure":
        return "cmH\u20820";
      case "fluid-pressure":
        return "mmHg";
      case "resistance":
        return "mmHg/ml/min";
      case "flow":
        return "ml/min";
      default:
        break;
    }
  })();
  return /* @__PURE__ */ jsxs13("div", { children: [
    /* @__PURE__ */ jsx24(Label, { className: twMerge20("mb-2 inline", className), children: /* @__PURE__ */ jsx24(HasVisibleData, { fallback, children }) }),
    unit && /* @__PURE__ */ jsxs13(Fragment2, { children: [
      " ",
      /* @__PURE__ */ jsx24(Muted, { className: "text-xs", children: unit })
    ] })
  ] });
};
var DataItem = {
  Root: Root15,
  Title: Title6,
  Value
};

// src/core/date-picker.tsx
import { format } from "date-fns";
import { createContext as createContext6, useContext as useContext6 } from "react";
import { DayPicker } from "react-day-picker";
import { HiCalendar, HiChevronLeft, HiChevronRight, HiXCircle as HiXCircle3 } from "react-icons/hi2";
import { Fragment as Fragment3, jsx as jsx25, jsxs as jsxs14 } from "react/jsx-runtime";
var RootContext3 = createContext6({
  selected: void 0,
  onSelect: (value) => value
});
var Root16 = ({ children, selected, onSelect }) => {
  function setToday() {
    const today = /* @__PURE__ */ new Date();
    onSelect({ from: today, to: today });
  }
  function setYesterday() {
    const yesterday = /* @__PURE__ */ new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    onSelect({ from: yesterday, to: yesterday });
  }
  function setThisWeek() {
    const today = /* @__PURE__ */ new Date();
    const firstDay = new Date(today.setDate(today.getDate() - today.getDay()));
    const lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 6));
    onSelect({ from: firstDay, to: lastDay });
  }
  function setLastWeek() {
    const today = /* @__PURE__ */ new Date();
    const firstDay = new Date(today.setDate(today.getDate() - today.getDay() - 7));
    const lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 6));
    onSelect({ from: firstDay, to: lastDay });
  }
  function setThisMonth() {
    const today = /* @__PURE__ */ new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    onSelect({ from: firstDay, to: lastDay });
  }
  function setLastMonth() {
    const today = /* @__PURE__ */ new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
    onSelect({ from: firstDay, to: lastDay });
  }
  return /* @__PURE__ */ jsx25(RootContext3.Provider, { value: { selected, onSelect }, children: /* @__PURE__ */ jsxs14(Popover.Root, { children: [
    children,
    /* @__PURE__ */ jsx25(Popover.Content, { align: "start", className: "w-auto", children: /* @__PURE__ */ jsxs14("div", { className: "flex", children: [
      /* @__PURE__ */ jsxs14("div", { className: "flex flex-col gap-y-2.5 pr-12 pt-2", children: [
        /* @__PURE__ */ jsx25(Button, { variant: "ghost", size: "xs", onClick: () => setToday(), children: "Today" }),
        /* @__PURE__ */ jsx25(Button, { variant: "ghost", size: "xs", onClick: () => setYesterday(), children: "Yesterday" }),
        /* @__PURE__ */ jsx25(Button, { variant: "ghost", size: "xs", onClick: () => setThisWeek(), children: "This Week" }),
        /* @__PURE__ */ jsx25(Button, { variant: "ghost", size: "xs", onClick: () => setLastWeek(), children: "Last Week" }),
        /* @__PURE__ */ jsx25(Button, { variant: "ghost", size: "xs", onClick: () => setThisMonth(), children: "This Month" }),
        /* @__PURE__ */ jsx25(Button, { variant: "ghost", size: "xs", onClick: () => setLastMonth(), children: "Last Month" })
      ] }),
      /* @__PURE__ */ jsxs14("div", { children: [
        /* @__PURE__ */ jsx25(Calendar, { mode: "range", selected, onSelect: (range) => onSelect(range), className: "p-2" }),
        selected && /* @__PURE__ */ jsx25(Button, { variant: "outline", icon: HiXCircle3, isFullWidth: true, onClick: () => onSelect(void 0), children: "Clear" })
      ] })
    ] }) })
  ] }) });
};
var Trigger5 = (props) => {
  const { selected } = useContext6(RootContext3);
  return /* @__PURE__ */ jsx25(Popover.Trigger, { asChild: true, children: /* @__PURE__ */ jsx25(Button, { ...props, icon: HiCalendar, iconPosition: "right", className: props.className?.(selected?.from != null), children: selected?.from ? selected.to ? /* @__PURE__ */ jsxs14(Fragment3, { children: [
    format(selected.from, "LLL dd, y"),
    " - ",
    format(selected.to, "LLL dd, y")
  ] }) : format(selected.from, "LLL dd, y") : /* @__PURE__ */ jsx25("span", { children: "Created" }) }) });
};
var Calendar = ({ className, classNames, showOutsideDays = true, numberOfMonths = 2, ...props }) => {
  return /* @__PURE__ */ jsx25(
    DayPicker,
    {
      showOutsideDays,
      numberOfMonths,
      className,
      classNames: {
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium",
        nav: "space-x-1 flex items-center",
        nav_button: "p-1 bg-transparent border border-transparent hover:bg-primary/10 hover:border-primary/10 text-primary focus:ring-primary inline-flex items-center justify-center rounded-full font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell: "text-muted rounded-md w-9 font-normal text-[0.8rem]",
        row: "flex w-full mt-2",
        cell: "h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
        day: "h-9 w-9 p-0 text-primary rounded-md hover:bg-gray-100",
        day_range_end: "day-range-end",
        day_selected: "bg-primary text-white hover:bg-primary hover:text-white focus:bg-primary focus:text-white",
        day_today: "bg-gray-100",
        day_outside: "day-outside text-primary opacity-50",
        day_disabled: "text-muted opacity-50",
        day_range_middle: "aria-selected:bg-gray-100 aria-selected:text-primary",
        day_hidden: "invisible",
        ...classNames
      },
      components: {
        IconLeft: () => /* @__PURE__ */ jsx25(HiChevronLeft, {}),
        IconRight: () => /* @__PURE__ */ jsx25(HiChevronRight, {})
      },
      ...props
    }
  );
};
var DatePicker = {
  Root: Root16,
  Trigger: Trigger5
};

// src/core/dropdown.tsx
import * as RxDropdown from "@radix-ui/react-dropdown-menu";
import { forwardRef as forwardRef19 } from "react";
import { FaDotCircle } from "react-icons/fa";
import { HiCheck as HiCheck3 } from "react-icons/hi2";
import { twMerge as twMerge21 } from "tailwind-merge";
import { jsx as jsx26, jsxs as jsxs15 } from "react/jsx-runtime";
var Content9 = forwardRef19(
  ({ className, sideOffset = 4, ...props }, ref) => /* @__PURE__ */ jsx26(RxDropdown.Portal, { children: /* @__PURE__ */ jsx26(
    RxDropdown.Content,
    {
      ref,
      sideOffset,
      className: twMerge21(
        "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 min-w-[12rem] overflow-hidden rounded-md border bg-white p-1 shadow-md",
        className
      ),
      ...props
    }
  ) })
);
var Item6 = forwardRef19(({ className, inset, ...props }, ref) => /* @__PURE__ */ jsx26(
  RxDropdown.Item,
  {
    ref,
    className: twMerge21(
      "focus:bg-primary-950 relative flex cursor-default select-none items-center rounded-md px-2 py-1.5 text-sm outline-none transition-colors focus:text-white data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      inset && "pl-8",
      className
    ),
    ...props
  }
));
var CheckboxItem2 = forwardRef19(
  ({ className, children, checked, ...props }, ref) => /* @__PURE__ */ jsxs15(
    RxDropdown.CheckboxItem,
    {
      ref,
      className: twMerge21(
        "focus:bg-primary-950 relative flex cursor-default select-none items-center rounded-md py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:text-white data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
        className
      ),
      checked,
      ...props,
      children: [
        /* @__PURE__ */ jsx26("span", { className: "absolute left-2 flex h-3.5 w-3.5 items-center justify-center", children: /* @__PURE__ */ jsx26(RxDropdown.ItemIndicator, { children: /* @__PURE__ */ jsx26(HiCheck3, { className: "h-4 w-4" }) }) }),
        children
      ]
    }
  )
);
var RadioItem2 = forwardRef19(
  ({ className, children, ...props }, ref) => /* @__PURE__ */ jsxs15(
    RxDropdown.RadioItem,
    {
      ref,
      className: twMerge21(
        "focus:bg-primary-950 relative flex cursor-default select-none items-center rounded-md py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:text-white data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
        className
      ),
      ...props,
      children: [
        /* @__PURE__ */ jsx26("span", { className: "absolute left-2 flex h-3.5 w-3.5 items-center justify-center", children: /* @__PURE__ */ jsx26(RxDropdown.ItemIndicator, { children: /* @__PURE__ */ jsx26(FaDotCircle, { className: "fill-current" }) }) }),
        children
      ]
    }
  )
);
var Label4 = forwardRef19(({ className, inset, ...props }, ref) => /* @__PURE__ */ jsx26(RxDropdown.Label, { ref, className: twMerge21("px-2 py-1.5 text-xs uppercase text-gray-500", inset && "pl-8", className), ...props }));
var Separator5 = forwardRef19(
  ({ className, ...props }, ref) => /* @__PURE__ */ jsx26(RxDropdown.Separator, { ref, className: twMerge21("-mx-1 my-1 h-px bg-gray-200", className), ...props })
);
var CornerHint = ({ className, ...props }) => {
  return /* @__PURE__ */ jsx26("span", { className: twMerge21("ml-auto text-xs opacity-60", className), ...props });
};
var Dropdown = {
  Root: RxDropdown.Root,
  Trigger: RxDropdown.Trigger,
  Content: Content9,
  Group: RxDropdown.Group,
  RadioGroup: RxDropdown.RadioGroup,
  Item: Item6,
  CheckboxItem: CheckboxItem2,
  RadioItem: RadioItem2,
  Label: Label4,
  Separator: Separator5,
  CornerHint
};

// src/core/feed.tsx
import clsx12 from "clsx";
import { twMerge as twMerge22 } from "tailwind-merge";
import { jsx as jsx27, jsxs as jsxs16 } from "react/jsx-runtime";
var colors5 = {
  primary: "text-primary",
  secondary: "text-secondary",
  success: "text-success",
  danger: "text-danger",
  warning: "text-warning"
};
var Root18 = ({ children, className }) => {
  return /* @__PURE__ */ jsx27("ul", { role: "list", className: twMerge22("space-y-6", className), children });
};
var Item7 = ({ children, className, color = "primary", icon: Icon, connect = true }) => {
  return /* @__PURE__ */ jsxs16("li", { className: twMerge22("relative flex gap-x-4", className), children: [
    /* @__PURE__ */ jsx27("div", { className: clsx12(!connect ? "h-6" : "-bottom-6", "absolute left-0 top-0 flex w-6 justify-center"), children: /* @__PURE__ */ jsx27("div", { className: "w-px bg-gray-200" }) }),
    /* @__PURE__ */ jsx27("div", { className: "relative flex h-6 w-6 flex-none items-center justify-center bg-white", children: Icon ? /* @__PURE__ */ jsx27(Icon, { className: clsx12(colors5[color]) }) : /* @__PURE__ */ jsx27("div", { className: "bg-secondary-100 ring-secondary-400 h-1.5 w-1.5 rounded-full ring-1" }) }),
    children
  ] });
};
var Text = ({ children }) => {
  return /* @__PURE__ */ jsx27(Paragraph, { className: "flex-auto py-0.5 text-sm leading-5", children });
};
var Corner2 = ({ children }) => {
  return /* @__PURE__ */ jsx27("div", { className: "text-muted flex-none py-0.5 text-xs leading-5", children });
};
var Feed = {
  Root: Root18,
  Item: Item7,
  Text,
  Corner: Corner2
};

// src/core/form/input.tsx
import clsx13 from "clsx";
import { createContext as createContext7, useContext as useContext7 } from "react";
import { jsx as jsx28, jsxs as jsxs17 } from "react/jsx-runtime";
var RootContext4 = createContext7({});
var Root19 = ({ children, ...props }) => {
  return /* @__PURE__ */ jsx28(RootContext4.Provider, { value: props, children: /* @__PURE__ */ jsx28("div", { className: "space-y-1.5", children }) });
};
var Header3 = ({ children }) => {
  return /* @__PURE__ */ jsx28("div", { className: "flex justify-between", children });
};
var Label5 = ({ children, isValid = true }) => {
  const { id } = useContext7(RootContext4);
  return /* @__PURE__ */ jsx28("label", { htmlFor: id, className: clsx13(isValid ? "text-gray-900" : "text-danger-600", "block text-sm font-medium leading-6"), children });
};
var CornerHint2 = ({ children }) => {
  const { id } = useContext7(RootContext4);
  return /* @__PURE__ */ jsx28("span", { id, className: "text-sm leading-6 text-gray-500", children });
};
var Field2 = ({ left, right }) => {
  const props = useContext7(RootContext4);
  return /* @__PURE__ */ jsxs17("div", { className: "relative rounded", children: [
    left != null && /* @__PURE__ */ jsx28("div", { className: "pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3", children: /* @__PURE__ */ jsx28("span", { className: "text-gray-500 sm:text-sm", children: left }) }),
    /* @__PURE__ */ jsx28(
      "input",
      {
        className: clsx13(
          left == null ? "pl-3" : "pl-8",
          right == null ? "pr-3" : "pr-12",
          "focus:ring-primary-950 block w-full rounded border-0 py-1.5 text-gray-900 ring-1 ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm sm:leading-6"
        ),
        ...props
      }
    ),
    right != null && /* @__PURE__ */ jsx28("div", { className: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3", children: /* @__PURE__ */ jsx28("span", { className: "text-gray-500 sm:text-sm", children: right }) })
  ] });
};
var Description5 = ({ children }) => {
  return /* @__PURE__ */ jsx28(Paragraph, { className: "text-xs", children });
};
var Error3 = ({ children }) => {
  return /* @__PURE__ */ jsx28(Paragraph, { className: "text-danger text-xs", children });
};
var Input2 = {
  Root: Root19,
  Header: Header3,
  Label: Label5,
  CornerHint: CornerHint2,
  Field: Field2,
  Description: Description5,
  Error: Error3
};

// src/core/form/input-otp.tsx
import { OTPInput, OTPInputContext } from "input-otp";
import { forwardRef as forwardRef20, useContext as useContext8 } from "react";
import { LuDot } from "react-icons/lu";
import { twMerge as twMerge23 } from "tailwind-merge";
import { jsx as jsx29, jsxs as jsxs18 } from "react/jsx-runtime";
var Root20 = forwardRef20(
  ({ className, containerClassName, ...props }, ref) => /* @__PURE__ */ jsx29(
    OTPInput,
    {
      ref,
      containerClassName: twMerge23("flex items-center gap-2 has-[:disabled]:opacity-50", containerClassName),
      className: twMerge23("disabled:cursor-not-allowed", className),
      ...props
    }
  )
);
var Group3 = forwardRef20(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx29("div", { ref, className: twMerge23("flex items-center", className), ...props });
});
var Slot3 = forwardRef20(({ index, className, ...props }, ref) => {
  const inputOTPContext = useContext8(OTPInputContext);
  const { char, hasFakeCaret, isActive } = inputOTPContext.slots[index];
  return /* @__PURE__ */ jsxs18(
    "div",
    {
      ref,
      className: twMerge23(
        "border-gray relative flex h-12 w-12 items-center justify-center border-y border-r text-base transition-all first:rounded-l-md first:border-l last:rounded-r-md",
        isActive && "ring-primary z-10 ring-2",
        className
      ),
      ...props,
      children: [
        char,
        hasFakeCaret && /* @__PURE__ */ jsx29("div", { className: "pointer-events-none absolute inset-0 flex items-center justify-center", children: /* @__PURE__ */ jsx29("div", { className: "animate-caret-blink bg-foreground h-4 w-px duration-1000" }) })
      ]
    }
  );
});
var Separator6 = forwardRef20(({ ...props }, ref) => {
  return /* @__PURE__ */ jsx29("div", { ref, role: "separator", ...props, children: /* @__PURE__ */ jsx29(LuDot, {}) });
});
var InputOTP = {
  Root: Root20,
  Group: Group3,
  Slot: Slot3,
  Separator: Separator6
};

// src/core/form/radio-group.tsx
import * as RxRadioGroup from "@radix-ui/react-radio-group";
import { forwardRef as forwardRef21 } from "react";
import { FaCircle } from "react-icons/fa";
import { twMerge as twMerge24 } from "tailwind-merge";
import { jsx as jsx30, jsxs as jsxs19 } from "react/jsx-runtime";
var Root22 = forwardRef21(
  ({ className, ...props }, ref) => {
    return /* @__PURE__ */ jsx30(RxRadioGroup.Root, { ref, className: twMerge24("space-y-4", className), ...props });
  }
);
var Item9 = forwardRef21(
  ({ children, className, ...props }, ref) => {
    return /* @__PURE__ */ jsxs19("div", { className: twMerge24("flex items-center", className), children: [
      /* @__PURE__ */ jsx30(
        RxRadioGroup.Item,
        {
          ref,
          className: "focus-visible:ring-primary-950 data-[state=checked]:border-primary-950 aspect-square h-4 w-4 rounded-full border border-gray-300 ring-offset-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          ...props,
          children: /* @__PURE__ */ jsx30(RxRadioGroup.Indicator, { className: "flex items-center justify-center", children: /* @__PURE__ */ jsx30(FaCircle, { className: "h-2.5 w-2.5 fill-current text-current" }) })
        }
      ),
      /* @__PURE__ */ jsx30("div", { className: "flex flex-col", children })
    ] });
  }
);
var Label6 = forwardRef21(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx30(Label, { ref, ...props });
});
var Description6 = forwardRef21(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx30(Paragraph, { ref, className: twMerge24("text-xs", className), ...props });
});
var RadioGroup2 = {
  Root: Root22,
  Item: Item9,
  Label: Label6,
  Description: Description6
};

// src/core/has-visible-data.tsx
import { Children, Fragment as Fragment4, isValidElement } from "react";
import { jsx as jsx31 } from "react/jsx-runtime";
function escape(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
function replaceAll(str, chars) {
  return chars.reduce((acc, char) => acc.replace(new RegExp(escape(char), "g"), ""), str);
}
var HasVisibleData = ({ children, className, fallback = null, ignoreChars = [], as: Comp }) => {
  const hasVisibleChildren = Children.toArray(children).some((child) => {
    if (isValidElement(child)) {
      return true;
    }
    if (typeof child === "string") {
      const cleaned = ignoreChars.length > 0 ? replaceAll(child, ignoreChars).trim() : child.trim();
      return cleaned.length > 0;
    }
    if (typeof child === "number" && !isNaN(child)) {
      return true;
    }
    return false;
  });
  if (!Comp) {
    return hasVisibleChildren ? /* @__PURE__ */ jsx31(Fragment4, { children }) : fallback;
  }
  return hasVisibleChildren ? /* @__PURE__ */ jsx31(Comp, { className, children }) : fallback;
};

// src/core/hover-card.tsx
import * as RxHoverCard from "@radix-ui/react-hover-card";
import { forwardRef as forwardRef22 } from "react";
import { twMerge as twMerge25 } from "tailwind-merge";
import { jsx as jsx32 } from "react/jsx-runtime";
var Content11 = forwardRef22(
  ({ className, align = "center", sideOffset = 4, ...props }, ref) => /* @__PURE__ */ jsx32(
    RxHoverCard.Content,
    {
      ref,
      align,
      sideOffset,
      className: twMerge25(
        "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 w-64 rounded-md border bg-white p-4 shadow-md outline-none",
        className
      ),
      ...props
    }
  )
);
var HoverCard = {
  Root: RxHoverCard.Root,
  Trigger: RxHoverCard.Trigger,
  Content: Content11
};

// src/core/icon-badge.tsx
import clsx14 from "clsx";
import { twMerge as twMerge26 } from "tailwind-merge";
import { jsx as jsx33 } from "react/jsx-runtime";
var sizes8 = {
  xxs: {
    container: "h-6 w-6",
    icon: "h-4 w-4"
  },
  xs: {
    container: "h-7 w-7",
    icon: "h-4 w-4"
  },
  sm: {
    container: "h-8 w-8",
    icon: "h-5 w-5"
  },
  md: {
    container: "h-10 w-10",
    icon: "h-6 w-6"
  },
  lg: {
    container: "h-12 w-12",
    icon: "h-7 w-7"
  },
  xl: {
    container: "h-14 w-14",
    icon: "h-8 w-8"
  }
};
var colors6 = {
  primary: "bg-primary text-white",
  secondary: "bg-secondary text-white",
  success: "bg-success text-white",
  danger: "bg-danger text-white",
  warning: "bg-warning text-white",
  heart: "bg-heart text-white",
  lung: "bg-lung text-white",
  liver: "bg-liver text-white",
  kidney: "bg-kidney text-white",
  "kidney-left": "bg-kidney-left text-white",
  "kidney-right": "bg-kidney-right text-white",
  pancreas: "bg-pancreas text-white"
};
var variants4 = {
  solid: colors6,
  soft: {
    primary: "bg-primary-50 ring-1 ring-inset ring-primary/10 text-primary",
    secondary: "bg-secondary-50 ring-1 ring-inset ring-secondary/10 text-secondary",
    success: "bg-success-50 ring-1 ring-inset ring-success/10 text-success",
    danger: "bg-danger-50 ring-1 ring-inset ring-danger/10 text-danger",
    warning: "bg-warning-50 ring-1 ring-inset ring-warning/10 text-warning",
    heart: "bg-heart-50 ring-1 ring-inset ring-heart/10 text-heart",
    lung: "bg-lung-50 ring-1 ring-inset ring-lung/10 text-lung",
    liver: "bg-liver-50 ring-1 ring-inset ring-liver/10 text-liver",
    kidney: "bg-kidney-50 ring-1 ring-inset ring-kidney/10 text-kidney",
    "kidney-left": "bg-kidney-left-50 ring-1 ring-inset ring-kidney-left/10 text-kidney-left",
    "kidney-right": "bg-kidney-right-50 ring-1 ring-inset ring-kidney-right/10 text-kidney-right",
    pancreas: "bg-pancreas-50 ring-1 ring-inset ring-pancreas/10 text-pancreas"
  }
};
var IconBadge = ({ className, color = "primary", variant = "soft", size = "md", icon: Icon, ...props }) => {
  return /* @__PURE__ */ jsx33(
    "span",
    {
      className: twMerge26(clsx14(sizes8[size].container, variants4[variant][color], "flex items-center justify-center rounded-md"), className),
      ...props,
      children: /* @__PURE__ */ jsx33(Icon, { className: sizes8[size].icon })
    }
  );
};

// src/core/loader-overlay.tsx
import { jsx as jsx34, jsxs as jsxs20 } from "react/jsx-runtime";
var LoaderOverlay = ({ isLoading = false, text }) => {
  if (!isLoading) {
    return null;
  }
  return /* @__PURE__ */ jsxs20("div", { children: [
    /* @__PURE__ */ jsx34("div", { className: "fixed inset-0 z-[9998] bg-white opacity-70" }),
    /* @__PURE__ */ jsx34("div", { className: "fixed inset-0 z-[9999] w-screen overflow-y-auto", children: /* @__PURE__ */ jsxs20("div", { className: "flex min-h-full flex-col items-center justify-center space-y-2.5", children: [
      /* @__PURE__ */ jsx34(
        "img",
        {
          src: "/assets/logos/paragonix-logo-square-dark.png",
          className: "h-[56px] w-[56px] animate-pulse rounded-lg ring-2 ring-black ring-offset-2 grayscale",
          alt: "Paragonix"
        }
      ),
      /* @__PURE__ */ jsx34("div", { children: /* @__PURE__ */ jsx34("span", { className: "text-center text-sm text-gray-400", children: text }) })
    ] }) })
  ] });
};

// src/core/loader-screen.tsx
import { jsx as jsx35, jsxs as jsxs21 } from "react/jsx-runtime";
var LoaderScreen = ({ text }) => {
  return /* @__PURE__ */ jsx35("div", { className: "flex h-full w-full items-center justify-center", children: /* @__PURE__ */ jsxs21("div", { className: "flex flex-col items-center space-y-2.5", children: [
    /* @__PURE__ */ jsx35(
      "img",
      {
        src: "/assets/logos/paragonix-logo-square-dark.png",
        className: "h-[56px] w-[56px] animate-pulse rounded-lg ring-2 ring-black ring-offset-2 grayscale",
        alt: "Paragonix"
      }
    ),
    /* @__PURE__ */ jsx35("div", { children: /* @__PURE__ */ jsx35("span", { className: "text-center text-sm text-gray-400", children: text }) })
  ] }) });
};

// src/core/notification.tsx
import { twMerge as twMerge27 } from "tailwind-merge";
import { jsx as jsx36 } from "react/jsx-runtime";
var Root24 = ({ children, className }) => {
  return /* @__PURE__ */ jsx36("div", { className: twMerge27("flex w-full items-start space-x-4", className), children });
};
var LeftCorner = ({ children, className }) => {
  return /* @__PURE__ */ jsx36("div", { className: twMerge27("flex-shrink-0 pt-0.5", className), children });
};
var RightCorner = ({ children, className }) => {
  return /* @__PURE__ */ jsx36("div", { className: twMerge27("flex flex-shrink-0", className), children });
};
var Content12 = ({ children, className }) => {
  return /* @__PURE__ */ jsx36("div", { className: twMerge27("w-0 flex-1", className), children });
};
var Title7 = ({ children, className }) => {
  return /* @__PURE__ */ jsx36(Heading, { size: "4", className, children });
};
var Description7 = ({ children, className }) => {
  return /* @__PURE__ */ jsx36(Paragraph, { className, children });
};
var Footer3 = ({ children, className }) => {
  return /* @__PURE__ */ jsx36("div", { className: twMerge27("mt-4", className), children });
};
var Notification = {
  Root: Root24,
  LeftCorner,
  RightCorner,
  Content: Content12,
  Title: Title7,
  Description: Description7,
  Footer: Footer3
};

// src/core/progress.tsx
import * as RxProgress from "@radix-ui/react-progress";
import clsx15 from "clsx";
import { forwardRef as forwardRef23 } from "react";
import { twMerge as twMerge28 } from "tailwind-merge";
import { jsx as jsx37, jsxs as jsxs22 } from "react/jsx-runtime";
var Progress = forwardRef23(
  ({ className, value, ...props }, ref) => /* @__PURE__ */ jsxs22("div", { className: "relative flex items-center", children: [
    /* @__PURE__ */ jsx37(
      RxProgress.Root,
      {
        ref,
        className: twMerge28("bg-secondary-200 relative h-1 w-full overflow-hidden rounded-sm", className),
        ...props,
        children: /* @__PURE__ */ jsx37(
          RxProgress.Indicator,
          {
            className: "bg-primary h-full w-full flex-1 transition-all",
            style: { transform: `translateX(-${100 - (value ?? 0)}%)` }
          }
        )
      }
    ),
    /* @__PURE__ */ jsx37("div", { className: clsx15(value && value > 0 ? "bg-primary" : "bg-secondary-200", "absolute left-0 h-3 w-3 rounded-full") }),
    /* @__PURE__ */ jsx37("div", { className: clsx15(value && value > 0 ? "bg-primary" : "bg-secondary-200", "absolute right-0 h-3 w-3 rounded-full") })
  ] })
);

// src/core/scroll-area.tsx
import * as RxScrollArea from "@radix-ui/react-scroll-area";
import clsx16 from "clsx";
import { forwardRef as forwardRef24 } from "react";
import { twMerge as twMerge29 } from "tailwind-merge";
import { jsx as jsx38, jsxs as jsxs23 } from "react/jsx-runtime";
var Root27 = forwardRef24(
  ({ className, children, ...props }, ref) => /* @__PURE__ */ jsxs23(RxScrollArea.Root, { ref, className: twMerge29("relative overflow-hidden", className), ...props, children: [
    /* @__PURE__ */ jsx38(RxScrollArea.Viewport, { className: "h-full w-full rounded-[inherit]", children }),
    /* @__PURE__ */ jsx38(ScrollBar, {}),
    /* @__PURE__ */ jsx38(RxScrollArea.Corner, {})
  ] })
);
var ScrollBar = forwardRef24(({ className, orientation = "vertical", ...props }, ref) => /* @__PURE__ */ jsx38(
  RxScrollArea.ScrollAreaScrollbar,
  {
    ref,
    orientation,
    className: clsx16(
      "flex touch-none select-none transition-colors",
      orientation === "vertical" && "h-full w-2.5 border-l border-l-transparent p-[1px]",
      orientation === "horizontal" && "h-2.5 flex-col border-t border-t-transparent p-[1px]",
      className
    ),
    ...props,
    children: /* @__PURE__ */ jsx38(RxScrollArea.ScrollAreaThumb, { className: "relative flex-1 rounded-full bg-gray-200" })
  }
));
var ScrollArea = {
  Root: Root27,
  ScrollBar
};

// src/core/sheet.tsx
import * as RxSheet from "@radix-ui/react-dialog";
import clsx17 from "clsx";
import { forwardRef as forwardRef25 } from "react";
import { HiXMark as HiXMark2 } from "react-icons/hi2";
import { twMerge as twMerge30 } from "tailwind-merge";
import { jsx as jsx39, jsxs as jsxs24 } from "react/jsx-runtime";
var sides = {
  top: "inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top",
  bottom: "inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom",
  left: "inset-y-0 left-0 h-full w-full border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left",
  right: "inset-y-0 right-0 h-full w-full border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right"
};
var sizes9 = {
  xs: "max-w-xs",
  sm: "max-w-sm",
  md: "max-w-md",
  lg: "max-w-lg",
  xl: "max-w-xl",
  "2xl": "max-w-2xl",
  "3xl": "max-w-3xl",
  "4xl": "max-w-4xl",
  "5xl": "max-w-5xl",
  "6xl": "max-w-6xl",
  "7xl": "max-w-7xl",
  full: "max-w-full"
};
var paddings3 = {
  top: "pt-6",
  bottom: "pb-6",
  left: "pl-6",
  right: "pr-6",
  x: "px-6",
  y: "py-6",
  all: "p-6",
  none: "p-0"
};
function transpilePadding3(padding) {
  if (Array.isArray(padding)) {
    return padding.map((p) => paddings3[p]).join(" ");
  }
  return paddings3[padding];
}
var Overlay4 = forwardRef25(
  ({ className, ...props }, ref) => /* @__PURE__ */ jsx39(
    RxSheet.Overlay,
    {
      className: twMerge30(
        "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 bg-black/80",
        className
      ),
      ...props,
      ref
    }
  )
);
var Content14 = forwardRef25(
  ({ side = "right", size = "lg", className, children, ...props }, ref) => {
    return /* @__PURE__ */ jsxs24(RxSheet.Portal, { children: [
      /* @__PURE__ */ jsx39(Overlay4, {}),
      /* @__PURE__ */ jsxs24(
        RxSheet.Content,
        {
          ref,
          className: twMerge30(
            clsx17(
              sides[side],
              sizes9[size],
              "data-[state=open]:animate-in data-[state=closed]:animate-out fixed z-50 overflow-y-scroll rounded-s-md bg-white shadow-lg transition ease-in-out data-[state=closed]:duration-300 data-[state=open]:duration-500"
            ),
            className
          ),
          ...props,
          children: [
            children,
            /* @__PURE__ */ jsxs24(RxSheet.Close, { className: "absolute right-4 top-4 rounded-sm opacity-70 hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none", children: [
              /* @__PURE__ */ jsx39(HiXMark2, { className: "h-4 w-4" }),
              /* @__PURE__ */ jsx39("span", { className: "sr-only", children: "Close" })
            ] })
          ]
        }
      )
    ] });
  }
);
var Section3 = ({ className, padding = "x", ...props }) => {
  return /* @__PURE__ */ jsx39("div", { className: twMerge30(transpilePadding3(padding), className), ...props });
};
var SectionTitle2 = ({ children, className }) => {
  return /* @__PURE__ */ jsx39(Heading, { size: "3", className: twMerge30("mb-3", className), children });
};
var Header4 = ({ className, padding = "all", ...props }) => {
  return /* @__PURE__ */ jsx39("div", { className: twMerge30(clsx17(transpilePadding3(padding), "flex flex-col space-y-1.5"), className), ...props });
};
var Title9 = forwardRef25(
  ({ className, ...props }, ref) => {
    return /* @__PURE__ */ jsx39(RxSheet.Title, { ref, asChild: true, children: /* @__PURE__ */ jsx39(Heading, { size: "3", className, ...props }) });
  }
);
var Description8 = forwardRef25(
  ({ className, ...props }, ref) => {
    return /* @__PURE__ */ jsx39(RxSheet.Title, { ref, asChild: true, children: /* @__PURE__ */ jsx39(Paragraph, { className, ...props }) });
  }
);
var Separator7 = forwardRef25(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx39("hr", { ref, className: twMerge30("mx-6 my-6", className), ...props });
});
var Footer4 = ({ className, padding = "all", ...props }) => {
  return /* @__PURE__ */ jsx39(
    "div",
    {
      className: twMerge30(clsx17(transpilePadding3(padding), "absolute inset-x-0 bottom-0 flex w-full items-center bg-white"), className),
      ...props
    }
  );
};
var Sheet = {
  Root: RxSheet.Root,
  Content: Content14,
  Trigger: RxSheet.Trigger,
  Header: Header4,
  Title: Title9,
  Description: Description8,
  Section: Section3,
  SectionTitle: SectionTitle2,
  Separator: Separator7,
  Footer: Footer4,
  Close: RxSheet.Close
};

// src/core/switch.tsx
import * as RxSwitch from "@radix-ui/react-switch";
import { forwardRef as forwardRef26 } from "react";
import { twMerge as twMerge31 } from "tailwind-merge";
import { jsx as jsx40, jsxs as jsxs25 } from "react/jsx-runtime";
var Group4 = forwardRef26(({ children, className, ...props }, ref) => {
  return /* @__PURE__ */ jsx40("div", { ref, className: twMerge31("flex flex-col space-y-4", className), ...props, children });
});
var Root30 = forwardRef26(
  ({ children, className, ...props }, ref) => {
    return /* @__PURE__ */ jsxs25("div", { className: twMerge31("flex items-center", className), children: [
      /* @__PURE__ */ jsx40(
        RxSwitch.Root,
        {
          ref,
          className: "data-[state=checked]:bg-primary-950 focus:ring-primary-950 relative inline-flex h-5 w-9 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-300 disabled:ring-gray-300 disabled:ring-offset-gray-300 data-[state=unchecked]:bg-gray-200",
          ...props,
          children: /* @__PURE__ */ jsx40(RxSwitch.Thumb, { className: "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0" })
        }
      ),
      /* @__PURE__ */ jsx40("div", { className: "flex flex-col", children })
    ] });
  }
);
var Label7 = forwardRef26(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx40(Label, { ref, ...props });
});
var Description9 = forwardRef26(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx40(Paragraph, { ref, className: twMerge31("text-xs", className), ...props });
});
var Switch = {
  Group: Group4,
  Root: Root30,
  Label: Label7,
  Description: Description9
};

// src/core/table.tsx
import { Form, useSearchParams } from "@remix-run/react";
import { createContext as createContext8, forwardRef as forwardRef27, useContext as useContext9 } from "react";
import { HiArrowDown, HiArrowUp, HiChevronUpDown, HiXCircle as HiXCircle4 } from "react-icons/hi2";
import { twMerge as twMerge32 } from "tailwind-merge";
import { Fragment as Fragment5, jsx as jsx41, jsxs as jsxs26 } from "react/jsx-runtime";
var RootContext5 = createContext8({ columns: [] });
var Root31 = forwardRef27(
  ({ className, columns, selectable, ...props }, ref) => /* @__PURE__ */ jsx41(RootContext5.Provider, { value: { columns, selectable }, children: /* @__PURE__ */ jsx41("div", { className: "relative w-full overflow-auto", children: /* @__PURE__ */ jsx41("table", { ref, className: twMerge32("w-full caption-bottom text-sm", className), ...props }) }) })
);
var Header5 = forwardRef27(({ className, ...props }, ref) => /* @__PURE__ */ jsx41("thead", { ref, className: twMerge32("[&_tr]:border-b", className), ...props }));
var Body = forwardRef27(({ className, ...props }, ref) => /* @__PURE__ */ jsx41("tbody", { ref, className: twMerge32("[&_tr:last-child]:border-0", className), ...props }));
var Footer5 = forwardRef27(({ className, ...props }, ref) => /* @__PURE__ */ jsx41("tfoot", { ref, className: twMerge32("border-t font-medium [&>tr]:last:border-b-0", className), ...props }));
var Row = forwardRef27(
  ({ className, selected, ...props }, ref) => /* @__PURE__ */ jsx41("tr", { ref, className: twMerge32("border-b transition-colors hover:bg-gray-50", selected && "bg-gray-100", className), ...props })
);
var Head = forwardRef27(({ className, selector, sortable = false, selectable = false, checked = false, onCheckedChange, children, ...props }, ref) => {
  const { columns } = useContext9(RootContext5);
  const [params, setParams] = useSearchParams();
  const sort = params.get("sort")?.split(",") ?? [];
  const showClear = sort[0] && sort[1];
  const icon = (() => {
    if (!sortable) {
      return;
    }
    if (showClear && sort[0] === selector && sort[1] === "asc") {
      return HiArrowUp;
    }
    if (showClear && sort[0] === selector && sort[1] === "desc") {
      return HiArrowDown;
    }
    return HiChevronUpDown;
  })();
  function handleOnSort(direction = "desc") {
    setParams((prev) => {
      prev.set("sort", `${selector},${direction}`);
      return prev;
    });
  }
  function handleOnClear() {
    setParams((prev) => {
      prev.delete("sort");
      return prev;
    });
  }
  if (!columns.includes(selector)) {
    return null;
  }
  return /* @__PURE__ */ jsx41(
    "th",
    {
      ref,
      className: twMerge32("text-muted-foreground h-12 px-4 text-left align-middle font-normal [&:has([role=checkbox])]:pr-0", className),
      ...props,
      children: selectable && onCheckedChange ? /* @__PURE__ */ jsx41(Checkbox.Root, { checked, onCheckedChange: (checked2) => onCheckedChange(Boolean(checked2)) }) : sortable ? /* @__PURE__ */ jsxs26(Dropdown.Root, { children: [
        /* @__PURE__ */ jsx41(Dropdown.Trigger, { asChild: true, children: /* @__PURE__ */ jsx41(Button, { variant: "ghost", icon, iconPosition: "right", size: "xs", className: "px-0 py-0.5", children }) }),
        /* @__PURE__ */ jsx41(Dropdown.Content, { className: "min-w-[100px]", children: /* @__PURE__ */ jsxs26(Form, { method: "GET", children: [
          sortable && /* @__PURE__ */ jsxs26(Dropdown.Group, { children: [
            /* @__PURE__ */ jsxs26(Dropdown.Item, { onSelect: () => handleOnSort("asc"), children: [
              /* @__PURE__ */ jsx41(HiArrowUp, { className: "mr-2 h-4 w-4" }),
              /* @__PURE__ */ jsx41("span", { children: "Asc" })
            ] }),
            /* @__PURE__ */ jsxs26(Dropdown.Item, { onSelect: () => handleOnSort("desc"), children: [
              /* @__PURE__ */ jsx41(HiArrowDown, { className: "mr-2 h-4 w-4" }),
              /* @__PURE__ */ jsx41("span", { children: "Desc" })
            ] })
          ] }),
          showClear && /* @__PURE__ */ jsxs26(Fragment5, { children: [
            /* @__PURE__ */ jsx41(Dropdown.Separator, {}),
            /* @__PURE__ */ jsx41(Dropdown.Group, { children: /* @__PURE__ */ jsxs26(Dropdown.Item, { onSelect: handleOnClear, children: [
              /* @__PURE__ */ jsx41(HiXCircle4, { className: "mr-2" }),
              "Clear"
            ] }) })
          ] })
        ] }) })
      ] }) : /* @__PURE__ */ jsx41("span", { className: "text-primary text-xs font-medium", children })
    }
  );
});
var Cell = forwardRef27(({ className, selector, checked = false, onCheckedChange, ...props }, ref) => {
  const { columns, selectable } = useContext9(RootContext5);
  if (!columns.includes(selector) && selector !== "checkbox") {
    return null;
  }
  return /* @__PURE__ */ jsx41("td", { ref, className: twMerge32("p-4 align-middle [&:has([role=checkbox])]:pr-0", className), ...props, children: selectable && selector === "checkbox" && onCheckedChange ? /* @__PURE__ */ jsx41(Checkbox.Root, { checked, onCheckedChange: (checked2) => onCheckedChange(Boolean(checked2)) }) : props.children });
});
var Caption = forwardRef27(({ className, ...props }, ref) => /* @__PURE__ */ jsx41("caption", { ref, className: twMerge32("text-muted-foreground mt-4 text-sm", className), ...props }));
var Table = {
  Root: Root31,
  Header: Header5,
  Body,
  Footer: Footer5,
  Row,
  Head,
  Cell,
  Caption
};

// src/core/tabs.tsx
import * as RxTabs from "@radix-ui/react-tabs";
import { forwardRef as forwardRef28 } from "react";
import { twMerge as twMerge33 } from "tailwind-merge";
import { jsx as jsx42 } from "react/jsx-runtime";
var List4 = forwardRef28(({ className, ...props }, ref) => /* @__PURE__ */ jsx42(
  RxTabs.List,
  {
    ref,
    className: twMerge33("text-primary-950 inline-flex h-10 items-center justify-center rounded-md bg-gray-100 p-1", className),
    ...props
  }
));
var Trigger10 = forwardRef28(
  ({ className, ...props }, ref) => /* @__PURE__ */ jsx42(
    RxTabs.Trigger,
    {
      ref,
      className: twMerge33(
        "data-[state=active]:text-primary-950 inline-flex items-center justify-center whitespace-nowrap rounded-md px-3 py-1.5 text-sm font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-white data-[state=active]:shadow-sm",
        className
      ),
      ...props
    }
  )
);
var Content16 = forwardRef28(
  ({ className, ...props }, ref) => /* @__PURE__ */ jsx42(
    RxTabs.Content,
    {
      ref,
      className: twMerge33(
        "ring-offset-background focus-visible:ring-ring mt-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2",
        className
      ),
      ...props
    }
  )
);
var Tabs = {
  Root: RxTabs.Root,
  List: List4,
  Trigger: Trigger10,
  Content: Content16
};

// src/core/traffic-light.tsx
import clsx18 from "clsx";
import { jsx as jsx43, jsxs as jsxs27 } from "react/jsx-runtime";
var colors7 = {
  gray: {
    outer: "bg-gray-500/20",
    inner: "bg-gray-500"
  },
  blue: {
    outer: "bg-primary-500/20",
    inner: "bg-primary-500"
  },
  red: {
    outer: "bg-danger-500/20",
    inner: "bg-danger-500"
  },
  yellow: {
    outer: "bg-warning-500/20",
    inner: "bg-warning-500"
  },
  green: {
    outer: "bg-success-500/20",
    inner: "bg-success-500"
  }
};
var TrafficLight = ({ children, className, color, hidden = false, isPinging = false, side = "left" }) => {
  return /* @__PURE__ */ jsxs27("div", { className: clsx18(side === "left" ? "flex-row" : "flex-row-reverse", "flex items-center gap-x-1.5"), children: [
    !hidden && /* @__PURE__ */ jsx43("div", { className: clsx18(colors7[color].outer, isPinging && "animate-ping", "flex-none rounded-full p-1"), children: /* @__PURE__ */ jsx43("div", { className: clsx18(colors7[color].inner, "h-2 w-2 rounded-full") }) }),
    /* @__PURE__ */ jsx43("div", { className, children: children != null && /* @__PURE__ */ jsx43("p", { children }) })
  ] });
};
export {
  Alert,
  Avatar,
  Badge,
  Button,
  Card,
  Carousel,
  Checkbox,
  Combobox,
  Command,
  CopyToClipboard,
  DataItem,
  DatePicker,
  Dialog,
  Dropdown,
  Feed,
  HasVisibleData,
  HoverCard,
  IconBadge,
  IconButton,
  Input2 as Input,
  InputOTP,
  Loader,
  LoaderOverlay,
  LoaderScreen,
  Notification,
  Popover,
  Progress,
  RadioGroup2 as RadioGroup,
  Root16 as Root,
  ScrollArea,
  Sheet,
  Switch,
  Table,
  Tabs,
  Tooltip,
  TrafficLight
};
