import { Heading, Paragraph } from '@packages/ui/typography';
import { ReactNode } from 'react';
import { ForgotPasswordForm, ForgotPasswordFormValidator, useForgotPasswordFormSubmit } from './components/forgot-password-form';
import { ResetPasswordForm, ResetPasswordFormValidator, useResetPasswordFormSubmit } from './components/reset-password-form';
import { SignInForm, SignInFormValidator, useSignInFormSubmit } from './components/sign-in-form';
import { SignUpForm, SignUpFormValidator, useSignUpFormSubmit } from './components/sign-up-form';

const Root = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex h-screen flex-1">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <img src="/assets/logos/paragonix-logo-light.png" className="mb-8 w-48" />

          {children}
        </div>

        <p className="text-muted mt-8 text-center text-xs">© 2024 Paragonix Technologies Inc.</p>
      </div>

      <div className="relative hidden w-0 flex-1 lg:block">
        <img className="absolute inset-0 h-full w-full object-cover" src="/assets/backgrounds/auth-bg-image.jpg" alt="" />
      </div>
    </div>
  );
};

const Header = ({ children }: { children: ReactNode }) => {
  return <div>{children}</div>;
};

export const Title = ({ children }: { children: ReactNode }) => {
  return (
    <Heading size="2" className="mb-1.5">
      {children}
    </Heading>
  );
};

const Description = ({ children }: { children: ReactNode }) => {
  return <Paragraph>{children}</Paragraph>;
};

const FormContainer = ({ children }: { children: ReactNode }) => {
  return <div className="mt-10">{children}</div>;
};

export const AuthLayout = {
  Root,
  Header,
  Title,
  Description,
  FormContainer,

  SignInForm,
  SignInFormValidator,
  useSignInFormSubmit,

  SignUpForm,
  SignUpFormValidator,
  useSignUpFormSubmit,

  ForgotPasswordForm,
  ForgotPasswordFormValidator,
  useForgotPasswordFormSubmit,

  ResetPasswordForm,
  ResetPasswordFormValidator,
  useResetPasswordFormSubmit,
};
