import { captureRemixErrorBoundaryError } from "@sentry/remix";
import '@packages/ui/tailwind.css';
import {
  json,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useNavigation,
  useRouteError,
} from '@remix-run/react';
import { LoaderFunctionArgs } from '@vercel/remix';
import { clsx } from 'clsx';
import 'leaflet/dist/leaflet.css';
import { useEffect, useRef, useState } from 'react';
import { getToast } from 'remix-toast';
import { toast as notify, Toaster } from 'sonner';
import { UserSession } from './.server/user-session';
import { FirebaseManager } from './firebase-manager';
import './styles.css';

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { user, otherUsers } = await UserSession.getUsers(request);

  const { toast, headers } = await getToast(request);

  return json(
    {
      user: user?.toJSON(),
      otherUsers,
      toast,
      ENV: {
        APP_ENV: process.env.APP_ENV,
        GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
        FIREBASE_API_KEY: process.env.FIREBASE_API_KEY,
        FIREBASE_AUTH_DOMAIN: process.env.FIREBASE_AUTH_DOMAIN,
        FIREBASE_PROJECT_ID: process.env.FIREBASE_PROJECT_ID,
        FIREBASE_STORAGE_BUCKET: process.env.FIREBASE_STORAGE_BUCKET,
        FIREBASE_MESSAGING_SENDER_ID: process.env.FIREBASE_MESSAGING_SENDER_ID,
        FIREBASE_APP_ID: process.env.FIREBASE_APP_ID,
        FIREBASE_MEASUREMENT_ID: process.env.FIREBASE_MEASUREMENT_ID,
        FIREBASE_REGION: process.env.FIREBASE_REGION,
        FIREBASE_VAPID_KEY: process.env.FIREBASE_VAPID_KEY,
        STREAM_CHAT_KEY: process.env.STREAM_CHAT_KEY,
        WITH_FIREBASE_EMULATORS: process.env.WITH_FIREBASE_EMULATORS,
      },
    },
    { headers },
  );
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  const loaderData = useLoaderData<typeof loader>();

  useEffect(() => {
    if (loaderData.toast?.type === 'error') {
      notify.error(loaderData.toast.message);
    }

    if (loaderData.toast?.type === 'success') {
      notify.success(loaderData.toast.message);
    }
  }, [loaderData.toast]);

  useEffect(() => {
    if (!loaderData.user) {
      return;
    }

    FirebaseManager.initializeApps([loaderData.user.email, ...loaderData.otherUsers.map(otherUser => otherUser.email)]);
  }, []);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <GlobalLoading />

        <Outlet />

        <script
          dangerouslySetInnerHTML={{
            __html: `window.env = ${JSON.stringify(loaderData.ENV)}`,
          }}
        />

        <ScrollRestoration />
        <Scripts />
        <Toaster position="top-right" expand richColors />
      </body>
    </html>
  );
}

const GlobalLoading = () => {
  const { state } = useNavigation();

  const active = state !== 'idle';
  const [animationComplete, setAnimationComplete] = useState(true);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (active) {
      setAnimationComplete(false);
    }

    Promise.allSettled(ref.current.getAnimations().map(({ finished }) => finished)).then(() => !active && setAnimationComplete(true));
  }, [active]);

  return (
    <div
      role="progressbar"
      aria-hidden={!active}
      aria-valuetext={active ? 'Loading' : undefined}
      className="fixed inset-x-0 left-0 top-0 z-[9999] h-1"
    >
      <div
        ref={ref}
        className={clsx(
          state === 'idle' && animationComplete && 'w-0 opacity-0 transition-none',
          state === 'submitting' && 'w-4/12',
          state === 'loading' && 'w-10/12',
          state === 'idle' && !animationComplete && 'w-full',
          'bg-primary h-full transition-all duration-500 ease-in-out',
        )}
      />
    </div>
  );
};