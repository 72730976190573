import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, Firestore, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, Functions, getFunctions } from 'firebase/functions';
import { getMessaging, isSupported } from 'firebase/messaging';
import { connectStorageEmulator, FirebaseStorage, getStorage } from 'firebase/storage';

declare global {
  interface Window {
    env: Record<string, string>;
  }
}

class FirebaseManagerClass {
  private apps: { [key: string]: FirebaseApp } = {};

  private env = typeof window !== 'undefined' ? window.env : process.env;

  private emulate = typeof window !== 'undefined' && window.env.WITH_FIREBASE_EMULATORS === 'true';

  options = {
    apiKey: this.env.FIREBASE_API_KEY,
    authDomain: this.env.FIREBASE_AUTH_DOMAIN,
    projectId: this.env.FIREBASE_PROJECT_ID,
    storageBucket: this.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: this.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: this.env.FIREBASE_APP_ID,
    measurementId: this.env.FIREBASE_MEASUREMENT_ID,
  };

  getApp(email?: string) {
    let name = '[DEFAULT]';

    if (email) {
      name = this.emailToAppName(email);
    }

    if (!this.apps[name]) {
      this.apps[name] = initializeApp(this.options, name === '[DEFAULT]' ? undefined : name);

      const auth = getAuth(this.apps[name]);
      const firestore = getFirestore(this.apps[name]);
      const storage = getStorage(this.apps[name]);
      const functions = getFunctions(this.apps[name], this.env.FIREBASE_REGION);

      if (this.emulate) {
        connectAuthEmulator(auth, 'http://127.0.0.1:9099', { disableWarnings: true });
        connectFirestoreEmulator(firestore, 'localhost', 8080);
        connectStorageEmulator(storage, 'localhost', 9199);
        connectFunctionsEmulator(functions, 'localhost', 5001);
      }
    }

    const auth = getAuth(this.apps[name]);
    const firestore = getFirestore(this.apps[name]);
    const storage = getStorage(this.apps[name]);
    const functions = getFunctions(this.apps[name], this.env.FIREBASE_REGION);

    return {
      app: this.apps[name],
      auth,
      firestore,
      storage,
      functions,
    };
  }

  findApp(fn: (elems: { app: FirebaseApp; auth: Auth; firestore: Firestore; storage: FirebaseStorage; functions: Functions }) => boolean) {
    for (const key of Object.keys(this.apps)) {
      const app = this.apps[key];

      const elems = {
        app,
        auth: getAuth(app),
        firestore: getFirestore(app),
        storage: getStorage(app),
        functions: getFunctions(app, this.env.FIREBASE_REGION),
      };

      if (fn(elems)) {
        return elems;
      }
    }
  }

  async getMessaging(email?: string) {
    if (!(await isSupported())) {
      return undefined;
    }

    const { app } = this.getApp(email);

    return getMessaging(app);
  }

  initializeApps(emails: string[]) {
    emails.forEach(email => {
      this.getApp(email);
    });
  }

  private emailToAppName(email: string) {
    return email.replace(/[^a-zA-Z0-9]/g, '');
  }
}

export const FirebaseManager = new FirebaseManagerClass();
