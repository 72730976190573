import { ActionFunctionArgs, MetaFunction, redirect } from '@vercel/remix';
import { UserSession } from '../../.server/user-session';
import { AuthLayout } from '../_auth/layout';

export const meta: MetaFunction = () => {
  return [{ title: 'Sign In | Paragonix' }];
};

export const action = async ({ request }: ActionFunctionArgs) => {
  const form = await request.formData();
  const token = form.get('token');

  if (!token || typeof token !== 'string') {
    return redirect('/sign-in');
  }

  return UserSession.create(token, request.headers.get('Cookie') ?? undefined);
};

export default function Route() {
  const { onSubmit, error } = AuthLayout.useSignInFormSubmit();

  return (
    <>
      <AuthLayout.Header>
        <AuthLayout.Title>Sign in</AuthLayout.Title>

        <AuthLayout.Description>
          Dont have an account? <a href="/sign-up">Sign Up</a>
        </AuthLayout.Description>
      </AuthLayout.Header>

      <AuthLayout.FormContainer>
        <AuthLayout.SignInForm error={error} onSubmit={onSubmit} />
      </AuthLayout.FormContainer>
    </>
  );
}
