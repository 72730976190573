import { Alert, Button, Input } from '@packages/ui/core';
import { Link } from '@remix-run/react';
import { withZod } from '@remix-validated-form/with-zod';
import { confirmPasswordReset } from 'firebase/auth';
import { useState } from 'react';
import { ValidatedForm, useField, useIsSubmitting, useIsValid } from 'remix-validated-form';
import { z } from 'zod';
import { FirebaseManager } from '~/firebase-manager';
import { getFirebaseAuthErrorMessage } from '~/shared/utils/get-firebase-auth-error-message';

export const ResetPasswordFormValidator = withZod(
  z.object({
    password: z.string().min(6, { message: 'Password must be at least 6 characters long.' }),
  }),
);

export type ResetPasswordFormProps = {
  oobCode: {
    value: string;
    invalid: boolean;
  };
  success?: boolean;
  error?: string;
  signInUrl?: string;
  onSubmit: (data: { password: string }) => void;
};

export type ResetPasswordFormData = {
  password: string;
};

export function useResetPasswordFormSubmit() {
  const [success, setSuccess] = useState<boolean>();
  const [error, setError] = useState<string>();

  return {
    onSubmit: async (oobCode: string, data: ResetPasswordFormData) => {
      const client = FirebaseManager.getApp();

      setSuccess(false);
      setError(undefined);

      try {
        await confirmPasswordReset(client.auth, oobCode, data.password);
        setSuccess(true);
      } catch (err: any) {
        setError(getFirebaseAuthErrorMessage(err.code));
      }
    },
    success,
    error,
  };
}

export const ResetPasswordForm = ({ oobCode, success = false, error, signInUrl = '/sign-in', onSubmit }: ResetPasswordFormProps) => {
  const password = useField('password', { formId: 'reset-password' });

  const isValid = useIsValid('reset-password');
  const isSubmitting = useIsSubmitting('reset-password');

  const disabled = (!error && isSubmitting) || !isValid;
  const isLoading = !error && isSubmitting;

  if (success) {
    return (
      <div className="space-y-4">
        <Alert.Root color="success">
          <Alert.Title>All good!</Alert.Title>
          <Alert.Description>Your password has been reset! You can now sign in with your new password.</Alert.Description>
        </Alert.Root>

        <Link to={signInUrl} className="block">
          <Button isFullWidth>Back to Sign In</Button>
        </Link>
      </div>
    );
  }

  if (oobCode.invalid) {
    return (
      <div className="space-y-4">
        <Alert.Root color="danger">
          <Alert.Title>Something went wrong!</Alert.Title>
          <Alert.Description>
            The password reset link is invalid or has expired. Please request a new password reset link and try again.
          </Alert.Description>
        </Alert.Root>

        <Link to={signInUrl} className="block">
          <Button isFullWidth>Back to Sign In</Button>
        </Link>
      </div>
    );
  }

  return (
    <ValidatedForm
      id="reset-password"
      validator={ResetPasswordFormValidator}
      onSubmit={(data, event) => {
        event.preventDefault();
        onSubmit(data);
      }}
      method="POST"
    >
      <div className="space-y-6">
        <div className="space-y-4">
          {error && (
            <Alert.Root color="danger">
              <Alert.Title>Something went wrong!</Alert.Title>
              <Alert.Description>{error}</Alert.Description>
            </Alert.Root>
          )}

          <Input.Root {...password.getInputProps()} type="password">
            <Input.Header>
              <Input.Label>Password</Input.Label>
            </Input.Header>

            <Input.Field />

            {password.error && <Input.Error>{password.error}</Input.Error>}
          </Input.Root>
        </div>

        <Button type="submit" size="lg" disabled={disabled} isLoading={isLoading} isFullWidth>
          Confirm
        </Button>
      </div>
    </ValidatedForm>
  );
};
