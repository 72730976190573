// src/client/auth.ts
import { browserLocalPersistence, signInWithEmailAndPassword as fbSignInWithEmailAndPassword, setPersistence } from "firebase/auth";
async function signInWithEmailAndPassword(auth, email, password) {
  await setPersistence(auth, browserLocalPersistence);
  return fbSignInWithEmailAndPassword(auth, email, password);
}
async function signOut(auth) {
  return auth.signOut();
}

// src/client/functions.ts
import { httpsCallable } from "firebase/functions";
async function sendEmailVerificationFn(functions) {
  const fn = httpsCallable(functions, "sendEmailVerification");
  return fn({ source: "web" });
}
async function confirmEmailVerificationFn(functions, code) {
  const fn = httpsCallable(functions, "confirmEmailVerification");
  return fn({ code });
}
async function sendPasswordResetFn(functions, email) {
  const fn = httpsCallable(functions, "sendPasswordReset");
  return fn({ email, source: "web" });
}
async function createSessionFn(functions, params) {
  const fn = httpsCallable(functions, "createSession");
  return fn({ ...params, schemaVersion: 6 });
}
async function joinSessionFn(functions, joinKey) {
  const fn = httpsCallable(functions, "joinSession");
  return fn({ os: "web", joinKey, schemaVersion: 6 });
}
function startOrganizationValidationFn(functions, organizationId) {
  const fn = httpsCallable(functions, "startOrganizationValidation");
  return fn({ organizationId });
}
async function confirmOrganizationValidationFn(functions, organizationId, userIds) {
  const fn = httpsCallable(functions, "confirmOrganizationValidation");
  return fn({ organizationId, userIds });
}
function inviteToOrganizationFn(functions, organizationId, email) {
  const fn = httpsCallable(functions, "inviteToOrganization");
  return fn({ organizationId, email });
}
function sendOrganizationMemberInviteFn(functions, organizationId, userIds) {
  const fn = httpsCallable(functions, "sendOrganizationMemberInvite");
  return fn({ organizationId, userIds });
}
async function requestOrganizationAccessFn(functions, params) {
  const fn = httpsCallable(functions, "requestOrganizationAccess");
  return fn(params);
}
function grantOrganizationAccessFn(functions, organizationId, userId) {
  const fn = httpsCallable(functions, "grantOrganizationAccess");
  return fn({ organizationId, userIds: [userId] });
}
function rejectOrganizationAccessRequestFn(functions, organizationId, userId) {
  const fn = httpsCallable(functions, "rejectOrganizationAccessRequest");
  return fn({ organizationId, userIds: [userId] });
}
async function cancelOrganizationAccessRequestFn(functions, organizationId, userId) {
  const fn = httpsCallable(functions, "cancelOrganizationAccessRequest");
  return fn({ organizationId, userId });
}
async function joinOrganizationFn(functions, params) {
  const fn = httpsCallable(functions, "joinOrganization");
  return fn(params);
}
async function leaveOrganizationFn(functions, organizationId) {
  const fn = httpsCallable(functions, "leaveOrganization");
  return fn({ organizationId });
}
async function searchAirports(functions, query21) {
  const fn = httpsCallable(functions, "searchAirports");
  return fn({ query: query21 });
}
async function exportSessionsToSheetFn(functions, sessionIds) {
  const fn = httpsCallable(functions, "exportSessionsToSheet");
  return fn({ sessionIds });
}
async function createSharedSessionFn(functions, sessionId) {
  const fn = httpsCallable(functions, "createSharedSession");
  return fn({ sessionId });
}
async function createDemoSessionFn(functions, params) {
  const fn = httpsCallable(functions, "createDemoSession");
  return fn(params);
}

// src/client/messaging.ts
import { doc, setDoc } from "firebase/firestore";
import { getToken, onMessage } from "firebase/messaging";
async function getMessagingToken(config, vapidKey, messaging, firestore, userId) {
  const serviceWorkerRegistration = await registerMessagingServiceWorker(config);
  const currentToken = await getToken(messaging, {
    vapidKey,
    serviceWorkerRegistration
  });
  if (currentToken != null) {
    await setDoc(doc(firestore, "users", userId, "pushTokens", currentToken), {
      environment: "development",
      platform: "web"
    });
  }
  return currentToken;
}
function onFirebaseMessage(messaging, callback) {
  return onMessage(messaging, callback);
}
async function registerMessagingServiceWorker(config) {
  const firebaseConfig = encodeURIComponent(JSON.stringify(config));
  return navigator.serviceWorker.register(`/firebase-messaging-sw.js?firebaseConfig=${firebaseConfig}`);
}

// src/client/organizations.ts
import {
  addDoc,
  collection,
  doc as doc2,
  getDoc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  updateDoc,
  where
} from "firebase/firestore";
import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";

// src/models/advanced-tracking.ts
var AdvancedTracking = class {
  acceleration;
  externalTemperature;
  humidity;
  latitude;
  light;
  longitude;
  pressure;
  temperature;
  batterPowerPercent;
  accuracy;
  timestamp;
  constructor(params) {
    this.acceleration = params.acceleration;
    this.externalTemperature = params.externalTemperature;
    this.humidity = params.humidity;
    this.latitude = params.latitude;
    this.light = params.light;
    this.longitude = params.longitude;
    this.pressure = params.pressure;
    this.batterPowerPercent = params.batterPowerPercent;
    this.accuracy = params.accuracy;
    this.temperature = params.temperature;
    this.timestamp = params.timestamp instanceof Date ? params.timestamp : params.timestamp ? new Date(params.timestamp) : void 0;
  }
  toJSON() {
    return {
      acceleration: this.acceleration,
      externalTemperature: this.externalTemperature,
      humidity: this.humidity,
      latitude: this.latitude,
      light: this.light,
      longitude: this.longitude,
      pressure: this.pressure,
      batterPowerPercent: this.batterPowerPercent,
      accuracy: this.accuracy,
      temperature: this.temperature,
      timestamp: this.timestamp
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};
var AdvancedTrackingConverter = {
  toFirestore() {
    return {};
  },
  fromFirestore(doc10) {
    const data = doc10.data();
    return new AdvancedTracking({ ...data, id: doc10.id, timestamp: data.timestamp?.toDate() });
  }
};

// src/models/airport.ts
var Airport = class {
  name;
  city;
  code;
  countryCode;
  timezone;
  longitude;
  latitude;
  constructor(params) {
    this.name = params.name;
    this.city = params.city;
    this.code = params.code;
    this.countryCode = params.countryCode;
    this.timezone = params.timezone;
    this.longitude = params.longitude;
    this.latitude = params.latitude;
  }
  toJSON() {
    return {
      name: this.name,
      city: this.city,
      code: this.code,
      countryCode: this.countryCode,
      timezone: this.timezone,
      longitude: this.longitude,
      latitude: this.latitude
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};

// src/models/audit-log.ts
var AuditLog = class {
  id;
  userId;
  event;
  context;
  contextDate;
  timestampUser;
  timestampServer;
  timezone;
  device;
  appVersion;
  os;
  osVersion;
  constructor(params) {
    this.id = params.id;
    this.userId = params.userId;
    this.event = params.event;
    this.context = params.context;
    this.contextDate = typeof params.contextDate === "string" ? new Date(params.contextDate) : params.contextDate;
    this.timestampUser = typeof params.timestampUser === "string" ? new Date(params.timestampUser) : params.timestampUser;
    this.timestampServer = typeof params.timestampServer === "string" ? new Date(params.timestampServer) : params.timestampServer;
    this.timezone = params.timezone;
    this.device = params.device;
    this.appVersion = params.appVersion;
    this.os = params.os;
    this.osVersion = params.osVersion;
  }
  toJSON() {
    return {
      id: this.id,
      userId: this.userId,
      event: this.event,
      context: this.context,
      timestampUser: this.timestampUser,
      timestampServer: this.timestampServer,
      timezone: this.timezone,
      device: this.device,
      appVersion: this.appVersion,
      os: this.os,
      osVersion: this.osVersion
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};
var AuditLogConverter = {
  toFirestore() {
    return {};
  },
  fromFirestore(doc10) {
    const data = doc10.data();
    return new AuditLog({
      ...data,
      id: doc10.id,
      contextDate: data.contextDate?.toDate(),
      timestampUser: data.timestampUser?.toDate(),
      timestampServer: data.timestampServer?.toDate()
    });
  }
};

// src/models/cannulation-type.ts
var CannulationTypeUtil = class {
  static toUIString(type) {
    switch (type) {
      case "TANRP" /* TANRP */:
        return "TA-NRP";
      case "ANRP" /* ANRP */:
        return "A-NRP";
      default:
        return "unknown";
    }
  }
};

// src/models/destination.ts
var Destination = class {
  id;
  name;
  handle;
  organs;
  types;
  isSessionOption;
  isOrganizationOption;
  owner;
  constructor(params) {
    this.id = params.id;
    this.name = params.name;
    this.handle = params.handle;
    this.organs = params.organs ?? [];
    this.types = params.types ?? [];
    this.isSessionOption = params.isSessionOption ?? false;
    this.isOrganizationOption = params.isOrganizationOption ?? false;
    this.owner = params.owner;
  }
  toJSON() {
    return {
      id: this.id,
      name: this.name,
      handle: this.handle,
      organs: this.organs,
      types: this.types,
      isSessionOption: this.isSessionOption,
      isOrganizationOption: this.isOrganizationOption,
      owner: this.owner
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};
var DestinationConverter = {
  toFirestore() {
    return {};
  },
  fromFirestore(doc10) {
    const data = doc10.data();
    return new Destination({ ...data, id: doc10.id });
  }
};

// src/models/flight.ts
var Flight = class {
  id;
  flightId;
  ident;
  airline;
  state;
  cancelled;
  progressPercent;
  arrivalDelay;
  actualArrival;
  actualDeparture;
  departureDelay;
  estimatedDeparture;
  estimatedArrival;
  plannedArrival;
  plannedDeparture;
  origin;
  destination;
  destinationType;
  dataTimestamp;
  deleted;
  deletedAt;
  constructor(params) {
    this.id = params.id;
    this.flightId = params.flightId;
    this.ident = params.ident;
    this.airline = params.airline;
    this.state = params.state;
    this.cancelled = params.cancelled;
    this.progressPercent = params.progressPercent;
    this.arrivalDelay = params.arrivalDelay;
    this.departureDelay = params.departureDelay;
    this.origin = params.origin ? new Airport(params.origin) : void 0;
    this.destination = params.destination ? new Airport(params.destination) : void 0;
    this.destinationType = params.destinationType;
    this.dataTimestamp = params.dataTimestamp;
    this.deleted = params.deleted ?? false;
    this.deletedAt = params.deletedAt;
    this.actualArrival = params.actualArrival instanceof Date ? params.actualArrival : params.actualArrival ? new Date(params.actualArrival) : void 0;
    this.actualDeparture = params.actualDeparture instanceof Date ? params.actualDeparture : params.actualDeparture ? new Date(params.actualDeparture) : void 0;
    this.estimatedDeparture = params.estimatedDeparture instanceof Date ? params.estimatedDeparture : params.estimatedDeparture ? new Date(params.estimatedDeparture) : void 0;
    this.estimatedArrival = params.estimatedArrival instanceof Date ? params.estimatedArrival : params.estimatedArrival ? new Date(params.estimatedArrival) : void 0;
    this.plannedArrival = params.plannedArrival instanceof Date ? params.plannedArrival : params.plannedArrival ? new Date(params.plannedArrival) : void 0;
    this.plannedDeparture = params.plannedDeparture instanceof Date ? params.plannedDeparture : params.plannedDeparture ? new Date(params.plannedDeparture) : void 0;
  }
  toJSON() {
    return {
      id: this.id,
      flightId: this.flightId,
      ident: this.ident,
      airline: this.airline,
      state: this.state,
      cancelled: this.cancelled,
      progressPercent: this.progressPercent,
      arrivalDelay: this.arrivalDelay,
      actualArrival: this.actualArrival,
      actualDeparture: this.actualDeparture,
      departureDelay: this.departureDelay,
      estimatedDeparture: this.estimatedDeparture,
      estimatedArrival: this.estimatedArrival,
      plannedArrival: this.plannedArrival,
      plannedDeparture: this.plannedDeparture,
      origin: this.origin?.toJSON(),
      destination: this.destination?.toJSON(),
      destinationType: this.destinationType,
      dataTimestamp: this.dataTimestamp,
      deleted: this.deleted,
      deletedAt: this.deletedAt
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};
var FlightConverter = {
  toFirestore() {
    return {};
  },
  fromFirestore(doc10) {
    const data = doc10.data();
    return new Flight({
      ...data,
      id: doc10.id,
      actualArrival: data.actualArrival?.toDate(),
      actualDeparture: data.actualDeparture?.toDate(),
      estimatedDeparture: data.estimatedDeparture?.toDate(),
      estimatedArrival: data.estimatedArrival?.toDate(),
      plannedArrival: data.plannedArrival?.toDate(),
      plannedDeparture: data.plannedDeparture?.toDate(),
      dataTimestamp: data.dataTimestamp?.toDate()
    });
  }
};

// src/models/flight-position.ts
var FlightPosition = class {
  flightId;
  latitude;
  longitude;
  heading;
  altitude;
  groundspeed;
  timestamp;
  constructor(params) {
    this.flightId = params.flightId;
    this.latitude = params.latitude;
    this.longitude = params.longitude;
    this.heading = params.heading;
    this.altitude = params.altitude;
    this.groundspeed = params.groundspeed;
    this.timestamp = params.timestamp instanceof Date ? params.timestamp : params.timestamp ? new Date(params.timestamp) : void 0;
  }
  toJSON() {
    return {
      flightId: this.flightId,
      latitude: this.latitude,
      longitude: this.longitude,
      heading: this.heading,
      altitude: this.altitude,
      groundspeed: this.groundspeed,
      timestamp: this.timestamp
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};
var FlightPositionConverter = {
  toFirestore() {
    return {};
  },
  fromFirestore(doc10) {
    const data = doc10.data();
    return new FlightPosition({ ...data, id: doc10.id, timestamp: data.timestamp?.toDate() });
  }
};

// src/models/organ-reading.ts
var OrganReading = class {
  data;
  event;
  timestamp;
  constructor(params) {
    this.data = params.data;
    this.event = params.event;
    this.timestamp = typeof params.timestamp === "string" ? new Date(params.timestamp) : params.timestamp;
  }
  get isReading() {
    return this.event === "reading";
  }
  get isStartedEvent() {
    return this.event === "started";
  }
  get isStoppedEvent() {
    return this.event === "stopped";
  }
  toJSON() {
    return {
      data: this.data,
      event: this.event,
      timestamp: this.timestamp
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};

// src/models/reading.ts
var Reading = class {
  id;
  event;
  ambient;
  probe;
  pressure;
  pumpVoltage;
  timestamp;
  constructor(params) {
    this.id = params.id;
    this.event = params.event;
    this.ambient = params.ambient;
    this.probe = params.probe;
    this.pressure = params.pressure;
    this.pumpVoltage = params.pumpVoltage;
    this.timestamp = typeof params.timestamp === "string" ? new Date(params.timestamp) : params.timestamp;
  }
  get isReading() {
    return this.event === "reading";
  }
  get isStartedEvent() {
    return this.event === "started";
  }
  get isStoppedEvent() {
    return this.event === "stopped";
  }
  toOrganReading() {
    return new OrganReading({
      data: {
        ambient: this.ambient,
        probe: this.probe,
        pressure: this.pressure,
        pumpVoltage: this.pumpVoltage
      },
      event: this.event,
      timestamp: this.timestamp
    });
  }
  toJSON() {
    return {
      event: this.event,
      ambient: this.ambient,
      probe: this.probe,
      pressure: this.pressure,
      pumpVoltage: this.pumpVoltage,
      timestamp: this.timestamp
    };
  }
  toReadingCompressed() {
    return new ReadingCompressed({
      e: this.event,
      a: this.ambient,
      p: this.probe,
      pr: this.pressure,
      pv: this.pumpVoltage,
      t: this.timestamp
    });
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};

// src/models/reading-compressed.ts
var ReadingCompressed = class {
  e;
  a;
  p;
  pr;
  pv;
  t;
  constructor(params) {
    this.e = params.e;
    this.a = params.a;
    this.p = params.p;
    this.pr = params.pr;
    this.pv = params.pv;
    this.t = typeof params.t === "string" ? new Date(params.t) : params.t;
  }
  toJSON() {
    return {
      e: this.e,
      a: this.a,
      p: this.p,
      pr: this.pr,
      pv: this.pv,
      t: this.t
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
  toReading() {
    return new Reading({
      event: this.e,
      ambient: this.a,
      probe: this.p,
      pressure: this.pr,
      pumpVoltage: this.pv,
      timestamp: this.t
    });
  }
};

// src/models/log.ts
var Log = class {
  readings;
  constructor(params) {
    this.readings = params.readings ? params.readings.map((reading) => new ReadingCompressed(reading).toReading()) : [];
  }
  toJSON() {
    return {
      readings: this.readings.map((reading) => reading.toJSON())
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};
var LogConverter = {
  toFirestore() {
    return {};
  },
  fromFirestore(doc10) {
    const data = doc10.data();
    return new Log({
      readings: data.log?.map((reading) => new ReadingCompressed({ ...reading, t: reading.t?.toDate() })) ?? []
    });
  }
};

// src/models/logger-data.ts
var LoggerData = class {
  probe;
  ambient;
  pressure;
  timestamp;
  constructor(params) {
    this.probe = params.probe;
    this.ambient = params.ambient;
    this.pressure = params.pressure;
    this.timestamp = params.timestamp instanceof Date ? params.timestamp : new Date(params.timestamp);
  }
  toJSON() {
    return {
      probe: this.probe,
      ambient: this.ambient,
      pressure: this.pressure,
      timestamp: this.timestamp
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};

// src/models/logger-location.ts
var LoggerLocation = class {
  latitude;
  longitude;
  horizontalAccuracy;
  verticalAccuracy;
  timestamp;
  constructor(params) {
    this.latitude = params.latitude;
    this.longitude = params.longitude;
    this.horizontalAccuracy = params.horizontalAccuracy ?? -1;
    this.verticalAccuracy = params.verticalAccuracy ?? -1;
    this.timestamp = params.timestamp instanceof Date ? params.timestamp : new Date(params.timestamp);
  }
  toJSON() {
    return {
      latitude: this.latitude,
      longitude: this.longitude,
      horizontalAccuracy: this.horizontalAccuracy,
      verticalAccuracy: this.verticalAccuracy,
      timestamp: this.timestamp
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};
var LoggerLocationConverter = {
  toFirestore() {
    return {};
  },
  fromFirestore(doc10) {
    const data = doc10.data();
    return new LoggerLocation({ ...data, id: doc10.id, timestamp: data.timestamp?.toDate() });
  }
};

// src/models/lung-position.ts
var LungPositionTypeUtil = class {
  static toUIString(type) {
    switch (type) {
      case "SUPINE" /* SUPINE */:
        return "Supine";
      case "PRONE" /* PRONE */:
        return "Prone";
      default:
        return "unknown";
    }
  }
};

// src/models/organ-logger-connection.ts
var OrganLoggerConnection = class {
  connectedBy;
  pairedDeviceIdentifier;
  constructor(params) {
    this.connectedBy = params.connectedBy;
    this.pairedDeviceIdentifier = params.pairedDeviceIdentifier;
  }
  toJSON() {
    return {
      connectedBy: this.connectedBy,
      pairedDeviceIdentifier: this.pairedDeviceIdentifier
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};

// src/models/organ-logger-data.ts
var OrganLoggerData = class {
  probe;
  ambient;
  pressure;
  pumpVoltage;
  fluidPressure;
  flow;
  resistance;
  averageFluidPressure;
  averageFlow;
  averageResistance;
  batteryVoltage;
  rssi;
  timestamp;
  constructor(params) {
    this.probe = params.probe;
    this.ambient = params.ambient;
    this.pressure = params.pressure;
    this.pumpVoltage = params.pumpVoltage;
    this.fluidPressure = params.fluidPressure;
    this.flow = params.flow;
    this.resistance = params.resistance;
    this.averageFluidPressure = params.averageFluidPressure;
    this.averageFlow = params.averageFlow;
    this.averageResistance = params.averageResistance;
    this.batteryVoltage = params.batteryVoltage;
    this.rssi = params.rssi;
    this.timestamp = typeof params.timestamp === "string" ? new Date(params.timestamp) : params.timestamp;
  }
  toJSON() {
    return {
      probe: this.probe,
      ambient: this.ambient,
      pressure: this.pressure,
      pumpVoltage: this.pumpVoltage,
      fluidPressure: this.fluidPressure,
      flow: this.flow,
      resistance: this.resistance,
      averageFluidPressure: this.averageFluidPressure,
      averageFlow: this.averageFlow,
      averageResistance: this.averageResistance,
      batteryVoltage: this.batteryVoltage,
      rssi: this.rssi,
      timestamp: this.timestamp
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};

// src/models/organ-logger.ts
var OrganLogger = class {
  serialNo;
  passkey;
  type;
  state;
  connection;
  data;
  constructor(params) {
    this.serialNo = params.serialNo;
    this.passkey = params.passkey;
    this.type = params.type;
    this.state = params.state;
    this.connection = params.connection ? new OrganLoggerConnection(params.connection) : void 0;
    this.data = params.data ? new OrganLoggerData(params.data) : void 0;
  }
  toJSON() {
    return {
      serialNo: this.serialNo,
      passkey: this.passkey,
      type: this.type,
      state: this.state,
      connection: this.connection?.toJSON(),
      data: this.data?.toJSON()
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};

// src/models/session-report-data.ts
var SessionReportData = class {
  ambientAverage;
  ambientMaximum;
  ambientMinimum;
  probeAverage;
  probeMaximum;
  probeMinimum;
  pressureAverage;
  pressureMaximum;
  pressureMinimum;
  averageFluidPressureAverage;
  averageFluidPressureMaximum;
  averageFluidPressureMinimum;
  averageFlowAverage;
  averageFlowMinimum;
  averageFlowMaximum;
  averageResistanceAverage;
  averageResistanceMinimum;
  averageResistanceMaximum;
  pumpVoltageStart;
  pumpVoltageEnd;
  distance;
  distanceTotal;
  distanceDataSource;
  readingsCount;
  readingsCountTotal;
  loggerStartedAt;
  loggerStoppedAt;
  loggingDurationMillis;
  firstReading;
  lastReading;
  timeOnPump;
  constructor(params) {
    this.ambientAverage = params.ambientAverage;
    this.ambientMaximum = params.ambientMaximum;
    this.ambientMinimum = params.ambientMinimum;
    this.probeAverage = params.probeAverage;
    this.probeMaximum = params.probeMaximum;
    this.probeMinimum = params.probeMinimum;
    this.pressureAverage = params.pressureAverage;
    this.pressureMaximum = params.pressureMaximum;
    this.pressureMinimum = params.pressureMinimum;
    this.averageFluidPressureAverage = params.averageFluidPressureAverage;
    this.averageFluidPressureMaximum = params.averageFluidPressureMaximum;
    this.averageFluidPressureMinimum = params.averageFluidPressureMinimum;
    this.averageFlowAverage = params.averageFlowAverage;
    this.averageFlowMaximum = params.averageFlowMaximum;
    this.averageFlowMinimum = params.averageFlowMinimum;
    this.averageResistanceAverage = params.averageResistanceAverage;
    this.averageResistanceMaximum = params.averageResistanceMaximum;
    this.averageResistanceMinimum = params.averageResistanceMinimum;
    this.pumpVoltageStart = params.pumpVoltageStart;
    this.pumpVoltageEnd = params.pumpVoltageEnd;
    this.distance = params.distance;
    this.distanceTotal = params.distanceTotal;
    this.distanceDataSource = params.distanceDataSource;
    this.readingsCount = params.readingsCount;
    this.readingsCountTotal = params.readingsCountTotal;
    this.loggingDurationMillis = params.loggingDurationMillis;
    this.loggerStartedAt = params.loggerStartedAt instanceof Date ? params.loggerStartedAt : params.loggerStartedAt ? new Date(params.loggerStartedAt) : void 0;
    this.loggerStoppedAt = params.loggerStoppedAt instanceof Date ? params.loggerStoppedAt : params.loggerStoppedAt ? new Date(params.loggerStoppedAt) : void 0;
    this.firstReading = params.firstReading instanceof Date ? params.firstReading : params.firstReading ? new Date(params.firstReading) : void 0;
    this.lastReading = params.lastReading instanceof Date ? params.lastReading : params.lastReading ? new Date(params.lastReading) : void 0;
    if (params.timeOnPump) {
      const startTimestamp = params.timeOnPump.startTimestamp instanceof Date ? params.timeOnPump.startTimestamp : new Date(params.timeOnPump.startTimestamp);
      const endTimestamp = params.timeOnPump.endTimestamp instanceof Date ? params.timeOnPump.endTimestamp : params.timeOnPump.endTimestamp ? new Date(params.timeOnPump.endTimestamp) : void 0;
      this.timeOnPump = {
        ...params.timeOnPump,
        startTimestamp,
        endTimestamp
      };
    }
  }
  toJSON() {
    return {
      ambientAverage: this.ambientAverage,
      ambientMaximum: this.ambientMaximum,
      ambientMinimum: this.ambientMinimum,
      probeAverage: this.probeAverage,
      probeMaximum: this.probeMaximum,
      probeMinimum: this.probeMinimum,
      pressureAverage: this.pressureAverage,
      pressureMaximum: this.pressureMaximum,
      pressureMinimum: this.pressureMinimum,
      averageFluidPressureAverage: this.averageFluidPressureAverage,
      averageFluidPressureMaximum: this.averageFluidPressureMaximum,
      averageFluidPressureMinimum: this.averageFluidPressureMinimum,
      averageFlowAverage: this.averageFlowAverage,
      averageFlowMaximum: this.averageFlowMaximum,
      averageFlowMinimum: this.averageFlowMinimum,
      averageResistanceAverage: this.averageResistanceAverage,
      averageResistanceMaximum: this.averageResistanceMaximum,
      averageResistanceMinimum: this.averageResistanceMinimum,
      pumpVoltageStart: this.pumpVoltageStart,
      pumpVoltageEnd: this.pumpVoltageEnd,
      distance: this.distance,
      distanceTotal: this.distanceTotal,
      distanceDataSource: this.distanceDataSource,
      readingsCount: this.readingsCount,
      readingsCountTotal: this.readingsCountTotal,
      loggerStartedAt: this.loggerStartedAt,
      loggerStoppedAt: this.loggerStoppedAt,
      loggingDurationMillis: this.loggingDurationMillis,
      firstReading: this.firstReading,
      lastReading: this.lastReading,
      timeOnPump: this.timeOnPump
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};

// src/models/organ.ts
var Organ = class {
  id;
  referenceId;
  sessionId;
  product;
  organ;
  position;
  advancedTrackerId;
  reportData;
  logger;
  constructor(params) {
    this.id = params.id;
    this.referenceId = params.referenceId;
    this.sessionId = params.sessionId;
    this.product = params.product;
    this.organ = params.organ;
    this.position = params.position;
    this.logger = params.logger ? new OrganLogger(params.logger) : void 0;
    this.advancedTrackerId = params.advancedTrackerId;
    this.reportData = params.reportData ? new SessionReportData(params.reportData) : void 0;
  }
  toJSON() {
    return {
      id: this.id,
      referenceId: this.referenceId,
      sessionId: this.sessionId,
      product: this.product,
      organ: this.organ,
      position: this.position,
      logger: this.logger?.toJSON(),
      advancedTrackerId: this.advancedTrackerId,
      reportData: this.reportData?.toJSON()
    };
  }
};
var OrganConverter = {
  toFirestore() {
    return {};
  },
  fromFirestore(doc10) {
    const data = doc10.data();
    return new Organ({
      ...data,
      id: doc10.id,
      logger: parseOrganLogger(data.logger),
      reportData: parseSessionReportData(data.reportData)
    });
  }
};
function parseOrganLogger(data) {
  return data ? {
    ...data,
    data: data.data ? parseOrganLoggerData(data.data) : void 0
  } : void 0;
}
function parseOrganLoggerData(data) {
  return data ? {
    ...data,
    timestamp: data.timestamp?.toDate()
  } : void 0;
}
function parseSessionReportData(data) {
  return data ? {
    ...data,
    loggerStartedAt: data.loggerStartedAt?.toDate(),
    loggerStoppedAt: data.loggerStoppedAt?.toDate(),
    firstReading: data.firstReading?.toDate(),
    lastReading: data.lastReading?.toDate(),
    timeOnPump: data.timeOnPump ? {
      ...data.timeOnPump,
      startTimestamp: data.timeOnPump.startTimestamp?.toDate(),
      endTimestamp: data.timeOnPump.endTimestamp?.toDate()
    } : void 0
  } : void 0;
}

// src/models/organ-readings-page.ts
var OrganReadingsPage = class {
  id;
  logs;
  start;
  end;
  numberOfLogs;
  pageSize;
  order;
  constructor(params) {
    this.id = params.id;
    this.logs = params.logs?.map((log) => new OrganReading(log)) ?? [];
    this.numberOfLogs = params.numberOfLogs;
    this.pageSize = params.pageSize;
    this.order = params.order;
    this.start = typeof params.start === "string" ? new Date(params.start) : params.start;
    this.end = typeof params.end === "string" ? new Date(params.end) : params.end;
  }
  toJSON() {
    return {
      id: this.id,
      logs: this.logs.map((log) => log.toJSON()),
      start: this.start,
      end: this.end,
      numberOfLogs: this.numberOfLogs,
      pageSize: this.pageSize,
      order: this.order
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};
var OrganReadingsPageConverter = {
  toFirestore() {
    return {};
  },
  fromFirestore(doc10) {
    const data = doc10.data();
    return new OrganReadingsPage({
      ...data,
      logs: data.logs?.map((log) => new OrganReading({ ...log, timestamp: log.timestamp?.toDate() })) ?? [],
      start: data.start?.toDate(),
      end: data.end?.toDate()
    });
  }
};

// src/models/product-type.ts
var ProductTypeUtil = class {
  static toUIString(type, legalMark = true) {
    switch (type) {
      case "sherpapak-cts" /* SHERPAPAK */:
        return legalMark ? "SherpaPak\xAE" : "SherpaPak";
      case "lungguard" /* LUNGGUARD */:
        return legalMark ? "LUNGguard\xAE" : "LUNGguard";
      case "baroguard" /* BAROGUARD */:
        return legalMark ? "BAROguard\xAE" : "BAROguard";
      case "liverguard" /* LIVERGUARD */:
        return legalMark ? "LIVERguard\xAE" : "LIVERguard";
      case "sherpapak-kts" /* KTS */:
        return legalMark ? "Kidney Transport System" : "Kidney Transport System";
      case "kidneyvault" /* KIDNEYVAULT */:
        return legalMark ? "KidneyVault\u2122" : "KidneyVault";
      case "pancreaspak" /* PANCREASPAK */:
        return "PancreasPak\u2122";
      case "vantage-point" /* VANTAGEPOINT */:
        return "VantagePoint Tracking ";
      default:
        return "SherpaPak\xAE";
    }
  }
  static toColors(product, organPosition) {
    const COLOR_SHERPAPAK_PRIMARY = "#C91E4B";
    const COLOR_SHERPAPAK_PRIMARY_LIGHT = "#F5D8DE";
    const COLOR_LUNGGUARD_PRIMARY = "#3BADDE";
    const COLOR_LUNGGUARD_PRIMARY_LIGHT = "#DBEFF8";
    const COLOR_LIVERGUARD_PRIMARY = "#E97D29";
    const COLOR_LIVERGUARD_PRIMARY_LIGHT = "#FBE7D8";
    const COLOR_KTS_PRIMARY = "#73BB44";
    const COLOR_KTS_PRIMARY_LIGHT = "#EDFFE0";
    const COLOR_KIDNEYVAULT_PRIMARY = "#000";
    const COLOR_KIDNEYVAULT_PRIMARY_LIGHT = "#000";
    const COLOR_KIDNEYVAULT_POSITION_LEFT_PRIMARY = "#000";
    const COLOR_KIDNEYVAULT_POSITION_LEFT_PRIMARY_LIGHT = "#000";
    const COLOR_KIDNEYVAULT_POSITION_RIGHT_PRIMARY = "#000";
    const COLOR_KIDNEYVAULT_POSITION_RIGHT_PRIMARY_LIGHT = "#000";
    const COLOR_PANCREASPAK_PRIMARY = "#007D8A";
    const COLOR_PANCREASPAK_PRIMARY_LIGHT = "#BFDADD";
    const COLOR_VANTAGEPOINT_PRIMARY = "#323031";
    const COLOR_VANTAGEPOINT_PRIMARY_LIGHT = "#D3D3D3";
    if (product === "lungguard" /* LUNGGUARD */) {
      return { primary: COLOR_LUNGGUARD_PRIMARY, primaryLight: COLOR_LUNGGUARD_PRIMARY_LIGHT };
    }
    if (product === "baroguard" /* BAROGUARD */) {
      return { primary: COLOR_LUNGGUARD_PRIMARY, primaryLight: COLOR_LUNGGUARD_PRIMARY_LIGHT };
    }
    if (product === "liverguard" /* LIVERGUARD */) {
      return { primary: COLOR_LIVERGUARD_PRIMARY, primaryLight: COLOR_LIVERGUARD_PRIMARY_LIGHT };
    }
    if (product === "sherpapak-kts" /* KTS */) {
      return { primary: COLOR_KTS_PRIMARY, primaryLight: COLOR_KTS_PRIMARY_LIGHT };
    }
    if (product === "pancreaspak" /* PANCREASPAK */) {
      return { primary: COLOR_PANCREASPAK_PRIMARY, primaryLight: COLOR_PANCREASPAK_PRIMARY_LIGHT };
    }
    if (product === "kidneyvault" /* KIDNEYVAULT */ && !organPosition) {
      return { primary: COLOR_KIDNEYVAULT_PRIMARY, primaryLight: COLOR_KIDNEYVAULT_PRIMARY_LIGHT };
    }
    if (product === "kidneyvault" /* KIDNEYVAULT */ && organPosition === "LEFT" /* LEFT */) {
      return { primary: COLOR_KIDNEYVAULT_POSITION_LEFT_PRIMARY, primaryLight: COLOR_KIDNEYVAULT_POSITION_LEFT_PRIMARY_LIGHT };
    }
    if (product === "kidneyvault" /* KIDNEYVAULT */ && organPosition === "RIGHT" /* RIGHT */) {
      return { primary: COLOR_KIDNEYVAULT_POSITION_RIGHT_PRIMARY, primaryLight: COLOR_KIDNEYVAULT_POSITION_RIGHT_PRIMARY_LIGHT };
    }
    if (product === "vantage-point" /* VANTAGEPOINT */) {
      return { primary: COLOR_VANTAGEPOINT_PRIMARY, primaryLight: COLOR_VANTAGEPOINT_PRIMARY_LIGHT };
    }
    return { primary: COLOR_SHERPAPAK_PRIMARY, primaryLight: COLOR_SHERPAPAK_PRIMARY_LIGHT };
  }
};

// src/models/organization.ts
var Organization = class {
  id;
  name;
  handle;
  adminIds;
  userIds;
  status;
  organTypes;
  types;
  isInternal;
  placeId;
  address;
  addressComponents;
  location;
  country;
  logoFileName;
  logoUrl;
  domains;
  areDomainsWhitelisted;
  createdAt;
  updatedAt;
  validationStartedAt;
  activatedAt;
  constructor(params) {
    this.id = params.id;
    this.name = params.name;
    this.handle = params.handle;
    this.adminIds = params.adminIds ?? [];
    this.userIds = params.userIds ?? [];
    this.status = params.status;
    this.organTypes = params.organTypes ?? [];
    this.types = params.types ?? [];
    this.isInternal = params.isInternal ?? false;
    this.placeId = params.placeId;
    this.address = params.address;
    this.addressComponents = params.addressComponents;
    this.location = params.location;
    this.country = params.country;
    this.logoFileName = params.logoFileName;
    this.logoUrl = params.logoUrl;
    this.domains = params.domains ?? [];
    this.areDomainsWhitelisted = params.areDomainsWhitelisted ?? false;
    this.createdAt = typeof params.createdAt === "string" ? new Date(params.createdAt) : params.createdAt;
    this.updatedAt = typeof params.updatedAt === "string" ? new Date(params.updatedAt) : params.updatedAt;
    this.validationStartedAt = typeof params.validationStartedAt === "string" ? new Date(params.validationStartedAt) : params.validationStartedAt;
    this.activatedAt = typeof params.activatedAt === "string" ? new Date(params.activatedAt) : params.activatedAt;
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
  toJSON() {
    return {
      id: this.id,
      name: this.name,
      handle: this.handle,
      adminIds: this.adminIds,
      userIds: this.userIds,
      status: this.status,
      organTypes: this.organTypes,
      types: this.types,
      isInternal: this.isInternal,
      placeId: this.placeId,
      address: this.address,
      addressComponents: this.addressComponents,
      location: this.location,
      country: this.country,
      logoFileName: this.logoFileName,
      logoUrl: this.logoUrl,
      domains: this.domains,
      areDomainsWhitelisted: this.areDomainsWhitelisted,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      validationStartedAt: this.validationStartedAt,
      activatedAt: this.activatedAt
    };
  }
};
var OrganizationConverter = {
  toFirestore() {
    return {};
  },
  fromFirestore(doc10) {
    const data = doc10.data();
    return new Organization({
      ...data,
      id: doc10.id,
      createdAt: data.createdAt?.toDate(),
      updatedAt: data.updatedAt?.toDate(),
      validationStartedAt: data.validationStartedAt?.toDate(),
      activatedAt: data.activatedAt?.toDate()
    });
  }
};

// src/models/organization-member.ts
var OrganizationMember = class {
  id;
  email;
  isEmailWhitelisted;
  firstname;
  lastname;
  status;
  accessRole;
  role;
  title;
  startOfEmployment;
  createdAt;
  updatedAt;
  accessRequestedAt;
  invitedAt;
  invitedBy;
  lastInvitedAt;
  lastInvitedBy;
  activatedAt;
  deactivatedAt;
  deactivatedBy;
  deactivationReason;
  deactivationText;
  constructor(params) {
    this.id = params.id;
    this.email = params.email;
    this.isEmailWhitelisted = params.isEmailWhitelisted ?? false;
    this.firstname = params.firstname;
    this.lastname = params.lastname;
    this.status = params.status;
    this.accessRole = params.accessRole;
    this.role = params.role;
    this.title = params.title;
    this.startOfEmployment = typeof params.startOfEmployment === "string" ? new Date(params.startOfEmployment) : params.startOfEmployment;
    this.createdAt = typeof params.createdAt === "string" ? new Date(params.createdAt) : params.createdAt;
    this.updatedAt = typeof params.updatedAt === "string" ? new Date(params.updatedAt) : params.updatedAt;
    this.accessRequestedAt = typeof params.accessRequestedAt === "string" ? new Date(params.accessRequestedAt) : params.accessRequestedAt;
    this.invitedAt = typeof params.invitedAt === "string" ? new Date(params.invitedAt) : params.invitedAt;
    this.invitedBy = params.invitedBy;
    this.lastInvitedAt = typeof params.lastInvitedAt === "string" ? new Date(params.lastInvitedAt) : params.lastInvitedAt;
    this.lastInvitedBy = params.lastInvitedBy;
    this.activatedAt = typeof params.activatedAt === "string" ? new Date(params.activatedAt) : params.activatedAt;
    this.deactivatedAt = typeof params.deactivatedAt === "string" ? new Date(params.deactivatedAt) : params.deactivatedAt;
    this.deactivatedBy = params.deactivatedBy;
    this.deactivationReason = params.deactivationReason;
    this.deactivationText = params.deactivationText;
  }
  get fullname() {
    return `${this.firstname} ${this.lastname}`;
  }
  get initials() {
    return `${this.firstname?.charAt(0) ?? ""}${this.lastname?.charAt(0) ?? ""}`;
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
  toJSON() {
    return {
      id: this.id,
      email: this.email,
      isEmailWhitelisted: this.isEmailWhitelisted,
      firstname: this.firstname,
      lastname: this.lastname,
      status: this.status,
      accessRole: this.accessRole,
      role: this.role,
      title: this.title,
      startOfEmployment: this.startOfEmployment,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      accessRequestedAt: this.accessRequestedAt,
      invitedAt: this.invitedAt,
      invitedBy: this.invitedBy,
      lastInvitedAt: this.lastInvitedAt,
      lastInvitedBy: this.lastInvitedBy,
      activatedAt: this.activatedAt,
      deactivatedAt: this.deactivatedAt,
      deactivatedBy: this.deactivatedBy,
      deactivationReason: this.deactivationReason,
      deactivationText: this.deactivationText
    };
  }
};
var OrganizationMemberConverter = {
  toFirestore() {
    return {};
  },
  fromFirestore(doc10) {
    const data = doc10.data();
    return new OrganizationMember({
      id: doc10.id,
      ...data,
      startOfEmployment: data.startOfEmployment?.toDate(),
      createdAt: data.createdAt?.toDate(),
      updatedAt: data.updatedAt?.toDate(),
      accessRequestedAt: data.accessRequestedAt?.toDate(),
      invitedAt: data.invitedAt?.toDate(),
      lastInvitedAt: data.lastInvitedAt?.toDate(),
      activatedAt: data.activatedAt?.toDate(),
      deactivatedAt: data.deactivatedAt?.toDate()
    });
  }
};

// src/models/organization-preselected-member.ts
var OrganizationPreselectedMember = class extends OrganizationMember {
  isConfirmed;
  confirmedAt;
  constructor(params) {
    super(params);
    this.isConfirmed = params.isConfirmed;
    this.confirmedAt = typeof params.confirmedAt === "string" ? new Date(params.confirmedAt) : params.confirmedAt;
  }
  toJSON() {
    return {
      ...super.toJSON(),
      isConfirmed: this.isConfirmed,
      confirmedAt: this.confirmedAt
    };
  }
  serialize() {
    return {
      ...super.serialize(),
      ...JSON.parse(JSON.stringify(this))
    };
  }
};
var OrganizationPreselectedMemberConverter = {
  toFirestore() {
    return {};
  },
  fromFirestore(doc10) {
    const data = doc10.data();
    return new OrganizationPreselectedMember({
      ...data,
      id: doc10.id,
      createdAt: data.createdAt?.toDate(),
      updatedAt: data.updatedAt?.toDate(),
      confirmedAt: data.confirmedAt?.toDate()
    });
  }
};

// src/models/organization-unregistered-invite.ts
var OrganizationUnregisteredInvite = class {
  id;
  email;
  organizationId;
  invitedBy;
  createdAt;
  updatedAt;
  constructor(params) {
    this.id = params.id;
    this.email = params.email;
    this.organizationId = params.organizationId;
    this.invitedBy = params.invitedBy;
    this.createdAt = typeof params.createdAt === "string" ? new Date(params.createdAt) : params.createdAt;
    this.updatedAt = typeof params.updatedAt === "string" ? new Date(params.updatedAt) : params.updatedAt;
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};
var OrganizationUnregisteredInviteConverter = {
  toFirestore() {
    return {};
  },
  fromFirestore(doc10) {
    const data = doc10.data();
    return new OrganizationUnregisteredInvite({
      ...data,
      id: doc10.id,
      createdAt: data.createdAt?.toDate(),
      updatedAt: data.updatedAt?.toDate()
    });
  }
};

// src/utils/milliseconds-to-time-array.ts
import { Duration } from "luxon";
function millisecondsToTimeArray(milliseconds) {
  const { days, hours, minutes, seconds } = Duration.fromObject({ milliseconds }).shiftTo(
    "days",
    "hours",
    "minutes",
    "seconds",
    "milliseconds"
  );
  return [days > 0 ? days : 0, hours > 0 ? hours : 0, minutes > 0 ? minutes : 0, seconds > 0 ? seconds : 0];
}

// src/utils/format-duration.ts
function formatDuration(milliseconds, options) {
  if (milliseconds == null || Number.isNaN(milliseconds)) {
    return "-";
  }
  if (options?.onlyHours) {
    const totalHours = Math.floor(milliseconds / (1e3 * 60 * 60));
    return `${new Intl.NumberFormat().format(totalHours)}h`;
  }
  const [days, hours, minutes, seconds] = millisecondsToTimeArray(milliseconds);
  return [
    days > 0 && options?.days !== false ? `${new Intl.NumberFormat().format(days)}d` : null,
    hours > 0 && options?.hours !== false ? `${new Intl.NumberFormat().format(hours)}h` : null,
    minutes > 0 && options?.minutes !== false ? `${new Intl.NumberFormat().format(minutes)}m` : null,
    seconds > 0 && options?.seconds !== false ? `${new Intl.NumberFormat().format(seconds)}s` : null
  ].filter((elem) => elem != null).slice(options?.minUnits != null ? options.minUnits : 0, options?.maxUnits != null ? options.maxUnits : options?.maxUnits).join(" ");
}

// src/models/session-ischemic-time.ts
var SessionIschemicTime = class {
  id;
  type;
  organPosition;
  startType;
  startTimestamp;
  endType;
  endTimestamp;
  constructor(params) {
    this.id = params.id;
    this.type = params.type ?? "ISCHEMIC_TIME" /* ISCHEMIC_TIME */;
    this.organPosition = params.organPosition;
    this.startType = params.startType;
    this.endType = params.endType;
    this.startTimestamp = params.startTimestamp instanceof Date ? params.startTimestamp : new Date(params.startTimestamp);
    this.endTimestamp = params.endTimestamp instanceof Date ? params.endTimestamp : params.endTimestamp ? new Date(params.endTimestamp) : void 0;
  }
  get durationFormatted() {
    if (this.startTimestamp == null || this.endTimestamp == null) {
      return "0";
    }
    const milliseconds = this.endTimestamp.getTime() - this.startTimestamp.getTime();
    return formatDuration(milliseconds);
  }
  toJSON() {
    return {
      id: this.id,
      type: this.type,
      organPosition: this.organPosition,
      startType: this.startType,
      startTimestamp: this.startTimestamp,
      endType: this.endType,
      endTimestamp: this.endTimestamp
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};
var SessionIschemicTimeConverter = {
  toFirestore() {
    return {};
  },
  fromFirestore(doc10) {
    const data = doc10.data();
    return new SessionIschemicTime({
      ...data,
      id: doc10.id,
      startTimestamp: data.startTimestamp?.toDate(),
      endTimestamp: data.endTimestamp?.toDate()
    });
  }
};

// src/models/session-metadata.ts
var SessionMetadata = class {
  forceStopAfter48Hours;
  reportSentTo;
  sessionCreatedInfoMailSent;
  sessionStoppedInfoMailSent;
  stopReminderSent;
  constructor(params) {
    this.forceStopAfter48Hours = params?.forceStopAfter48Hours ?? false;
    this.reportSentTo = params?.reportSentTo ?? [];
    this.sessionCreatedInfoMailSent = params?.sessionCreatedInfoMailSent ?? false;
    this.sessionStoppedInfoMailSent = params?.sessionStoppedInfoMailSent ?? false;
    this.stopReminderSent = params?.stopReminderSent ?? false;
  }
  toJSON() {
    return {
      forceStopAfter48Hours: this.forceStopAfter48Hours,
      reportSentTo: this.reportSentTo,
      sessionCreatedInfoMailSent: this.sessionCreatedInfoMailSent,
      sessionStoppedInfoMailSent: this.sessionStoppedInfoMailSent,
      stopReminderSent: this.stopReminderSent
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};

// src/models/session-metrics.ts
var SessionMetrics = class {
  memberCount;
  timelineCount;
  constructor(params) {
    this.memberCount = params.memberCount;
    this.timelineCount = params.timelineCount;
  }
  toJSON() {
    return {
      memberCount: this.memberCount,
      timelineCount: this.timelineCount
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};

// src/models/session.ts
var Session = class {
  id;
  unosId;
  matchRunId;
  parentSessionId;
  shareKey;
  shared;
  sharedAt;
  sharedBy;
  organPosition;
  childSessionIds;
  joinKey;
  finished;
  admin;
  userIds;
  organizationIds;
  device;
  appVersion;
  os;
  osVersion;
  organ;
  product;
  sessionType;
  donationType;
  schemaVersion;
  destination;
  eta;
  etaSetBy;
  hasAdvancedTracking;
  advancedTrackerId;
  advancedTracking;
  hasFlights;
  flights;
  flightPosition;
  lastTimelineEvent;
  loggerData;
  location;
  ischemicTime;
  metadata;
  reportData;
  metrics;
  loggerPasskey;
  loggerSerialNo;
  loggerState;
  loggerConnectedBy;
  pairedDeviceIdentifier;
  createdAt;
  createdBy;
  createdByOrganization;
  endedAt;
  demo;
  demoParentId;
  constructor(params) {
    this.id = params.id;
    this.unosId = params.unosId;
    this.matchRunId = params.matchRunId;
    this.parentSessionId = params.parentSessionId;
    this.shareKey = params.shareKey;
    this.shared = params.shared ?? false;
    this.sharedBy = params.sharedBy;
    this.organPosition = params.organPosition;
    this.childSessionIds = params.childSessionIds;
    this.joinKey = params.joinKey;
    this.finished = params.finished ?? false;
    this.admin = params.admin;
    this.userIds = params.userIds ?? [];
    this.organizationIds = params.organizationIds ?? [];
    this.device = params.device;
    this.appVersion = params.appVersion;
    this.os = params.os;
    this.osVersion = params.osVersion;
    this.organ = params.organ;
    this.product = params.product ?? "sherpapak-cts" /* SHERPAPAK */;
    this.sessionType = params.sessionType;
    this.donationType = params.donationType;
    this.schemaVersion = params.schemaVersion;
    this.destination = params.destination;
    this.etaSetBy = params.etaSetBy;
    this.hasAdvancedTracking = params.hasAdvancedTracking ?? false;
    this.advancedTrackerId = params.advancedTrackerId;
    this.advancedTracking = params.advancedTracking ? new AdvancedTracking(params.advancedTracking) : void 0;
    this.hasFlights = params.hasFlights ?? false;
    this.flights = params.flights?.map((flight) => new Flight(flight)) ?? [];
    this.flightPosition = params.flightPosition ? new FlightPosition(params.flightPosition) : void 0;
    this.lastTimelineEvent = params.lastTimelineEvent;
    this.loggerData = params.loggerData ? new LoggerData(params.loggerData) : void 0;
    this.location = params.location ? new LoggerLocation(params.location) : void 0;
    this.ischemicTime = params.ischemicTime ? new SessionIschemicTime(params.ischemicTime) : void 0;
    this.metadata = params.metadata ? new SessionMetadata(params.metadata) : void 0;
    this.reportData = params.reportData ? new SessionReportData(params.reportData) : void 0;
    this.metrics = params.metrics ? new SessionMetrics(params.metrics) : void 0;
    this.loggerPasskey = params.loggerPasskey;
    this.loggerSerialNo = params.loggerSerialNo;
    this.loggerState = params.loggerState;
    this.loggerConnectedBy = params.loggerConnectedBy;
    this.pairedDeviceIdentifier = params.pairedDeviceIdentifier;
    this.createdBy = params.createdBy;
    this.createdByOrganization = params.createdByOrganization;
    this.demo = params.demo ?? false;
    this.demoParentId = params.demoParentId;
    this.sharedAt = params.sharedAt instanceof Date ? params.sharedAt : params.sharedAt ? new Date(params.sharedAt) : void 0;
    this.endedAt = params.endedAt instanceof Date ? params.endedAt : params.endedAt ? new Date(params.endedAt) : void 0;
    this.createdAt = params.createdAt instanceof Date ? params.createdAt : params.createdAt ? new Date(params.createdAt) : /* @__PURE__ */ new Date();
    this.eta = params.eta instanceof Date ? params.eta : params.eta ? new Date(params.eta) : void 0;
  }
  toOrgan() {
    return new Organ({
      id: this.id,
      sessionId: this.id,
      product: this.product,
      organ: this.organ,
      advancedTrackerId: this.advancedTrackerId,
      reportData: this.reportData,
      logger: this.loggerSerialNo != null ? {
        serialNo: this.loggerSerialNo,
        passkey: this.loggerPasskey,
        state: this.loggerState,
        data: this.loggerData
      } : void 0
    });
  }
  toJSON() {
    return {
      id: this.id,
      unosId: this.unosId,
      matchRunId: this.matchRunId,
      parentSessionId: this.parentSessionId,
      shareKey: this.shareKey,
      shared: this.shared,
      sharedAt: this.sharedAt,
      sharedBy: this.sharedBy,
      organPosition: this.organPosition,
      childSessionIds: this.childSessionIds,
      joinKey: this.joinKey,
      finished: this.finished,
      admin: this.admin,
      userIds: this.userIds,
      organizationIds: this.organizationIds,
      device: this.device,
      appVersion: this.appVersion,
      os: this.os,
      osVersion: this.osVersion,
      organ: this.organ,
      product: this.product,
      sessionType: this.sessionType,
      donationType: this.donationType,
      schemaVersion: this.schemaVersion,
      destination: this.destination,
      eta: this.eta,
      etaSetBy: this.etaSetBy,
      hasAdvancedTracking: this.hasAdvancedTracking,
      advancedTrackerId: this.advancedTrackerId,
      advancedTracking: this.advancedTracking?.toJSON(),
      hasFlights: this.hasFlights,
      flights: this.flights?.map((flight) => flight.toJSON()) ?? [],
      flightPosition: this.flightPosition?.toJSON(),
      lastTimelineEvent: this.lastTimelineEvent,
      loggerData: this.loggerData?.toJSON(),
      location: this.location?.toJSON(),
      ischemicTime: this.ischemicTime?.toJSON(),
      metadata: this.metadata?.toJSON(),
      reportData: this.reportData?.toJSON(),
      metrics: this.metrics?.toJSON(),
      loggerPasskey: this.loggerPasskey,
      loggerSerialNo: this.loggerSerialNo,
      loggerState: this.loggerState,
      loggerConnectedBy: this.loggerConnectedBy,
      pairedDeviceIdentifier: this.pairedDeviceIdentifier,
      createdAt: this.createdAt,
      createdBy: this.createdBy,
      createdByOrganization: this.createdByOrganization,
      endedAt: this.endedAt
    };
  }
  get duration() {
    return this.endedAt ? this.endedAt.getTime() - this.createdAt.getTime() : null;
  }
  get state() {
    if (this.isCreatedState()) {
      return "CREATED" /* CREATED */;
    }
    if (this.isLoggerStartedState()) {
      return "LOGGER_STARTED" /* LOGGER_STARTED */;
    }
    if (this.isLoggerStoppedState()) {
      return "LOGGER_STOPPED" /* LOGGER_STOPPED */;
    }
    if (this.isFinishedState()) {
      return "FINISHED" /* FINISHED */;
    }
    return "UNKNOWN" /* UNKNOWN */;
  }
  isCreatedState() {
    return this.loggerState !== "STOPPED" && !this.finished && this.pairedDeviceIdentifier == null;
  }
  isLoggerStartedState() {
    return this.loggerState === "STARTED" && !this.finished;
  }
  isLoggerStoppedState() {
    return this.loggerState === "STOPPED" && !this.finished;
  }
  isFinishedState() {
    return this.finished;
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};
var SessionConverter = {
  toFirestore() {
    return {};
  },
  fromFirestore(doc10) {
    const data = doc10.data();
    return new Session({
      ...data,
      id: doc10.id,
      eta: data.eta?.toDate != null && typeof data.eta?.toDate === "function" ? data.eta.toDate() : data.eta,
      location: parseLocation(data.location),
      advancedTracking: parseAdvancedTracking(data.advancedTracking),
      flights: parseFlights(data.flights),
      flightPosition: parseFlightPosition(data.flightPosition),
      loggerData: parseLoggerData(data.loggerData, data.temperature),
      ischemicTime: parseSessionIschemicTime(data.ischemicTime),
      reportData: parseSessionReportData2(data.reportData),
      createdAt: data.createdAt?.toDate(),
      endedAt: data.endedAt?.toDate()
    });
  }
};
function parseLocation(data) {
  return data ? { ...data, timestamp: data.timestamp?.toDate() } : void 0;
}
function parseAdvancedTracking(data) {
  return data ? { ...data, timestamp: data.timestamp?.toDate() } : void 0;
}
function parseFlights(data) {
  return data?.length > 0 ? data.map((flight) => ({
    ...flight,
    dataTimestamp: flight.dataTimestamp?.toDate(),
    actualArrival: flight.actualArrival?.toDate(),
    actualDeparture: flight.actualDeparture?.toDate(),
    estimatedDeparture: flight.estimatedDeparture?.toDate(),
    estimatedArrival: flight.estimatedArrival?.toDate(),
    plannedArrival: flight.plannedArrival?.toDate(),
    plannedDeparture: flight.plannedDeparture?.toDate()
  })).sort((a, b) => {
    return (a.actualDeparture?.getTime() ?? a.estimatedDeparture?.getTime() ?? a.plannedDeparture?.getTime() ?? 0) - (b.actualDeparture?.getTime() ?? b.estimatedDeparture?.getTime() ?? b.plannedDeparture?.getTime() ?? 0);
  }) : void 0;
}
function parseFlightPosition(data) {
  return data ? {
    ...data,
    timestamp: data.timestamp?.toDate()
  } : void 0;
}
function parseLoggerData(loggerData, temperature) {
  if (loggerData) {
    return {
      ...loggerData,
      timestamp: loggerData.timestamp?.toDate()
    };
  }
  if (temperature) {
    return {
      ...temperature,
      timestamp: temperature.timestamp?.toDate()
    };
  }
  return void 0;
}
function parseSessionIschemicTime(data) {
  return data ? {
    ...data,
    startTimestamp: data.startTimestamp?.toDate(),
    endTimestamp: data.endTimestamp?.toDate()
  } : void 0;
}
function parseSessionReportData2(data) {
  return data ? {
    ...data,
    loggerStartedAt: data.loggerStartedAt?.toDate(),
    loggerStoppedAt: data.loggerStoppedAt?.toDate(),
    firstReading: data.firstReading?.toDate(),
    lastReading: data.lastReading?.toDate(),
    timeOnPump: data.timeOnPump ? {
      ...data.timeOnPump,
      startTimestamp: data.timeOnPump.startTimestamp?.toDate(),
      endTimestamp: data.timeOnPump.endTimestamp?.toDate()
    } : void 0
  } : void 0;
}

// src/models/session-eta.ts
var SessionETA = class {
  id;
  destinationType;
  timestamp;
  userId;
  deleted;
  constructor(params) {
    this.id = params.id;
    this.destinationType = params.destinationType;
    this.userId = params.userId;
    this.deleted = params.deleted ?? false;
    this.timestamp = params.timestamp instanceof Date ? params.timestamp : new Date(params.timestamp);
  }
  toJSON() {
    return {
      id: this.id,
      destinationType: this.destinationType,
      timestamp: this.timestamp,
      userId: this.userId,
      deleted: this.deleted
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};
var SessionETAConverter = {
  toFirestore() {
    return {};
  },
  fromFirestore(doc10) {
    const data = doc10.data();
    return new SessionETA({
      ...data,
      id: doc10.id,
      timestamp: data.timestamp?.toDate()
    });
  }
};

// src/models/session-member.ts
var SessionMember = class {
  id;
  email;
  firstname;
  lastname;
  organization;
  organizationId;
  organizationHandle;
  organizationAccessRole;
  joinedAt;
  muted;
  constructor(params) {
    this.id = params.id;
    this.email = params.email;
    this.firstname = params.firstname;
    this.lastname = params.lastname;
    this.organization = params.organization;
    this.organizationId = params.organizationId;
    this.organizationHandle = params.organizationHandle;
    this.organizationAccessRole = params.organizationAccessRole;
    this.joinedAt = typeof params.joinedAt === "string" ? new Date(params.joinedAt) : params.joinedAt;
    this.muted = params.muted ?? false;
  }
  toJSON() {
    return {
      id: this.id,
      email: this.email,
      firstname: this.firstname,
      lastname: this.lastname,
      organization: this.organization,
      organizationId: this.organizationId,
      organizationHandle: this.organizationHandle,
      organizationAccessRole: this.organizationAccessRole,
      joinedAt: this.joinedAt,
      muted: this.muted
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
  get fullname() {
    return `${this.firstname} ${this.lastname}`;
  }
  get initials() {
    return `${this.firstname?.charAt(0) ?? ""}${this.lastname?.charAt(0) ?? ""}`;
  }
};
var SessionMemberConverter = {
  toFirestore() {
    return {};
  },
  fromFirestore(doc10) {
    const data = doc10.data();
    return new SessionMember({ ...data, id: doc10.id, joinedAt: data.joinedAt?.toDate() });
  }
};

// src/models/shared-session.ts
var SharedSession = class {
  id;
  shared;
  shareKey;
  sharedAt;
  sharedBy;
  organPosition;
  childSessionIds;
  finished;
  device;
  appVersion;
  os;
  osVersion;
  organ;
  product;
  sessionType;
  donationType;
  schemaVersion;
  unosId;
  readByUserIds;
  createdAt;
  endedAt;
  constructor(params) {
    this.id = params.id;
    this.shared = params.shared ?? true;
    this.shareKey = params.shareKey;
    this.sharedAt = typeof params.sharedAt === "string" ? new Date(params.sharedAt) : params.sharedAt;
    this.sharedBy = params.sharedBy;
    this.organPosition = params.organPosition;
    this.childSessionIds = params.childSessionIds;
    this.finished = params.finished ?? false;
    this.device = params.device;
    this.appVersion = params.appVersion;
    this.os = params.os;
    this.osVersion = params.osVersion;
    this.organ = params.organ;
    this.product = params.product;
    this.sessionType = params.sessionType;
    this.donationType = params.donationType;
    this.schemaVersion = params.schemaVersion;
    this.unosId = params.unosId;
    this.readByUserIds = params.readByUserIds ?? [];
    this.createdAt = typeof params.createdAt === "string" ? new Date(params.createdAt) : params.createdAt;
    this.endedAt = typeof params.endedAt === "string" ? new Date(params.endedAt) : params.endedAt;
  }
  toJSON() {
    return {
      id: this.id,
      shared: this.shared,
      shareKey: this.shareKey,
      sharedAt: this.sharedAt,
      sharedBy: this.sharedBy,
      organPosition: this.organPosition,
      childSessionIds: this.childSessionIds,
      finished: this.finished,
      device: this.device,
      appVersion: this.appVersion,
      os: this.os,
      osVersion: this.osVersion,
      organ: this.organ,
      product: this.product,
      sessionType: this.sessionType,
      donationType: this.donationType,
      schemaVersion: this.schemaVersion,
      unosId: this.unosId,
      readByUserIds: this.readByUserIds,
      createdAt: this.createdAt,
      endedAt: this.endedAt
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};
var SharedSessionConverter = {
  toFirestore() {
    return {};
  },
  fromFirestore(doc10) {
    const data = doc10.data();
    return new SharedSession({
      ...data,
      id: doc10.id,
      sharedAt: data.sharedAt?.toDate(),
      createdAt: data.createdAt?.toDate(),
      endedAt: data.endedAt?.toDate()
    });
  }
};

// src/models/timeline-entry.ts
var TimelineEntry = class {
  id;
  referenceId;
  type;
  annotations;
  senderId;
  skipPushNotification;
  timestamp;
  createdAt;
  deleted;
  deletedAt;
  constructor(params) {
    this.id = params.id;
    this.referenceId = params.referenceId;
    this.type = params.type;
    this.annotations = params.annotations ?? {};
    this.senderId = params.senderId;
    this.skipPushNotification = params.skipPushNotification ?? false;
    this.timestamp = typeof params.timestamp === "string" ? new Date(params.timestamp) : params.timestamp;
    this.createdAt = typeof params.createdAt === "string" ? new Date(params.createdAt) : params.createdAt;
    this.deleted = params.deleted ?? false;
    this.deletedAt = typeof params.deletedAt === "string" ? new Date(params.deletedAt) : params.deletedAt;
  }
  toJSON() {
    return {
      id: this.id,
      referenceId: this.referenceId,
      type: this.type,
      annotations: this.annotations,
      senderId: this.senderId,
      skipPushNotification: this.skipPushNotification,
      timestamp: this.timestamp,
      createdAt: this.createdAt,
      deleted: this.deleted,
      deletedAt: this.deletedAt
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
};
var TimelineEntryConverter = {
  toFirestore() {
    return {};
  },
  fromFirestore(doc10) {
    const data = doc10.data();
    return new TimelineEntry({
      ...data,
      id: doc10.id,
      timestamp: data.timestamp?.toDate(),
      createdAt: data.createdAt?.toDate(),
      deletedAt: data.deletedAt?.toDate()
    });
  }
};

// src/models/timeline-entry-type.ts
var TimelineEntryTypeUtil = class {
  static toUIString(type, productType, annotations = {}) {
    const product = ProductTypeUtil.toUIString(productType);
    const lungPositionTypeAnnotation = productType === "baroguard" /* BAROGUARD */ && annotations?.position != null ? ` - ${LungPositionTypeUtil.toUIString(annotations.position)}` : "";
    const cannulationTypeAnnotation = annotations?.cannulation != null ? ` - ${CannulationTypeUtil.toUIString(annotations.cannulation)}` : "";
    switch (type) {
      case "START_SESSION" /* START_SESSION */:
        return "Case Started";
      case "STOP_SESSION" /* STOP_SESSION */:
        return "Case Stopped";
      case "LEAVE_TX_CENTER" /* LEAVE_TX_CENTER */:
        return "Departed Recipient Center";
      case "ARRIVAL_AT_DONOR" /* ARRIVAL_AT_DONOR */:
        return "Arrived at Donor Center";
      case "DONOR_IN_ROOM" /* DONOR_IN_ROOM */:
        return "Donor In-room";
      case "HEAD_VESSEL_CLAMP" /* HEAD_VESSEL_CLAMP */:
        return "Head Vessel Clamp/Ligate";
      case "TIME_TO_INCISION" /* TIME_TO_INCISION */:
        return "Time of Incision";
      case "ORGAN_ACCEPTED" /* ORGAN_ACCEPTED */:
        return "Organ Accepted";
      case "ORGAN_DECLINED" /* ORGAN_DECLINED */:
        return "Organ Declined";
      case "TIME_OF_WITHDRAWAL" /* TIME_OF_WITHDRAWAL */:
        return "Time of Withdrawal";
      case "WARM_ISCHEMIC_TIME_START" /* WARM_ISCHEMIC_TIME_START */:
        return "Warm Ischemic Time Start";
      case "OBSERVATION_PERIOD_START" /* OBSERVATION_PERIOD_START */:
        return "Observation Period Start";
      case "TIME_OF_DEATH" /* TIME_OF_DEATH */:
        return "Time of Death";
      case "HEPARIN_ADMINISTERED" /* HEPARIN_ADMINISTERED */:
        return "Heparin Administered";
      case "CROSSCLAMP_AT_DONOR" /* CROSSCLAMP_AT_DONOR */:
        return "Donor Crossclamp";
      case "ORGAN_REMOVAL" /* ORGAN_REMOVAL */:
        return "Heart Removal";
      case "ORGAN_REMOVAL_LUNG" /* ORGAN_REMOVAL_LUNG */:
        return "Lung Removal";
      case "ORGAN_REMOVAL_LIVER" /* ORGAN_REMOVAL_LIVER */:
        return "Liver Removal";
      case "HEART_PLACED_IN_SHERPAPAK" /* HEART_PLACED_IN_SHERPAPAK */:
        return "Heart Placed into SherpaPak\xAE";
      case "LUNG_PLACED_IN_LUNGGUARD" /* LUNG_PLACED_IN_LUNGGUARD */:
        return `Lung(s) Placed into ${product}${lungPositionTypeAnnotation}`;
      case "LIVER_PLACED_IN_LIVERGUARD" /* LIVER_PLACED_IN_LIVERGUARD */:
        return "Liver Placed into LIVERguard\xAE";
      case "LEAVE_DONOR" /* LEAVE_DONOR */:
        return "Departed Donor Center";
      case "ARRIVE_AT_RECIPIENT" /* ARRIVE_AT_RECIPIENT */:
        return "Arrived at Recipient Center";
      case "HEART_REMOVED_FROM_SHERPAPAK" /* HEART_REMOVED_FROM_SHERPAPAK */:
        return "Heart Removed from SherpaPak\xAE";
      case "LIVER_REMOVED_FROM_LIVERGUARD" /* LIVER_REMOVED_FROM_LIVERGUARD */:
        return "Liver Removed from LIVERguard\xAE";
      case "LUNGS_REMOVED_FROM_LUNGGUARD" /* LUNGS_REMOVED_FROM_LUNGGUARD */:
        return `Lungs Removed from ${product}`;
      case "LEFT_LUNG_REMOVED_FROM_LUNGGUARD" /* LEFT_LUNG_REMOVED_FROM_LUNGGUARD */:
        return `Left Lung Removed from ${product}`;
      case "RIGHT_LUNG_REMOVED_FROM_LUNGGUARD" /* RIGHT_LUNG_REMOVED_FROM_LUNGGUARD */:
        return `Right Lung Removed from ${product}`;
      case "ANASTOMOSIS" /* ANASTOMOSIS */:
        return "Anastomosis";
      case "LEFT_LUNG_ANASTOMOSIS_COMPLETE" /* LEFT_LUNG_ANASTOMOSIS_COMPLETE */:
        return "Left Lung Anastomosis Complete";
      case "RIGHT_LUNG_ANASTOMOSIS_COMPLETE" /* RIGHT_LUNG_ANASTOMOSIS_COMPLETE */:
        return "Right Lung Anastomosis Complete";
      case "CROSSCLAMP_AT_RECIPIENT" /* CROSSCLAMP_AT_RECIPIENT */:
        return "Recipient Off Clamp";
      case "RECIPIENT_LEFT_LUNG_OFF_CLAMP" /* RECIPIENT_LEFT_LUNG_OFF_CLAMP */:
        return "Recipient Left Lung Off Clamp";
      case "RECIPIENT_RIGHT_LUNG_OFF_CLAMP" /* RECIPIENT_RIGHT_LUNG_OFF_CLAMP */:
        return "Recipient Right Lung Off Clamp";
      case "PORTAL_REPERFUSION" /* PORTAL_REPERFUSION */:
        return "Portal Reperfusion";
      case "ARTERIAL_REPERFUSION" /* ARTERIAL_REPERFUSION */:
        return "Arterial Reperfusion";
      case "PERFUSION_START" /* PERFUSION_START */:
        return "Start Aortic Flush";
      case "PERFUSION_STOP" /* PERFUSION_STOP */:
        return "End Aortic Flush";
      case "ON_CARDIO_PULMONARY_BYPASS_1" /* ON_CARDIO_PULMONARY_BYPASS_1 */:
        return "On Cardio Pulmonary Bypass 1";
      case "START_WEAN_1" /* START_WEAN_1 */:
        return "Start Wean 1";
      case "OFF_CARDIO_PULMONARY_BYPASS_1" /* OFF_CARDIO_PULMONARY_BYPASS_1 */:
        return "Off Cardio Pulmonary Bypass 1";
      case "ON_CARDIO_PULMONARY_BYPASS_2" /* ON_CARDIO_PULMONARY_BYPASS_2 */:
        return "On Cardio Pulmonary Bypass 2";
      case "START_WEAN_2" /* START_WEAN_2 */:
        return "Start Wean 2";
      case "OFF_CARDIO_PULMONARY_BYPASS_2" /* OFF_CARDIO_PULMONARY_BYPASS_2 */:
        return "Off Cardio Pulmonary Bypass 2";
      case "LEAVE_OPO" /* LEAVE_OPO */:
        return "Departed OPO";
      case "ARRIVE_AT_OPO" /* ARRIVE_AT_OPO */:
        return "Arrived at OPO";
      case "KIDNEY_DONOR_FINAL_BLOOD_UREA_NITROGEN" /* KIDNEY_DONOR_FINAL_BLOOD_UREA_NITROGEN */:
        return "Donor Final Blood Urea Nitrogen";
      case "KIDNEY_DONOR_FINAL_SERUM_CREATININE" /* KIDNEY_DONOR_FINAL_SERUM_CREATININE */:
        return "Donor Final Serum Creatinine";
      case "KIDNEY_DONOR_KIDNEY_DONOR_PROFILE_INDEX" /* KIDNEY_DONOR_KIDNEY_DONOR_PROFILE_INDEX */:
        return "Donor Kidney Donor Profile Index";
      case "KIDNEY_WITHDRAWAL_OF_SUPPORT" /* KIDNEY_WITHDRAWAL_OF_SUPPORT */:
        return "Withdrawal of Support";
      case "KIDNEY_START_AGONAL_PHASE" /* KIDNEY_START_AGONAL_PHASE */:
        return "Start of Agonal Phase";
      case "KIDNEY_START_FLUSH" /* KIDNEY_START_FLUSH */:
        return "Start Flush";
      case "KIDNEY_X_CLAMP" /* KIDNEY_X_CLAMP */:
        return "X Clamp";
      case "LEFT_KIDNEY_REMOVAL" /* LEFT_KIDNEY_REMOVAL */:
        return "Left Kidney Removal";
      case "RIGHT_KIDNEY_REMOVAL" /* RIGHT_KIDNEY_REMOVAL */:
        return "Right Kidney Removal";
      case "LEFT_KIDNEY_PLACED_ON_ICE" /* LEFT_KIDNEY_PLACED_ON_ICE */:
        return "Left Kidney Placed on Ice";
      case "RIGHT_KIDNEY_PLACED_ON_ICE" /* RIGHT_KIDNEY_PLACED_ON_ICE */:
        return "Right Kidney Placed on Ice";
      case "LEFT_KIDNEY_PLACED_INTO_STORAGE" /* LEFT_KIDNEY_PLACED_INTO_STORAGE */:
        return `Left Kidney Placed into ${product}`;
      case "RIGHT_KIDNEY_PLACED_INTO_STORAGE" /* RIGHT_KIDNEY_PLACED_INTO_STORAGE */:
        return `Right Kidney Placed into ${product}`;
      case "LEFT_KIDNEY_PLACED_IN_THIRD_PARTY_STORAGE" /* LEFT_KIDNEY_PLACED_IN_THIRD_PARTY_STORAGE */:
        return "Left Kidney Placed into Third-Party Storage";
      case "RIGHT_KIDNEY_PLACED_IN_THIRD_PARTY_STORAGE" /* RIGHT_KIDNEY_PLACED_IN_THIRD_PARTY_STORAGE */:
        return "Right Kidney Placed into Third-Party Storage";
      case "LEFT_KIDNEY_HANDED_OFF_TO_COURIER" /* LEFT_KIDNEY_HANDED_OFF_TO_COURIER */:
        return "Left Kidney Handed off to Courier/Air";
      case "RIGHT_KIDNEY_HANDED_OFF_TO_COURIER" /* RIGHT_KIDNEY_HANDED_OFF_TO_COURIER */:
        return "Right Kidney Handed off to Courier/Air";
      case "LEFT_KIDNEY_ARRIVED_AT_TXC_OR_OPO" /* LEFT_KIDNEY_ARRIVED_AT_TXC_OR_OPO */:
        return "Left Kidney Arrived at TXC or OPO";
      case "RIGHT_KIDNEY_ARRIVED_AT_TXC_OR_OPO" /* RIGHT_KIDNEY_ARRIVED_AT_TXC_OR_OPO */:
        return "Right Kidney Arrived at TXC or OPO";
      case "LEFT_KIDNEY_OUT_OF_STORAGE" /* LEFT_KIDNEY_OUT_OF_STORAGE */:
        return `Left Kidney Out of ${product}`;
      case "RIGHT_KIDNEY_OUT_OF_STORAGE" /* RIGHT_KIDNEY_OUT_OF_STORAGE */:
        return `Right Kidney Out of ${product}`;
      case "LEFT_KIDNEY_REMOVED_FROM_ICE" /* LEFT_KIDNEY_REMOVED_FROM_ICE */:
        return "Left Kidney Removed from Ice";
      case "RIGHT_KIDNEY_REMOVED_FROM_ICE" /* RIGHT_KIDNEY_REMOVED_FROM_ICE */:
        return "Right Kidney Removed from Ice";
      case "LEFT_KIDNEY_REMOVED_FROM_THIRD_PARTY_STORAGE" /* LEFT_KIDNEY_REMOVED_FROM_THIRD_PARTY_STORAGE */:
        return "Left Kidney Removed from Third-Party Storage";
      case "RIGHT_KIDNEY_REMOVED_FROM_THIRD_PARTY_STORAGE" /* RIGHT_KIDNEY_REMOVED_FROM_THIRD_PARTY_STORAGE */:
        return "Right Kidney Removed from Third-Party Storage";
      case "LEFT_KIDNEY_ARTERY_ANASTOMOSIS" /* LEFT_KIDNEY_ARTERY_ANASTOMOSIS */:
        return "Left Kidney Artery Anastomosis";
      case "RIGHT_KIDNEY_ARTERY_ANASTOMOSIS" /* RIGHT_KIDNEY_ARTERY_ANASTOMOSIS */:
        return "Right Kidney Artery Anastomosis";
      case "LEFT_KIDNEY_VEIN_ANASTOMOSIS" /* LEFT_KIDNEY_VEIN_ANASTOMOSIS */:
        return "Left Kidney Vein Anastomosis";
      case "RIGHT_KIDNEY_VEIN_ANASTOMOSIS" /* RIGHT_KIDNEY_VEIN_ANASTOMOSIS */:
        return "Right Kidney Vein Anastomosis";
      case "LEFT_KIDNEY_URETER_ANASTOMOSIS" /* LEFT_KIDNEY_URETER_ANASTOMOSIS */:
        return "Left Kidney Ureter Anastomosis";
      case "RIGHT_KIDNEY_URETER_ANASTOMOSIS" /* RIGHT_KIDNEY_URETER_ANASTOMOSIS */:
        return "Right Kidney Ureter Anastomosis";
      case "LEFT_KIDNEY_ACCEPTED" /* LEFT_KIDNEY_ACCEPTED */:
        return "Left Kidney Accepted for Recovery";
      case "RIGHT_KIDNEY_ACCEPTED" /* RIGHT_KIDNEY_ACCEPTED */:
        return "Right Kidney Accepted for Recovery";
      case "LEFT_KIDNEY_ALLOCATION_COMPLETE" /* LEFT_KIDNEY_ALLOCATION_COMPLETE */:
        return "Left Kidney Allocation Complete";
      case "RIGHT_KIDNEY_ALLOCATION_COMPLETE" /* RIGHT_KIDNEY_ALLOCATION_COMPLETE */:
        return "Right Kidney Allocation Complete";
      case "LEFT_KIDNEY_NOT_RECOVERED" /* LEFT_KIDNEY_NOT_RECOVERED */:
        return "Left Kidney Not Recovered";
      case "RIGHT_KIDNEY_NOT_RECOVERED" /* RIGHT_KIDNEY_NOT_RECOVERED */:
        return "Right Kidney Not Recovered";
      case "ORGAN_REMOVAL_PANCREAS" /* ORGAN_REMOVAL_PANCREAS */:
        return "Pancreas Removal";
      case "PANCREAS_PLACED_IN_PANCREASPAK" /* PANCREAS_PLACED_IN_PANCREASPAK */:
        return "Pancreas Placed into PancreasPak\u2122";
      case "PANCREAS_REMOVED_FROM_PANCREASPAK" /* PANCREAS_REMOVED_FROM_PANCREASPAK */:
        return "Pancreas Removed from PancreasPak\u2122";
      case "CANNULATION" /* CANNULATION */:
        return `Cannulation${cannulationTypeAnnotation}`;
      case "COLD_PRESERVATION_FLUSH" /* COLD_PRESERVATION_FLUSH */:
        return "Cold Preservation Flush";
      case "UNKNOWN" /* UNKNOWN */:
        return "Unknown Event";
      case "START_LOGGER" /* START_LOGGER */:
        return "Logger Started";
      case "STOP_LOGGER" /* STOP_LOGGER */:
        return "Logger Stopped";
      case "PLANE_DEPARTURE_RECIPIENT" /* PLANE_DEPARTURE_RECIPIENT */:
        return "Take-off at Recipient Airport";
      case "PLANE_ARRIVAL_DONOR" /* PLANE_ARRIVAL_DONOR */:
        return "Landed at Donor Airport";
      case "PLANE_DEPARTURE_DONOR" /* PLANE_DEPARTURE_DONOR */:
        return "Take-off at Donor Airport";
      case "PLANE_ARRIVAL_RECIPIENT" /* PLANE_ARRIVAL_RECIPIENT */:
        return "Landed at Recipient Airport";
      case "LEFT_KIDNEY_DECLINED" /* LEFT_KIDNEY_DECLINED */:
        return "Left Kidney Declined";
      case "RIGHT_KIDNEY_DECLINED" /* RIGHT_KIDNEY_DECLINED */:
        return "Right Kidney Declined";
      default:
        return "Unknown Event";
    }
  }
  static getOrganPosition(type) {
    const byPositions = {
      ["LEFT" /* LEFT */]: [
        "LEFT_LUNG_REMOVED_FROM_LUNGGUARD" /* LEFT_LUNG_REMOVED_FROM_LUNGGUARD */,
        "LEFT_LUNG_ANASTOMOSIS_COMPLETE" /* LEFT_LUNG_ANASTOMOSIS_COMPLETE */,
        "RECIPIENT_LEFT_LUNG_OFF_CLAMP" /* RECIPIENT_LEFT_LUNG_OFF_CLAMP */,
        "LEFT_KIDNEY_REMOVAL" /* LEFT_KIDNEY_REMOVAL */,
        "LEFT_KIDNEY_PLACED_ON_ICE" /* LEFT_KIDNEY_PLACED_ON_ICE */,
        "LEFT_KIDNEY_PLACED_INTO_STORAGE" /* LEFT_KIDNEY_PLACED_INTO_STORAGE */,
        "LEFT_KIDNEY_PLACED_IN_THIRD_PARTY_STORAGE" /* LEFT_KIDNEY_PLACED_IN_THIRD_PARTY_STORAGE */,
        "LEFT_KIDNEY_HANDED_OFF_TO_COURIER" /* LEFT_KIDNEY_HANDED_OFF_TO_COURIER */,
        "LEFT_KIDNEY_ARRIVED_AT_TXC_OR_OPO" /* LEFT_KIDNEY_ARRIVED_AT_TXC_OR_OPO */,
        "LEFT_KIDNEY_OUT_OF_STORAGE" /* LEFT_KIDNEY_OUT_OF_STORAGE */,
        "LEFT_KIDNEY_REMOVED_FROM_ICE" /* LEFT_KIDNEY_REMOVED_FROM_ICE */,
        "LEFT_KIDNEY_REMOVED_FROM_THIRD_PARTY_STORAGE" /* LEFT_KIDNEY_REMOVED_FROM_THIRD_PARTY_STORAGE */,
        "LEFT_KIDNEY_ARTERY_ANASTOMOSIS" /* LEFT_KIDNEY_ARTERY_ANASTOMOSIS */,
        "LEFT_KIDNEY_VEIN_ANASTOMOSIS" /* LEFT_KIDNEY_VEIN_ANASTOMOSIS */,
        "LEFT_KIDNEY_URETER_ANASTOMOSIS" /* LEFT_KIDNEY_URETER_ANASTOMOSIS */,
        "LEFT_KIDNEY_ACCEPTED" /* LEFT_KIDNEY_ACCEPTED */,
        "LEFT_KIDNEY_ALLOCATION_COMPLETE" /* LEFT_KIDNEY_ALLOCATION_COMPLETE */,
        "LEFT_KIDNEY_NOT_RECOVERED" /* LEFT_KIDNEY_NOT_RECOVERED */,
        "LEFT_KIDNEY_DECLINED" /* LEFT_KIDNEY_DECLINED */
      ],
      ["RIGHT" /* RIGHT */]: [
        "RIGHT_LUNG_REMOVED_FROM_LUNGGUARD" /* RIGHT_LUNG_REMOVED_FROM_LUNGGUARD */,
        "RIGHT_LUNG_ANASTOMOSIS_COMPLETE" /* RIGHT_LUNG_ANASTOMOSIS_COMPLETE */,
        "RECIPIENT_RIGHT_LUNG_OFF_CLAMP" /* RECIPIENT_RIGHT_LUNG_OFF_CLAMP */,
        "RIGHT_KIDNEY_REMOVAL" /* RIGHT_KIDNEY_REMOVAL */,
        "RIGHT_KIDNEY_PLACED_ON_ICE" /* RIGHT_KIDNEY_PLACED_ON_ICE */,
        "RIGHT_KIDNEY_PLACED_INTO_STORAGE" /* RIGHT_KIDNEY_PLACED_INTO_STORAGE */,
        "RIGHT_KIDNEY_PLACED_IN_THIRD_PARTY_STORAGE" /* RIGHT_KIDNEY_PLACED_IN_THIRD_PARTY_STORAGE */,
        "RIGHT_KIDNEY_HANDED_OFF_TO_COURIER" /* RIGHT_KIDNEY_HANDED_OFF_TO_COURIER */,
        "RIGHT_KIDNEY_ARRIVED_AT_TXC_OR_OPO" /* RIGHT_KIDNEY_ARRIVED_AT_TXC_OR_OPO */,
        "RIGHT_KIDNEY_OUT_OF_STORAGE" /* RIGHT_KIDNEY_OUT_OF_STORAGE */,
        "RIGHT_KIDNEY_REMOVED_FROM_ICE" /* RIGHT_KIDNEY_REMOVED_FROM_ICE */,
        "RIGHT_KIDNEY_REMOVED_FROM_THIRD_PARTY_STORAGE" /* RIGHT_KIDNEY_REMOVED_FROM_THIRD_PARTY_STORAGE */,
        "RIGHT_KIDNEY_ARTERY_ANASTOMOSIS" /* RIGHT_KIDNEY_ARTERY_ANASTOMOSIS */,
        "RIGHT_KIDNEY_VEIN_ANASTOMOSIS" /* RIGHT_KIDNEY_VEIN_ANASTOMOSIS */,
        "RIGHT_KIDNEY_URETER_ANASTOMOSIS" /* RIGHT_KIDNEY_URETER_ANASTOMOSIS */,
        "RIGHT_KIDNEY_ACCEPTED" /* RIGHT_KIDNEY_ACCEPTED */,
        "RIGHT_KIDNEY_ALLOCATION_COMPLETE" /* RIGHT_KIDNEY_ALLOCATION_COMPLETE */,
        "RIGHT_KIDNEY_NOT_RECOVERED" /* RIGHT_KIDNEY_NOT_RECOVERED */,
        "RIGHT_KIDNEY_DECLINED" /* RIGHT_KIDNEY_DECLINED */
      ]
    };
    if (byPositions["LEFT" /* LEFT */].includes(type)) {
      return "LEFT" /* LEFT */;
    }
    if (byPositions["RIGHT" /* RIGHT */].includes(type)) {
      return "RIGHT" /* RIGHT */;
    }
    return null;
  }
  static attributes(type) {
    switch (type) {
      case "HEART_PLACED_IN_SHERPAPAK" /* HEART_PLACED_IN_SHERPAPAK */:
        return ["STARTS_LOGGER" /* STARTS_LOGGER */];
      case "LUNG_PLACED_IN_LUNGGUARD" /* LUNG_PLACED_IN_LUNGGUARD */:
        return ["STARTS_LOGGER" /* STARTS_LOGGER */];
      case "LIVER_PLACED_IN_LIVERGUARD" /* LIVER_PLACED_IN_LIVERGUARD */:
        return ["STARTS_LOGGER" /* STARTS_LOGGER */];
      case "PANCREAS_PLACED_IN_PANCREASPAK" /* PANCREAS_PLACED_IN_PANCREASPAK */:
        return ["STARTS_LOGGER" /* STARTS_LOGGER */];
      case "LEFT_KIDNEY_PLACED_INTO_STORAGE" /* LEFT_KIDNEY_PLACED_INTO_STORAGE */:
        return ["STARTS_LOGGER" /* STARTS_LOGGER */];
      case "RIGHT_KIDNEY_PLACED_INTO_STORAGE" /* RIGHT_KIDNEY_PLACED_INTO_STORAGE */:
        return ["STARTS_LOGGER" /* STARTS_LOGGER */];
      case "HEART_REMOVED_FROM_SHERPAPAK" /* HEART_REMOVED_FROM_SHERPAPAK */:
        return ["STOPS_LOGGER" /* STOPS_LOGGER */];
      case "LUNGS_REMOVED_FROM_LUNGGUARD" /* LUNGS_REMOVED_FROM_LUNGGUARD */:
        return ["STOPS_LOGGER" /* STOPS_LOGGER */];
      case "LIVER_REMOVED_FROM_LIVERGUARD" /* LIVER_REMOVED_FROM_LIVERGUARD */:
        return ["STOPS_LOGGER" /* STOPS_LOGGER */];
      case "PANCREAS_REMOVED_FROM_PANCREASPAK" /* PANCREAS_REMOVED_FROM_PANCREASPAK */:
        return ["STOPS_LOGGER" /* STOPS_LOGGER */];
      case "LEFT_LUNG_REMOVED_FROM_LUNGGUARD" /* LEFT_LUNG_REMOVED_FROM_LUNGGUARD */:
        return ["STOPS_LOGGER" /* STOPS_LOGGER */];
      case "LEFT_KIDNEY_OUT_OF_STORAGE" /* LEFT_KIDNEY_OUT_OF_STORAGE */:
        return ["STOPS_LOGGER" /* STOPS_LOGGER */];
      case "RIGHT_LUNG_REMOVED_FROM_LUNGGUARD" /* RIGHT_LUNG_REMOVED_FROM_LUNGGUARD */:
        return ["STOPS_LOGGER" /* STOPS_LOGGER */];
      case "RIGHT_KIDNEY_OUT_OF_STORAGE" /* RIGHT_KIDNEY_OUT_OF_STORAGE */:
        return ["STOPS_LOGGER" /* STOPS_LOGGER */];
      default:
        return [];
    }
  }
};

// src/models/user-settings.ts
var UserSettings = class {
  includeTimezone;
  force24hours;
  marketingMails;
  sessionReportMails;
  forceDistanceUnit;
  constructor(params) {
    this.includeTimezone = params?.includeTimezone ?? false;
    this.force24hours = params?.force24hours ?? false;
    this.marketingMails = params?.marketingMails ?? false;
    this.sessionReportMails = params?.sessionReportMails ?? false;
    this.forceDistanceUnit = params?.forceDistanceUnit ?? "imperial" /* IMPERIAL */;
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
  toJSON() {
    return {
      includeTimezone: this.includeTimezone,
      force24hours: this.force24hours,
      marketingMails: this.marketingMails,
      sessionReportMails: this.sessionReportMails,
      forceDistanceUnit: this.forceDistanceUnit
    };
  }
};

// src/models/user.ts
var User = class {
  id;
  email;
  emailVerified;
  acceptedTermsVersion;
  firstname;
  lastname;
  avatarFileName;
  avatarUrl;
  organization;
  role;
  permissions;
  settings;
  timezone;
  timezoneName;
  organizationIds;
  activeInOrganizationIds;
  readAccessOrganizationIds;
  needsOrganizationProfile;
  needsEmailVerification;
  createdAt;
  deactivatedAt;
  deactivationReason;
  constructor(params) {
    this.id = params.id;
    this.email = params.email;
    this.emailVerified = params.emailVerified ?? false;
    this.firstname = params.firstname;
    this.lastname = params.lastname;
    this.avatarFileName = params.avatarFileName;
    this.avatarUrl = params.avatarUrl;
    this.organization = params.organization;
    this.role = params.role;
    this.permissions = params.permissions ?? [];
    this.acceptedTermsVersion = params.acceptedTermsVersion;
    this.settings = new UserSettings(params.settings);
    this.timezone = params.timezone;
    this.timezoneName = params.timezoneName;
    this.organizationIds = params.organizationIds ?? [];
    this.activeInOrganizationIds = params.activeInOrganizationIds ?? [];
    this.readAccessOrganizationIds = params.readAccessOrganizationIds ?? [];
    this.needsOrganizationProfile = params.needsOrganizationProfile ?? false;
    this.needsEmailVerification = params.needsEmailVerification ?? false;
    this.createdAt = typeof params.createdAt === "string" ? new Date(params.createdAt) : params.createdAt;
    this.deactivatedAt = typeof params.deactivatedAt === "string" ? new Date(params.deactivatedAt) : params.deactivatedAt;
    this.deactivationReason = params.deactivationReason;
  }
  get fullname() {
    return `${this.firstname} ${this.lastname}`;
  }
  get initials() {
    return `${this.firstname?.charAt(0) ?? ""}${this.lastname?.charAt(0) ?? ""}`;
  }
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
  toJSON() {
    return {
      id: this.id,
      email: this.email,
      emailVerified: this.emailVerified,
      firstname: this.firstname,
      lastname: this.lastname,
      avatarFileName: this.avatarFileName,
      avatarUrl: this.avatarUrl,
      organization: this.organization,
      role: this.role,
      permissions: this.permissions,
      acceptedTermsVersion: this.acceptedTermsVersion,
      settings: this.settings.toJSON(),
      timezone: this.timezone,
      timezoneName: this.timezoneName,
      organizationIds: this.organizationIds,
      activeInOrganizationIds: this.activeInOrganizationIds,
      readAccessOrganizationIds: this.readAccessOrganizationIds,
      needsOrganizationProfile: this.needsOrganizationProfile,
      needsEmailVerification: this.needsEmailVerification,
      createdAt: this.createdAt,
      deactivatedAt: this.deactivatedAt,
      deactivationReason: this.deactivationReason
    };
  }
};
var UserConverter = {
  toFirestore() {
    return {};
  },
  fromFirestore(doc10) {
    const data = doc10.data();
    return new User({ ...data, id: doc10.id, createdAt: data.createdAt?.toDate() });
  }
};

// src/client/organizations.ts
function getOrganizationsQuery(firestore) {
  return collection(firestore, "organizations").withConverter(OrganizationConverter);
}
async function getOrganizations(...args) {
  const { docs } = await getDocs(getOrganizationsQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetOrganizations(...args) {
  return useCollectionData(getOrganizationsQuery(...args));
}
function getOrganizationQuery(firestore, organizationId) {
  return doc2(firestore, "organizations", organizationId).withConverter(OrganizationConverter);
}
async function getOrganization(...args) {
  const doc10 = await getDoc(getOrganizationQuery(...args));
  return doc10.data();
}
function useGetOrganization(...args) {
  return useDocumentData(getOrganizationQuery(...args));
}
function getOrganizationPreselectedMembersQuery(firestore, organizationId) {
  return collection(firestore, "organizations", organizationId, "preselectedUsers").withConverter(OrganizationPreselectedMemberConverter);
}
async function getOrganizationPreselectedMembers(...args) {
  const { docs } = await getDocs(getOrganizationPreselectedMembersQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetOrganizationPreselectedMembers(...args) {
  return useCollectionData(getOrganizationPreselectedMembersQuery(...args));
}
function getOrganizationPreselectedMemberQuery(firestore, organizationId, userId) {
  return doc2(firestore, "organizations", organizationId, "preselectedUsers", userId).withConverter(OrganizationPreselectedMemberConverter);
}
async function getOrganizationPreselectedMember(...args) {
  const doc10 = await getDoc(getOrganizationPreselectedMemberQuery(...args));
  return doc10.data();
}
function useGetOrganizationPreselectedMember(...args) {
  return useDocumentData(getOrganizationPreselectedMemberQuery(...args));
}
function getOrganizationMembersQuery(firestore, organizationId) {
  return collection(firestore, "organizations", organizationId, "users").withConverter(OrganizationMemberConverter);
}
async function getOrganizationMembers(...args) {
  const { docs } = await getDocs(getOrganizationMembersQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetOrganizationMembers(...args) {
  return useCollectionData(getOrganizationMembersQuery(...args));
}
function getOrganizationMemberQuery(firestore, organizationId, userId) {
  return doc2(firestore, "organizations", organizationId, "users", userId).withConverter(OrganizationMemberConverter);
}
async function getOrganizationMember(...args) {
  const doc10 = await getDoc(getOrganizationMemberQuery(...args));
  return doc10.data();
}
function useGetOrganizationMember(...args) {
  return useDocumentData(getOrganizationMemberQuery(...args));
}
function getOrganizationUnregisteredInvitesQuery(firestore, organizationId) {
  return query(collection(firestore, "organizations_unregistered_invites"), where("organizationId", "==", organizationId)).withConverter(
    OrganizationUnregisteredInviteConverter
  );
}
async function getOrganizationUnregisteredInvites(...args) {
  const { docs } = await getDocs(getOrganizationUnregisteredInvitesQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetOrganizationUnregisteredInvites(...args) {
  return useCollectionData(getOrganizationUnregisteredInvitesQuery(...args));
}
function findOrganizationsQuery(firestore, filter) {
  if (!filter) {
    return getOrganizationsQuery(firestore);
  }
  const conditions = [
    filter.userId ? where("userIds", "array-contains", filter.userId) : null,
    filter.status ? where("status", "==", filter.status) : null,
    filter.limit ? limit(filter.limit) : null
  ].filter(Boolean);
  return query(collection(firestore, "organizations"), ...conditions).withConverter(OrganizationConverter);
}
async function findOrganizations(...args) {
  const { docs } = await getDocs(findOrganizationsQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useFindOrganizations(...args) {
  return useCollectionData(findOrganizationsQuery(...args));
}
async function createOrganization(firestore, data) {
  const ref = await addDoc(collection(firestore, "organizations"), {
    ...data,
    status: "CREATED" /* CREATED */,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp()
  });
  return ref;
}
async function updateOrganization(firestore, organizationId, data) {
  await updateDoc(doc2(firestore, "organizations", organizationId), { ...data, updatedAt: serverTimestamp() });
}

// src/client/sessions/use-find-sessions.ts
import { collection as collection2, getDocs as getDocs2, limit as limit2, query as query2, where as where2 } from "firebase/firestore";
import { useCollectionData as useCollectionData2 } from "react-firebase-hooks/firestore";
function findSessionsQuery(firestore, filter) {
  if (!filter) {
    return getSessionsQuery(firestore);
  }
  const conditions = [
    filter.userId ? where2("userIds", "array-contains", filter.userId) : null,
    filter.organizationIds && filter.organizationIds.length > 0 ? where2("organizationIds", "array-contains-any", filter.organizationIds) : null,
    filter.finished != null ? where2("finished", "==", filter.finished) : null,
    filter.limit ? limit2(filter.limit) : null
  ].filter(Boolean);
  return query2(collection2(firestore, "sessions"), ...conditions).withConverter(SessionConverter);
}
async function findSessions(...args) {
  const { docs } = await getDocs2(findSessionsQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useFindSessions(...args) {
  return useCollectionData2(findSessionsQuery(...args));
}

// src/client/sessions/use-get-organ.ts
import { doc as doc3, getDoc as getDoc2 } from "firebase/firestore";
import { useDocumentData as useDocumentData2 } from "react-firebase-hooks/firestore";
function getOrganQuery(firestore, sessionId, organId) {
  return doc3(firestore, "sessions", sessionId, "organs", organId).withConverter(OrganConverter);
}
async function getOrgan(...args) {
  const doc10 = await getDoc2(getOrganQuery(...args));
  return doc10.data();
}
function useGetOrgan(...args) {
  return useDocumentData2(getOrganQuery(...args));
}

// src/client/sessions/use-get-organ-advanced-tracking.ts
import { collection as collection3, getDocs as getDocs3, orderBy, query as query3 } from "firebase/firestore";
import { useCollectionData as useCollectionData3 } from "react-firebase-hooks/firestore";
function getOrganAdvancedTrackingQuery(firestore, sessionId, organId) {
  return query3(collection3(firestore, "sessions", sessionId, "organs", organId, "vantagePoints"), orderBy("timestamp", "asc")).withConverter(
    AdvancedTrackingConverter
  );
}
async function getOrganAdvancedTracking(...args) {
  const { docs } = await getDocs3(getOrganAdvancedTrackingQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetOrganAdvancedTracking(...args) {
  return useCollectionData3(getOrganAdvancedTrackingQuery(...args));
}

// src/client/sessions/use-get-organ-last-advanced-tracking.ts
import { collection as collection4, getDocs as getDocs4, limit as limit3, orderBy as orderBy2, query as query4 } from "firebase/firestore";
import { useCollectionData as useCollectionData4 } from "react-firebase-hooks/firestore";
function getOrganLastAdvancedTrackingQuery(firestore, sessionId, organId) {
  return query4(
    collection4(firestore, "sessions", sessionId, "organs", organId, "vantagePoints"),
    orderBy2("timestamp", "desc"),
    limit3(1)
  ).withConverter(AdvancedTrackingConverter);
}
async function getOrganLastAdvancedTracking(...args) {
  const { docs } = await getDocs4(getOrganLastAdvancedTrackingQuery(...args));
  return docs.map((doc10) => doc10.data())[0];
}
function useGetOrganLastAdvancedTracking(...args) {
  return useCollectionData4(getOrganLastAdvancedTrackingQuery(...args));
}

// src/client/sessions/use-get-organ-last-log.ts
import { collection as collection5, getDocs as getDocs5, limit as limit4, orderBy as orderBy3, query as query5 } from "firebase/firestore";
import { useCollectionData as useCollectionData5 } from "react-firebase-hooks/firestore";
function getOrganLastLogQuery(firestore, sessionId, organId) {
  return query5(collection5(firestore, "sessions", sessionId, "organs", organId, "logs"), orderBy3("order", "desc"), limit4(1)).withConverter(
    OrganReadingsPageConverter
  );
}
async function getLastOrganLog(...args) {
  const { docs } = await getDocs5(getOrganLastLogQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetLastOrganLog(...args) {
  return useCollectionData5(getOrganLastLogQuery(...args));
}

// src/client/sessions/use-get-organ-last-logger-location.ts
import { collection as collection6, getDocs as getDocs6, limit as limit5, orderBy as orderBy4, query as query6 } from "firebase/firestore";
import { useCollectionData as useCollectionData6 } from "react-firebase-hooks/firestore";
function getOrganLastLoggerLocationQuery(firestore, sessionId, organId) {
  return query6(
    collection6(firestore, "sessions", sessionId, "organs", organId, "locations"),
    orderBy4("timestamp", "desc"),
    limit5(1)
  ).withConverter(LoggerLocationConverter);
}
async function getOrganLastLoggerLocation(...args) {
  const { docs } = await getDocs6(getOrganLastLoggerLocationQuery(...args));
  return docs.map((doc10) => doc10.data())[0];
}
function useGetOrganLastLoggerLocation(...args) {
  return useCollectionData6(getOrganLastLoggerLocationQuery(...args));
}

// src/client/sessions/use-get-organ-logger-locations.ts
import { collection as collection7, getDocs as getDocs7, orderBy as orderBy5, query as query7 } from "firebase/firestore";
import { useCollectionData as useCollectionData7 } from "react-firebase-hooks/firestore";
function getOrganLoggerLocationsQuery(firestore, sessionId, organId) {
  return query7(collection7(firestore, "sessions", sessionId, "organs", organId, "locations"), orderBy5("timestamp", "asc")).withConverter(
    LoggerLocationConverter
  );
}
async function getOrganLoggerLocations(...args) {
  const { docs } = await getDocs7(getOrganLoggerLocationsQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetOrganLoggerLocations(...args) {
  return useCollectionData7(getOrganLoggerLocationsQuery(...args));
}

// src/client/sessions/use-get-organ-logs.ts
import { collection as collection8, getDocs as getDocs8, orderBy as orderBy6, query as query8 } from "firebase/firestore";
import { useCollectionData as useCollectionData8 } from "react-firebase-hooks/firestore";
function getOrganLogsQuery(firestore, sessionId, organId) {
  return query8(collection8(firestore, "sessions", sessionId, "organs", organId, "logs"), orderBy6("order", "asc")).withConverter(
    OrganReadingsPageConverter
  );
}
async function getOrganLogs(...args) {
  const { docs } = await getDocs8(getOrganLogsQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetOrganLogs(...args) {
  return useCollectionData8(getOrganLogsQuery(...args));
}

// src/client/sessions/use-get-organ-marker-details.ts
import { useMemo } from "react";

// src/client/sessions/use-get-session-last-advanced-tracking.ts
import { collection as collection9, getDocs as getDocs9, orderBy as orderBy7, query as query9 } from "firebase/firestore";
import { useCollectionData as useCollectionData9 } from "react-firebase-hooks/firestore";
function getSessionLastAdvancedTrackingQuery(firestore, sessionId) {
  return query9(collection9(firestore, "sessions", sessionId, "vantagePoints"), orderBy7("timestamp", "desc")).withConverter(
    AdvancedTrackingConverter
  );
}
async function getSessionLastAdvancedTracking(...args) {
  const { docs } = await getDocs9(getSessionLastAdvancedTrackingQuery(...args));
  return docs.map((doc10) => doc10.data())[0];
}
function useGetSessionLastAdvancedTracking(...args) {
  return useCollectionData9(getSessionLastAdvancedTrackingQuery(...args));
}

// src/client/sessions/use-get-session-last-logger-location.ts
import { collection as collection10, getDocs as getDocs10, orderBy as orderBy8, query as query10 } from "firebase/firestore";
import { useCollectionData as useCollectionData10 } from "react-firebase-hooks/firestore";
function getSessionLastLoggerLocationQuery(firestore, sessionId) {
  return query10(collection10(firestore, "sessions", sessionId, "locations"), orderBy8("timestamp", "desc")).withConverter(
    LoggerLocationConverter
  );
}
async function getSessionLastLoggerLocation(...args) {
  const { docs } = await getDocs10(getSessionLastLoggerLocationQuery(...args));
  return docs.map((doc10) => doc10.data())[0];
}
function useGetSessionLastLoggerLocation(...args) {
  return useCollectionData10(getSessionLastLoggerLocationQuery(...args));
}

// src/client/sessions/use-get-organ-marker-details.ts
function useGetOrganMarkerDetails(firestore, sessionId, organId) {
  const [lastLoggerLocation, isLastLoggerLocationLoading] = useOrganLastLoggerLocation(firestore, sessionId, organId);
  const [lastAdvancedTracking, isLastAdvancedTrackingLoading] = useOrganLastAdvancedTracking(firestore, sessionId, organId);
  const isLoading = useMemo(() => {
    return isLastLoggerLocationLoading || isLastAdvancedTrackingLoading;
  }, [isLastLoggerLocationLoading, isLastAdvancedTrackingLoading]);
  return {
    lastLoggerLocation,
    lastAdvancedTracking,
    isLoading
  };
}
function useOrganLastLoggerLocation(firestore, sessionId, organId) {
  const [lastSessionLoggerLocation, isLastSessionLoggerLocationLoading] = useGetSessionLastLoggerLocation(firestore, sessionId);
  const [lastOrganLoggerLocation, isLastOrganLoggerLocationLoading] = useGetOrganLastLoggerLocation(firestore, sessionId, organId);
  const isLoading = useMemo(() => {
    return isLastSessionLoggerLocationLoading || isLastOrganLoggerLocationLoading;
  }, [isLastSessionLoggerLocationLoading, isLastOrganLoggerLocationLoading]);
  const lastLoggerLocation = useMemo(() => {
    return lastOrganLoggerLocation?.[0] ?? lastSessionLoggerLocation?.[0];
  }, [lastSessionLoggerLocation, lastOrganLoggerLocation, isLoading]);
  return [lastLoggerLocation, isLoading];
}
function useOrganLastAdvancedTracking(firestore, sessionId, organId) {
  const [lastSessionAdvancedTracking, isLastSessionAdvancedTrackingLoading] = useGetSessionLastAdvancedTracking(firestore, sessionId);
  const [lastOrganAdvancedTracking, isLastOrganAdvancedTrackingLoading] = useGetOrganLastAdvancedTracking(firestore, sessionId, organId);
  const isLoading = useMemo(() => {
    return isLastSessionAdvancedTrackingLoading || isLastOrganAdvancedTrackingLoading;
  }, [isLastSessionAdvancedTrackingLoading, isLastOrganAdvancedTrackingLoading]);
  const lastAdvancedTracking = useMemo(() => {
    return lastOrganAdvancedTracking?.[0] ?? lastSessionAdvancedTracking?.[0];
  }, [lastOrganAdvancedTracking, lastSessionAdvancedTracking, isLoading]);
  return [lastAdvancedTracking, isLoading];
}

// src/client/sessions/use-get-organ-polylines-details.ts
import { useMemo as useMemo2 } from "react";

// src/client/sessions/use-get-session-advanced-tracking.ts
import { collection as collection11, getDocs as getDocs11, orderBy as orderBy9, query as query11 } from "firebase/firestore";
import { useCollectionData as useCollectionData11 } from "react-firebase-hooks/firestore";
function getSessionAdvancedTrackingQuery(firestore, sessionId) {
  return query11(collection11(firestore, "sessions", sessionId, "vantagePoints"), orderBy9("timestamp", "asc")).withConverter(
    AdvancedTrackingConverter
  );
}
async function getSessionAdvancedTracking(...args) {
  const { docs } = await getDocs11(getSessionAdvancedTrackingQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetSessionAdvancedTracking(...args) {
  return useCollectionData11(getSessionAdvancedTrackingQuery(...args));
}

// src/client/sessions/use-get-session-logger-locations.ts
import { collection as collection12, getDocs as getDocs12, orderBy as orderBy10, query as query12 } from "firebase/firestore";
import { useCollectionData as useCollectionData12 } from "react-firebase-hooks/firestore";
function getSessionLoggerLocationsQuery(firestore, sessionId) {
  return query12(collection12(firestore, "sessions", sessionId, "locations"), orderBy10("timestamp", "asc")).withConverter(
    LoggerLocationConverter
  );
}
async function getSessionLoggerLocations(...args) {
  const { docs } = await getDocs12(getSessionLoggerLocationsQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetSessionLoggerLocations(...args) {
  return useCollectionData12(getSessionLoggerLocationsQuery(...args));
}

// src/client/sessions/use-get-organ-polylines-details.ts
function useGetOrganPolylinesDetails(firestore, sessionId, organId) {
  const [loggerLocations, areLoggerLocationsLoading] = useOrganLoggerLocations(firestore, sessionId, organId);
  const [advancedTracking, isLastAdvancedTrackingLoading] = useOrganAdvancedTracking(firestore, sessionId, organId);
  const isLoading = useMemo2(() => {
    return areLoggerLocationsLoading || isLastAdvancedTrackingLoading;
  }, [areLoggerLocationsLoading, isLastAdvancedTrackingLoading]);
  return {
    loggerLocations,
    advancedTracking,
    isLoading
  };
}
function useOrganLoggerLocations(firestore, sessionId, organId) {
  const [sessionLoggerLocations, areSessionLoggerLocationsLoading] = useGetSessionLoggerLocations(firestore, sessionId);
  const [organLoggerLocations, areOrganLoggerLocationsLoading] = useGetOrganLoggerLocations(firestore, sessionId, organId);
  const isLoading = useMemo2(() => {
    return areSessionLoggerLocationsLoading || areOrganLoggerLocationsLoading;
  }, [areSessionLoggerLocationsLoading, areOrganLoggerLocationsLoading]);
  const loggerLocations = useMemo2(() => {
    return organLoggerLocations && organLoggerLocations.length > 0 ? organLoggerLocations : sessionLoggerLocations ?? [];
  }, [sessionLoggerLocations, organLoggerLocations, isLoading]);
  return [loggerLocations, isLoading];
}
function useOrganAdvancedTracking(firestore, sessionId, organId) {
  const [sessionAdvancedTracking, isSessionAdvancedTrackingLoading] = useGetSessionAdvancedTracking(firestore, sessionId);
  const [organAdvancedTracking, isOrganAdvancedTrackingLoading] = useGetOrganAdvancedTracking(firestore, sessionId, organId);
  const isLoading = useMemo2(() => {
    return isSessionAdvancedTrackingLoading || isOrganAdvancedTrackingLoading;
  }, [isSessionAdvancedTrackingLoading, isOrganAdvancedTrackingLoading]);
  const advancedTracking = useMemo2(() => {
    return organAdvancedTracking && organAdvancedTracking.length > 0 ? organAdvancedTracking : sessionAdvancedTracking ?? [];
  }, [organAdvancedTracking, sessionAdvancedTracking, isLoading]);
  return [advancedTracking, isLoading];
}

// src/client/sessions/use-get-organs.ts
import { collection as collection13, getDocs as getDocs13 } from "firebase/firestore";
import { useCollectionData as useCollectionData13 } from "react-firebase-hooks/firestore";
function getOrgansQuery(firestore, sessionId) {
  return collection13(firestore, "sessions", sessionId, "organs").withConverter(OrganConverter);
}
async function getOrgans(...args) {
  const { docs } = await getDocs13(getOrgansQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetOrgans(...args) {
  return useCollectionData13(getOrgansQuery(...args));
}

// src/client/sessions/use-get-session.ts
import { doc as doc4, getDoc as getDoc3 } from "firebase/firestore";
import { useDocumentData as useDocumentData3 } from "react-firebase-hooks/firestore";
function getSessionQuery(firestore, sessionId) {
  return doc4(firestore, "sessions", sessionId).withConverter(SessionConverter);
}
async function getSession(...args) {
  const doc10 = await getDoc3(getSessionQuery(...args));
  return doc10.data();
}
function useGetSession(...args) {
  return useDocumentData3(getSessionQuery(...args));
}

// src/client/sessions/use-get-session-audit-log.ts
import { collection as collection14, getDocs as getDocs14, orderBy as orderBy11, query as query13 } from "firebase/firestore";
import { useCollectionData as useCollectionData14 } from "react-firebase-hooks/firestore";
function getSessionAuditLogQuery(firestore, sessionId) {
  return query13(collection14(firestore, "sessions", sessionId, "auditLogs"), orderBy11("timestampServer", "desc")).withConverter(
    AuditLogConverter
  );
}
async function getSessionAuditLog(...args) {
  const { docs } = await getDocs14(getSessionAuditLogQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetSessionAuditLog(...args) {
  return useCollectionData14(getSessionAuditLogQuery(...args));
}

// src/client/sessions/use-get-session-details.ts
import { useEffect, useMemo as useMemo3, useState } from "react";

// src/client/config/use-get-config-destination.ts
import { doc as doc5, getDoc as getDoc4 } from "firebase/firestore";
import { useDocumentData as useDocumentData4 } from "react-firebase-hooks/firestore";
function getConfigDestinationQuery(firestore, destinationId) {
  return doc5(firestore, "config", "organizations", "destinations", destinationId).withConverter(DestinationConverter);
}
async function getConfigDestination(...args) {
  const doc10 = await getDoc4(getConfigDestinationQuery(...args));
  return doc10.data();
}

// src/client/sessions/use-get-session-ischemic-times.ts
import { collection as collection15, getDocs as getDocs15 } from "firebase/firestore";
import { useCollectionData as useCollectionData15 } from "react-firebase-hooks/firestore";
function getSessionIschemicTimesQuery(firestore, sessionId) {
  return collection15(firestore, "sessions", sessionId, "ischemicTimes").withConverter(SessionIschemicTimeConverter);
}
function useGetSessionIschemicTimes(...args) {
  return useCollectionData15(getSessionIschemicTimesQuery(...args));
}

// src/client/sessions/use-get-session-details.ts
function useGetSessionDetails(firestore, sessionId) {
  const [session, isSessionLoading] = useGetSession(firestore, sessionId);
  const [destination, isDestinationLoading] = useSessionDestination(firestore, { session, isSessionLoading });
  const [eta, isETALoading] = useSessionETA(firestore, sessionId, { session, isSessionLoading });
  const [ischemicTimes, areIschemicTimesLoading] = useSessionIschemicTimes(firestore, sessionId, { session, isSessionLoading });
  const [members, areMembersLoading] = useGetSessionMembers(firestore, sessionId);
  const [timeline, isTimelineLoading] = useGetSessionTimeline(firestore, sessionId);
  const [auditLog, isAuditLogLoading] = useGetSessionAuditLog(firestore, sessionId);
  const [flights, areFlightsLoading] = useSessionFlights(firestore, sessionId, { session, isSessionLoading });
  const [flightPositions, areFlightPositionsLoading] = useGetSessionFlightPositions(firestore, sessionId);
  const [organs, areOrgansLoading] = useOrgans(firestore, sessionId, { session, isSessionLoading });
  const isLoading = useMemo3(() => {
    return isSessionLoading || isDestinationLoading || isETALoading || areIschemicTimesLoading || areMembersLoading || isTimelineLoading || isAuditLogLoading || areFlightsLoading || areFlightPositionsLoading || areOrgansLoading;
  }, [
    isSessionLoading,
    isDestinationLoading,
    isETALoading,
    areIschemicTimesLoading,
    areMembersLoading,
    isTimelineLoading,
    isAuditLogLoading,
    areFlightsLoading,
    areFlightPositionsLoading,
    areOrgansLoading
  ]);
  const timelineAll = useMemo3(() => {
    if (!timeline || isTimelineLoading) {
      return [];
    }
    return timeline.filter((t) => !t.deleted);
  }, [timeline, isTimelineLoading]);
  const timelineLeft = useMemo3(() => {
    if (!timelineAll) {
      return [];
    }
    return timelineAll.filter((t) => {
      const entryOrganPosition = TimelineEntryTypeUtil.getOrganPosition(t.type);
      return !entryOrganPosition || entryOrganPosition === "LEFT" /* LEFT */;
    });
  }, [timelineAll]);
  const timelineRight = useMemo3(() => {
    if (!timelineAll) {
      return [];
    }
    return timelineAll.filter((t) => {
      const entryOrganPosition = TimelineEntryTypeUtil.getOrganPosition(t.type);
      return !entryOrganPosition || entryOrganPosition === "RIGHT" /* RIGHT */;
    });
  }, [timelineAll]);
  return {
    session,
    destination,
    eta: eta ?? [],
    ischemicTimes: ischemicTimes ?? [],
    members: members ?? [],
    timelines: {
      all: timelineAll,
      ["LEFT" /* LEFT */]: timelineLeft,
      ["RIGHT" /* RIGHT */]: timelineRight
    },
    auditLog: auditLog ?? [],
    flights: flights ?? [],
    flightPositions: flightPositions ?? [],
    organs: organs ?? [],
    isLoading
  };
}
function useSessionDestination(firestore, data) {
  const { session, isSessionLoading } = data;
  const [destination, setDestination] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let isMounted = true;
    if (isSessionLoading || !session) {
      setDestination(void 0);
      setIsLoading(false);
      return;
    }
    if (!session.destination) {
      setDestination(void 0);
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    getConfigDestination(firestore, session.destination).then((destination2) => {
      if (isMounted) {
        setDestination(destination2);
      }
    }).finally(() => {
      if (isMounted) {
        setIsLoading(false);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [firestore, session?.destination, isSessionLoading]);
  return [destination, isLoading];
}
function useSessionETA(firestore, sessionId, data) {
  const { session, isSessionLoading } = data;
  const [etaCollection, isETACollectionLoading] = useGetSessionETA(firestore, sessionId);
  const eta = useMemo3(() => {
    if (!session || isSessionLoading || isETACollectionLoading) {
      return [];
    }
    if (etaCollection && etaCollection.length > 0) {
      return etaCollection.filter((e) => !e.deleted).sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
    }
    return session.eta ? [new SessionETA({ destinationType: "RECIPIENT" /* RECIPIENT */, timestamp: session.eta })] : [];
  }, [session, isSessionLoading, etaCollection, isETACollectionLoading]);
  const isLoading = useMemo3(() => {
    return isSessionLoading || isETACollectionLoading;
  }, [isSessionLoading, isETACollectionLoading]);
  return [eta, isLoading];
}
function useSessionIschemicTimes(firestore, sessionId, data) {
  const { session, isSessionLoading } = data;
  const [ischemicTimesCollection, isIschemicTimesCollectionLoading] = useGetSessionIschemicTimes(firestore, sessionId);
  const ischemicTimes = useMemo3(() => {
    if (!session || isSessionLoading || isIschemicTimesCollectionLoading) {
      return [];
    }
    if (ischemicTimesCollection && ischemicTimesCollection.length > 0) {
      return ischemicTimesCollection;
    }
    return session.ischemicTime ? [session.ischemicTime] : [];
  }, [session, isSessionLoading, ischemicTimesCollection, isIschemicTimesCollectionLoading]);
  const isLoading = useMemo3(() => {
    return isSessionLoading || isIschemicTimesCollectionLoading;
  }, [isSessionLoading, isIschemicTimesCollectionLoading]);
  return [ischemicTimes, isLoading];
}
function useSessionFlights(firestore, sessionId, data) {
  const { session, isSessionLoading } = data;
  const [flightsCollection, isFlightsCollectionLoading] = useGetSessionFlights(firestore, sessionId);
  const flights = useMemo3(() => {
    if (!session || isSessionLoading || isFlightsCollectionLoading) {
      return [];
    }
    if (session.schemaVersion >= 6) {
      return flightsCollection?.filter((f) => !f.deleted) ?? [];
    }
    return session.flights ?? [];
  }, [session, isSessionLoading, flightsCollection, isFlightsCollectionLoading]);
  const isLoading = useMemo3(() => {
    return isSessionLoading || isFlightsCollectionLoading;
  }, [isSessionLoading, isFlightsCollectionLoading]);
  return [flights, isLoading];
}
function useOrgans(firestore, sessionId, data) {
  const { session, isSessionLoading } = data;
  const [organsCollection, isOrgansCollectionLoading] = useGetOrgans(firestore, sessionId);
  const organs = useMemo3(() => {
    if (!session || isSessionLoading || isOrgansCollectionLoading) {
      return [];
    }
    if (session.schemaVersion < 6) {
      return [session.toOrgan()];
    }
    return [
      organsCollection?.find((o) => o.position === "LEFT" /* LEFT */),
      organsCollection?.find((o) => o.position === "RIGHT" /* RIGHT */),
      organsCollection?.find((o) => !o.position)
    ].filter(Boolean);
  }, [session, isSessionLoading, organsCollection, isOrgansCollectionLoading]);
  const isLoading = useMemo3(() => {
    return isSessionLoading || isOrgansCollectionLoading;
  }, [isSessionLoading, isOrgansCollectionLoading]);
  return [organs, isLoading];
}

// src/client/sessions/use-get-session-eta.ts
import { collection as collection16, getDocs as getDocs16 } from "firebase/firestore";
import { useCollectionData as useCollectionData16 } from "react-firebase-hooks/firestore";
function getSessionETAQuery(firestore, sessionId) {
  return collection16(firestore, "sessions", sessionId, "eta").withConverter(SessionETAConverter);
}
async function getSessionETA(...args) {
  const { docs } = await getDocs16(getSessionETAQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetSessionETA(...args) {
  return useCollectionData16(getSessionETAQuery(...args));
}

// src/client/sessions/use-get-session-flight-positions.ts
import { collection as collection17, getDocs as getDocs17, orderBy as orderBy12, query as query14 } from "firebase/firestore";
import { useCollectionData as useCollectionData17 } from "react-firebase-hooks/firestore";
function getSessionFlightPositionsQuery(firestore, sessionId) {
  return query14(collection17(firestore, "sessions", sessionId, "flightPositions"), orderBy12("timestamp", "asc")).withConverter(
    FlightPositionConverter
  );
}
async function getSessionFlightPositions(...args) {
  const { docs } = await getDocs17(getSessionFlightPositionsQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetSessionFlightPositions(...args) {
  return useCollectionData17(getSessionFlightPositionsQuery(...args));
}

// src/client/sessions/use-get-session-flights.ts
import { collection as collection18, getDocs as getDocs18, query as query15 } from "firebase/firestore";
import { useCollectionData as useCollectionData18 } from "react-firebase-hooks/firestore";
function getSessionFlightsQuery(firestore, sessionId) {
  return query15(collection18(firestore, "sessions", sessionId, "flights")).withConverter(FlightConverter);
}
async function getSessionFlights(...args) {
  const { docs } = await getDocs18(getSessionFlightsQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetSessionFlights(...args) {
  return useCollectionData18(getSessionFlightsQuery(...args));
}

// src/client/sessions/use-get-session-last-timeline-entry.ts
import { collection as collection19, getDocs as getDocs19, limit as limit6, orderBy as orderBy13, query as query16 } from "firebase/firestore";
import { useCollectionData as useCollectionData19 } from "react-firebase-hooks/firestore";
function getSessionLastTimelineEntryQuery(firestore, sessionId) {
  return query16(collection19(firestore, "sessions", sessionId, "timeline"), orderBy13("timestamp", "desc"), limit6(1)).withConverter(
    TimelineEntryConverter
  );
}
async function getSessionLastTimelineEntry(...args) {
  const { docs } = await getDocs19(getSessionLastTimelineEntryQuery(...args));
  return docs.map((doc10) => doc10.data())[0];
}
function useGetSessionLastTimelineEntry(...args) {
  return useCollectionData19(getSessionLastTimelineEntryQuery(...args));
}

// src/client/sessions/use-get-session-log.ts
import { doc as doc6, getDoc as getDoc5 } from "firebase/firestore";
import { useDocumentData as useDocumentData5 } from "react-firebase-hooks/firestore";
function getSessionLogQuery(firestore, sessionId) {
  return doc6(firestore, "sessions", sessionId, "log", "log").withConverter(LogConverter);
}
async function getSessionLog(...args) {
  const doc10 = await getDoc5(getSessionLogQuery(...args));
  return doc10.data();
}
function useGetSessionLog(...args) {
  return useDocumentData5(getSessionLogQuery(...args));
}

// src/client/sessions/use-get-session-marker-details.ts
import { useMemo as useMemo4 } from "react";
function useGetSessionMarkerDetails(firestore, sessionId) {
  const [session, isSessionLoading] = useGetSession(firestore, sessionId);
  const [flights, lastFlightPositions, areFlightsLoading] = useSessionFlights2(firestore, sessionId, {
    session,
    isSessionLoading
  });
  const [organs, areOrgansLoading] = useOrgans2(firestore, sessionId, { session, isSessionLoading });
  const isLoading = useMemo4(() => {
    return isSessionLoading || areFlightsLoading || areOrgansLoading;
  }, [isSessionLoading, areFlightsLoading, areOrgansLoading]);
  return {
    session,
    flights,
    lastFlightPositions,
    organs,
    isLoading
  };
}
function useSessionFlights2(firestore, sessionId, data) {
  const { session, isSessionLoading } = data;
  const [flightPositions, areFlightPositionsLoading] = useGetSessionFlightPositions(firestore, sessionId);
  const [flightsCollection, isFlightsCollectionLoading] = useGetSessionFlights(firestore, sessionId);
  const flights = useMemo4(() => {
    if (!session || isSessionLoading || isFlightsCollectionLoading) {
      return [];
    }
    if (session.schemaVersion >= 6) {
      return flightsCollection?.filter((f) => !f.deleted) ?? [];
    }
    return session.flights ?? [];
  }, [session, isSessionLoading, flightsCollection, isFlightsCollectionLoading]);
  const lastFlightPositions = useMemo4(() => {
    if (!flights || !flightPositions || flightPositions.length === 0 || areFlightPositionsLoading) {
      return null;
    }
    return flights.reduce(
      (acc, flight) => {
        if (!flight.flightId) {
          return acc;
        }
        const positions = flightPositions.filter((fp) => fp.flightId === flight.flightId);
        if (positions.length === 0) {
          return acc;
        }
        acc[flight.flightId] = positions[positions.length - 1];
        return acc;
      },
      {}
    );
  }, [flights, flightPositions, areFlightPositionsLoading]);
  const isLoading = useMemo4(() => {
    return isSessionLoading || isFlightsCollectionLoading || areFlightPositionsLoading;
  }, [isSessionLoading, isFlightsCollectionLoading, areFlightPositionsLoading]);
  return [flights, lastFlightPositions, isLoading];
}
function useOrgans2(firestore, sessionId, data) {
  const { session, isSessionLoading } = data;
  const [organsCollection, isOrgansCollectionLoading] = useGetOrgans(firestore, sessionId);
  const organs = useMemo4(() => {
    if (!session || isSessionLoading || isOrgansCollectionLoading) {
      return [];
    }
    if (session.schemaVersion < 6) {
      return [session.toOrgan()];
    }
    return [
      organsCollection?.find((o) => o.position === "LEFT" /* LEFT */),
      organsCollection?.find((o) => o.position === "RIGHT" /* RIGHT */),
      organsCollection?.find((o) => !o.position)
    ].filter(Boolean);
  }, [session, isSessionLoading, organsCollection, isOrgansCollectionLoading]);
  const isLoading = useMemo4(() => {
    return isSessionLoading || isOrgansCollectionLoading;
  }, [isSessionLoading, isOrgansCollectionLoading]);
  return [organs, isLoading];
}

// src/client/sessions/use-get-session-members.ts
import { collection as collection20, getDocs as getDocs20, query as query17 } from "firebase/firestore";
import { useCollectionData as useCollectionData20 } from "react-firebase-hooks/firestore";
function getSessionMembersQuery(firestore, sessionId) {
  return query17(collection20(firestore, "sessions", sessionId, "users")).withConverter(SessionMemberConverter);
}
async function getSessionMembers(...args) {
  const { docs } = await getDocs20(getSessionMembersQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetSessionMembers(...args) {
  return useCollectionData20(getSessionMembersQuery(...args));
}

// src/client/sessions/use-get-session-timeline.ts
import { collection as collection21, getDocs as getDocs21, orderBy as orderBy14, query as query18 } from "firebase/firestore";
import { useCollectionData as useCollectionData21 } from "react-firebase-hooks/firestore";
function getSessionTimelineQuery(firestore, sessionId) {
  return query18(collection21(firestore, "sessions", sessionId, "timeline"), orderBy14("timestamp", "desc")).withConverter(
    TimelineEntryConverter
  );
}
async function getSessionTimeline(...args) {
  const { docs } = await getDocs21(getSessionTimelineQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetSessionTimeline(...args) {
  return useCollectionData21(getSessionTimelineQuery(...args));
}

// src/client/sessions/use-get-sessions.ts
import { collection as collection22, getDocs as getDocs22 } from "firebase/firestore";
import { useCollectionData as useCollectionData22 } from "react-firebase-hooks/firestore";
function getSessionsQuery(firestore) {
  return collection22(firestore, "sessions").withConverter(SessionConverter);
}
async function getSessions(...args) {
  const { docs } = await getDocs22(getSessionsQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetSessions(...args) {
  return useCollectionData22(getSessionsQuery(...args));
}

// src/client/shared-sessions/use-get-shared-session.ts
import { doc as doc7, getDoc as getDoc6 } from "firebase/firestore";
import { useDocumentData as useDocumentData6 } from "react-firebase-hooks/firestore";
function getSharedSessionQuery(firestore, sessionId) {
  return doc7(firestore, "shared_sessions", sessionId).withConverter(SharedSessionConverter);
}
async function getSharedSession(...args) {
  const doc10 = await getDoc6(getSharedSessionQuery(...args));
  return doc10.data();
}
function useGetSharedSession(...args) {
  return useDocumentData6(getSharedSessionQuery(...args));
}

// src/client/shared-sessions/use-get-shared-session-details.ts
import { useMemo as useMemo5 } from "react";
function useGetSharedSessionDetails(firestore, sessionId) {
  const [session, isSessionLoading] = useGetSharedSession(firestore, sessionId);
  const [ischemicTimes, areIschemicTimesLoading] = useGetSharedSessionIschemicTimes(firestore, sessionId);
  const [timeline, isTimelineLoading] = useGetSharedSessionTimeline(firestore, sessionId);
  const [organsCollection, isOrgansCollectionLoading] = useGetSharedSessionOrgans(firestore, sessionId);
  const isLoading = useMemo5(() => {
    return isSessionLoading || areIschemicTimesLoading || isTimelineLoading || isOrgansCollectionLoading;
  }, [isSessionLoading, areIschemicTimesLoading, isTimelineLoading, isOrgansCollectionLoading]);
  const organs = useMemo5(() => {
    return [
      organsCollection?.find((o) => o.position === "LEFT" /* LEFT */),
      organsCollection?.find((o) => o.position === "RIGHT" /* RIGHT */),
      organsCollection?.find((o) => !o.position)
    ].filter(Boolean);
  }, [organsCollection, isOrgansCollectionLoading]);
  const timelineAll = useMemo5(() => {
    if (!timeline || isTimelineLoading) {
      return [];
    }
    return timeline.filter((t) => !t.deleted);
  }, [timeline, isTimelineLoading]);
  const timelineLeft = useMemo5(() => {
    if (!timeline || isTimelineLoading) {
      return [];
    }
    return timelineAll.filter((t) => {
      const entryOrganPosition = TimelineEntryTypeUtil.getOrganPosition(t.type);
      return !entryOrganPosition || entryOrganPosition === "LEFT" /* LEFT */;
    });
  }, [timelineAll]);
  const timelineRight = useMemo5(() => {
    if (!timeline || isTimelineLoading) {
      return [];
    }
    return timelineAll.filter((t) => {
      const entryOrganPosition = TimelineEntryTypeUtil.getOrganPosition(t.type);
      return !entryOrganPosition || entryOrganPosition === "RIGHT" /* RIGHT */;
    });
  }, [timelineAll]);
  return {
    session,
    ischemicTimes: ischemicTimes ?? [],
    timelines: {
      all: timelineAll,
      ["LEFT" /* LEFT */]: timelineLeft,
      ["RIGHT" /* RIGHT */]: timelineRight
    },
    organs: organs ?? [],
    isLoading
  };
}

// src/client/shared-sessions/use-get-shared-session-ischemic-times.ts
import { collection as collection23, getDocs as getDocs23 } from "firebase/firestore";
import { useCollectionData as useCollectionData23 } from "react-firebase-hooks/firestore";
function getSharedSessionIschemicTimesQuery(firestore, sessionId) {
  return collection23(firestore, "shared_sessions", sessionId, "ischemicTimes").withConverter(SessionIschemicTimeConverter);
}
async function getSharedSessionIschemicTimes(...args) {
  const { docs } = await getDocs23(getSharedSessionIschemicTimesQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetSharedSessionIschemicTimes(...args) {
  return useCollectionData23(getSharedSessionIschemicTimesQuery(...args));
}

// src/client/shared-sessions/use-get-shared-session-organ.ts
import { doc as doc8, getDoc as getDoc7 } from "firebase/firestore";
import { useDocumentData as useDocumentData7 } from "react-firebase-hooks/firestore";
function getSharedSessionOrganQuery(firestore, sessionId, organId) {
  return doc8(firestore, "shared_sessions", sessionId, "organs", organId).withConverter(OrganConverter);
}
async function getSharedSessionOrgan(...args) {
  const doc10 = await getDoc7(getSharedSessionOrganQuery(...args));
  return doc10.data();
}
function useGetSharedSessionOrgan(...args) {
  return useDocumentData7(getSharedSessionOrganQuery(...args));
}

// src/client/shared-sessions/use-get-shared-session-organ-logs.ts
import { collection as collection24, getDocs as getDocs24, orderBy as orderBy15, query as query19 } from "firebase/firestore";
import { useCollectionData as useCollectionData24 } from "react-firebase-hooks/firestore";
function getSharedSessionOrganLogsQuery(firestore, sessionId, organId) {
  return query19(collection24(firestore, "shared_sessions", sessionId, "organs", organId, "logs"), orderBy15("order", "asc")).withConverter(
    OrganReadingsPageConverter
  );
}
async function getSharedSessionOrganLogs(...args) {
  const { docs } = await getDocs24(getSharedSessionOrganLogsQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetSharedSessionOrganLogs(...args) {
  return useCollectionData24(getSharedSessionOrganLogsQuery(...args));
}

// src/client/shared-sessions/use-get-shared-session-organs.ts
import { collection as collection25, getDocs as getDocs25 } from "firebase/firestore";
import { useCollectionData as useCollectionData25 } from "react-firebase-hooks/firestore";
function getSharedSessionOrgansQuery(firestore, sessionId) {
  return collection25(firestore, "shared_sessions", sessionId, "organs").withConverter(OrganConverter);
}
async function getSharedSessionOrgans(...args) {
  const { docs } = await getDocs25(getSharedSessionOrgansQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetSharedSessionOrgans(...args) {
  return useCollectionData25(getSharedSessionOrgansQuery(...args));
}

// src/client/shared-sessions/use-get-shared-session-timeline.ts
import { collection as collection26, getDocs as getDocs26, orderBy as orderBy16, query as query20 } from "firebase/firestore";
import { useCollectionData as useCollectionData26 } from "react-firebase-hooks/firestore";
function getSharedSessionTimelineQuery(firestore, sessionId) {
  return query20(collection26(firestore, "shared_sessions", sessionId, "timeline"), orderBy16("timestamp", "desc")).withConverter(
    TimelineEntryConverter
  );
}
async function getSharedSessionTimeline(...args) {
  const { docs } = await getDocs26(getSharedSessionTimelineQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetSharedSessionTimeline(...args) {
  return useCollectionData26(getSharedSessionTimelineQuery(...args));
}

// src/client/users.ts
import { collection as collection27, doc as doc9, getDoc as getDoc8, getDocs as getDocs27, serverTimestamp as serverTimestamp2, setDoc as setDoc2, updateDoc as updateDoc2 } from "firebase/firestore";
import { useCollectionData as useCollectionData27, useDocumentData as useDocumentData8 } from "react-firebase-hooks/firestore";
function getUsersQuery(firestore) {
  return collection27(firestore, "users").withConverter(UserConverter);
}
async function getUsers(...args) {
  const { docs } = await getDocs27(getUsersQuery(...args));
  return docs.map((doc10) => doc10.data());
}
function useGetUsers(...args) {
  return useCollectionData27(getUsersQuery(...args));
}
function getUserQuery(firestore, userId) {
  return doc9(firestore, "users", userId).withConverter(UserConverter);
}
async function getUser(...args) {
  const doc10 = await getDoc8(getUserQuery(...args));
  return doc10.data();
}
function useGetUser(...args) {
  return useDocumentData8(getUserQuery(...args));
}
async function createUser(firestore, id, data) {
  await setDoc2(doc9(firestore, "users", id), {
    ...data,
    needsOrganizationProfile: true,
    needsEmailVerification: true,
    createdAt: serverTimestamp2()
  });
}
async function updateUser(firestore, userId, data) {
  await updateDoc2(doc9(firestore, "users", userId), data);
}
async function updateUserSettings(firestore, userId, data) {
  await Promise.all(Object.entries(data).map(([key, value]) => updateDoc2(doc9(firestore, "users", userId), { [`settings.${key}`]: value })));
}
export {
  cancelOrganizationAccessRequestFn,
  confirmEmailVerificationFn,
  confirmOrganizationValidationFn,
  createDemoSessionFn,
  createOrganization,
  createSessionFn,
  createSharedSessionFn,
  createUser,
  exportSessionsToSheetFn,
  findOrganizations,
  findOrganizationsQuery,
  findSessions,
  findSessionsQuery,
  getLastOrganLog,
  getMessagingToken,
  getOrgan,
  getOrganAdvancedTracking,
  getOrganAdvancedTrackingQuery,
  getOrganLastAdvancedTracking,
  getOrganLastAdvancedTrackingQuery,
  getOrganLastLogQuery,
  getOrganLastLoggerLocation,
  getOrganLastLoggerLocationQuery,
  getOrganLoggerLocations,
  getOrganLoggerLocationsQuery,
  getOrganLogs,
  getOrganLogsQuery,
  getOrganQuery,
  getOrganization,
  getOrganizationMember,
  getOrganizationMemberQuery,
  getOrganizationMembers,
  getOrganizationMembersQuery,
  getOrganizationPreselectedMember,
  getOrganizationPreselectedMemberQuery,
  getOrganizationPreselectedMembers,
  getOrganizationPreselectedMembersQuery,
  getOrganizationQuery,
  getOrganizationUnregisteredInvites,
  getOrganizationUnregisteredInvitesQuery,
  getOrganizations,
  getOrganizationsQuery,
  getOrgans,
  getOrgansQuery,
  getSession,
  getSessionAdvancedTracking,
  getSessionAdvancedTrackingQuery,
  getSessionAuditLog,
  getSessionAuditLogQuery,
  getSessionETA,
  getSessionETAQuery,
  getSessionFlightPositions,
  getSessionFlightPositionsQuery,
  getSessionFlights,
  getSessionFlightsQuery,
  getSessionLastAdvancedTracking,
  getSessionLastAdvancedTrackingQuery,
  getSessionLastLoggerLocation,
  getSessionLastLoggerLocationQuery,
  getSessionLastTimelineEntry,
  getSessionLastTimelineEntryQuery,
  getSessionLog,
  getSessionLogQuery,
  getSessionLoggerLocations,
  getSessionLoggerLocationsQuery,
  getSessionMembers,
  getSessionMembersQuery,
  getSessionQuery,
  getSessionTimeline,
  getSessionTimelineQuery,
  getSessions,
  getSessionsQuery,
  getSharedSession,
  getSharedSessionIschemicTimes,
  getSharedSessionIschemicTimesQuery,
  getSharedSessionOrgan,
  getSharedSessionOrganLogs,
  getSharedSessionOrganLogsQuery,
  getSharedSessionOrganQuery,
  getSharedSessionOrgans,
  getSharedSessionOrgansQuery,
  getSharedSessionQuery,
  getSharedSessionTimeline,
  getSharedSessionTimelineQuery,
  getUser,
  getUserQuery,
  getUsers,
  getUsersQuery,
  grantOrganizationAccessFn,
  inviteToOrganizationFn,
  joinOrganizationFn,
  joinSessionFn,
  leaveOrganizationFn,
  onFirebaseMessage,
  rejectOrganizationAccessRequestFn,
  requestOrganizationAccessFn,
  searchAirports,
  sendEmailVerificationFn,
  sendOrganizationMemberInviteFn,
  sendPasswordResetFn,
  signInWithEmailAndPassword,
  signOut,
  startOrganizationValidationFn,
  updateOrganization,
  updateUser,
  updateUserSettings,
  useFindOrganizations,
  useFindSessions,
  useGetLastOrganLog,
  useGetOrgan,
  useGetOrganAdvancedTracking,
  useGetOrganLastAdvancedTracking,
  useGetOrganLastLoggerLocation,
  useGetOrganLoggerLocations,
  useGetOrganLogs,
  useGetOrganMarkerDetails,
  useGetOrganPolylinesDetails,
  useGetOrganization,
  useGetOrganizationMember,
  useGetOrganizationMembers,
  useGetOrganizationPreselectedMember,
  useGetOrganizationPreselectedMembers,
  useGetOrganizationUnregisteredInvites,
  useGetOrganizations,
  useGetOrgans,
  useGetSession,
  useGetSessionAdvancedTracking,
  useGetSessionAuditLog,
  useGetSessionDetails,
  useGetSessionETA,
  useGetSessionFlightPositions,
  useGetSessionFlights,
  useGetSessionLastAdvancedTracking,
  useGetSessionLastLoggerLocation,
  useGetSessionLastTimelineEntry,
  useGetSessionLog,
  useGetSessionLoggerLocations,
  useGetSessionMarkerDetails,
  useGetSessionMembers,
  useGetSessionTimeline,
  useGetSessions,
  useGetSharedSession,
  useGetSharedSessionDetails,
  useGetSharedSessionIschemicTimes,
  useGetSharedSessionOrgan,
  useGetSharedSessionOrganLogs,
  useGetSharedSessionOrgans,
  useGetSharedSessionTimeline,
  useGetUser,
  useGetUsers
};
