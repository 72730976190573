import { Outlet } from '@remix-run/react';
import { LoaderFunctionArgs, redirect } from '@vercel/remix';
import { UserSession } from '../../.server/user-session';
import { AuthLayout } from './layout';

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const isAuthenticated = await UserSession.isAuthenticated(request);

  if (isAuthenticated) {
    throw redirect('/');
  }

  return null;
};

export default function Route() {
  return (
    <AuthLayout.Root>
      <Outlet />
    </AuthLayout.Root>
  );
}
