// src/typography/code.tsx
import { forwardRef as forwardRef2 } from "react";

// src/typography/prose.tsx
import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import { jsx } from "react/jsx-runtime";
var Prose = forwardRef(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx("div", { ref, className: twMerge("prose prose-sm marker:text-black", className), ...props });
});

// src/typography/code.tsx
import { jsx as jsx2 } from "react/jsx-runtime";
var Code = forwardRef2(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx2(Prose, { className, children: /* @__PURE__ */ jsx2("code", { ref, ...props }) });
});

// src/typography/description-list.tsx
import { createContext, forwardRef as forwardRef3, useContext } from "react";
import { twMerge as twMerge2 } from "tailwind-merge";
import { jsx as jsx3 } from "react/jsx-runtime";
var justifies = {
  start: "flex flex-col items-start space-y-1",
  center: "flex flex-col items-center space-y-1",
  end: "flex flex-col items-end space-y-1",
  between: "flex justify-between items-center space-x-4"
};
var DescriptionListContext = createContext({
  justify: "start"
});
var Root = forwardRef3(({ className, justify = "start", ...props }, ref) => {
  return /* @__PURE__ */ jsx3(DescriptionListContext.Provider, { value: { justify }, children: /* @__PURE__ */ jsx3("dl", { ref, className: twMerge2("space-y-4 text-sm", className), ...props }) });
});
var Item = forwardRef3(({ className, ...props }, ref) => {
  const { justify } = useContext(DescriptionListContext);
  return /* @__PURE__ */ jsx3("div", { ref, className: twMerge2(justifies[justify ?? "start"], className), ...props });
});
var Term = forwardRef3(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx3("dt", { ref, className: twMerge2("text-muted", className), ...props });
});
var Detail = forwardRef3(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx3("dd", { ref, className: twMerge2("font-medium", className), ...props });
});
var DescriptionList = {
  Root,
  Item,
  Term,
  Detail
};

// src/typography/heading.tsx
import { forwardRef as forwardRef4 } from "react";
import { jsx as jsx4, jsxs } from "react/jsx-runtime";
var Heading = forwardRef4(({ className, size, ...props }, ref) => {
  return /* @__PURE__ */ jsxs(Prose, { className, children: [
    size === "1" && /* @__PURE__ */ jsx4("h1", { ref, className: "!my-0", ...props }),
    size === "2" && /* @__PURE__ */ jsx4("h2", { ref, className: "!my-0", ...props }),
    size === "3" && /* @__PURE__ */ jsx4("h3", { ref, className: "!my-0", ...props }),
    size === "4" && /* @__PURE__ */ jsx4("h4", { ref, className: "!my-0", ...props })
  ] });
});

// src/typography/label.tsx
import { forwardRef as forwardRef5 } from "react";
import { twMerge as twMerge3 } from "tailwind-merge";
import { jsx as jsx5 } from "react/jsx-runtime";
var Label = forwardRef5(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx5("label", { ref, className: twMerge3("block text-sm font-medium leading-6", className), ...props });
});

// src/typography/muted.tsx
import { forwardRef as forwardRef6 } from "react";
import { twMerge as twMerge4 } from "tailwind-merge";
import { jsx as jsx6 } from "react/jsx-runtime";
var Muted = forwardRef6(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx6("span", { ref, className: twMerge4("!text-muted-foreground", className), ...props });
});

// src/typography/paragraph.tsx
import { forwardRef as forwardRef7 } from "react";
import { jsx as jsx7 } from "react/jsx-runtime";
var Paragraph = forwardRef7(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx7(Prose, { className, children: /* @__PURE__ */ jsx7("p", { ref, className: "!my-0", ...props }) });
});

// src/typography/unordered-list.tsx
import { forwardRef as forwardRef8 } from "react";
import { twMerge as twMerge5 } from "tailwind-merge";
import { jsx as jsx8 } from "react/jsx-runtime";
var Root2 = forwardRef8(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx8("ul", { ref, className: twMerge5("space-y-2.5 text-sm marker:text-black", className), ...props });
});
var Item2 = forwardRef8(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx8("li", { ref, className: twMerge5(className), ...props });
});
var UnorderedList = {
  Root: Root2,
  Item: Item2
};
export {
  Code,
  DescriptionList,
  Heading,
  Label,
  Muted,
  Paragraph,
  Prose,
  UnorderedList
};
