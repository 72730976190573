import { sendPasswordResetFn } from '@packages/firebase/client';
import { Alert, Button, Input } from '@packages/ui/core';
import { withZod } from '@remix-validated-form/with-zod';
import { useState } from 'react';
import { ValidatedForm, useField, useIsSubmitting, useIsValid } from 'remix-validated-form';
import { z } from 'zod';
import { FirebaseManager } from '~/firebase-manager';

export const ForgotPasswordFormValidator = withZod(
  z.object({
    email: z.string().trim().toLowerCase().min(1, { message: 'Email is required.' }).email('Enter a valid email.'),
  }),
);

type ForgotPasswordFormProps = {
  success?: boolean;
  error?: string;
  onSubmit: (data: { email: string }) => void;
};

type ForgotPasswordFormData = {
  email: string;
};

export function useForgotPasswordFormSubmit() {
  const [success, setSuccess] = useState<boolean>();
  const [error, setError] = useState<string>();

  return {
    onSubmit: async (data: ForgotPasswordFormData) => {
      const client = FirebaseManager.getApp();

      setSuccess(false);
      setError(undefined);

      try {
        await sendPasswordResetFn(client.functions, data.email);
        setSuccess(true);
      } catch (err: any) {
        setSuccess(true);
      }
    },
    success,
    error,
  };
}

export const ForgotPasswordForm = ({ success = false, error, onSubmit }: ForgotPasswordFormProps) => {
  const email = useField('email', { formId: 'forgot-password' });

  const isValid = useIsValid('forgot-password');
  const isSubmitting = useIsSubmitting('forgot-password');

  const disabled = (!error && isSubmitting) || !isValid;
  const isLoading = !error && isSubmitting;

  return (
    <ValidatedForm
      id="forgot-password"
      validator={ForgotPasswordFormValidator}
      onSubmit={(data, event) => {
        event.preventDefault();
        onSubmit(data);
      }}
      method="POST"
    >
      <div className="space-y-6">
        <div className="space-y-4">
          {success && (
            <Alert.Root color="success">
              <Alert.Title>Success!</Alert.Title>
              <Alert.Description>
                If an account with this email exists, you will receive a password reset link shortly. Check your inbox and spam folder.
              </Alert.Description>
            </Alert.Root>
          )}

          <Input.Root {...email.getInputProps()} type="email" placeholder="name@example.com">
            <Input.Header>
              <Input.Label>Email</Input.Label>
            </Input.Header>

            <Input.Field />

            {email.error && <Input.Error>{email.error}</Input.Error>}
          </Input.Root>
        </div>

        <Button name="forgot-password-submit" type="submit" size="lg" disabled={disabled} isLoading={isLoading} isFullWidth>
          Send Reset Link
        </Button>
      </div>
    </ValidatedForm>
  );
};
