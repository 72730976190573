import { Alert, Button, Input } from '@packages/ui/core';
import { useSubmit } from '@remix-run/react';
import { withZod } from '@remix-validated-form/with-zod';
import { browserLocalPersistence, signInWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';
import { ValidatedForm, useField, useIsSubmitting, useIsValid } from 'remix-validated-form';
import { toast } from 'sonner';
import { z } from 'zod';
import { FirebaseManager } from '~/firebase-manager';
import { getFirebaseAuthErrorMessage } from '~/shared/utils/get-firebase-auth-error-message';

export const SignInFormValidator = withZod(
  z.object({
    email: z.string().trim().toLowerCase().min(1, { message: 'Email is required.' }).email('Enter a valid email.'),
    password: z.string().min(1, { message: 'Password is required.' }),
  }),
);

type SignInFormProps = {
  error?: string;
  forgotPasswordUrl?: string;
  onSubmit: (data: SignInFormData) => void;
};

type SignInFormData = {
  email: string;
  password: string;
};

export function useSignInFormSubmit() {
  const submit = useSubmit();

  const [error, setError] = useState<string>();

  return {
    onSubmit: async (data: SignInFormData, action = '/sign-in') => {
      const { auth } = FirebaseManager.getApp(data.email);

      if (auth.currentUser) {
        toast.error(`You are already signed in as ${auth.currentUser.email}.`);
        return;
      }

      try {
        auth.setPersistence(browserLocalPersistence);

        const { user } = await signInWithEmailAndPassword(auth, data.email, data.password);

        const token = await user.getIdToken();

        submit({ token }, { method: 'POST', action });
      } catch (err: any) {
        setError(getFirebaseAuthErrorMessage(err.code));
      }
    },
    error,
  };
}

export const SignInForm = ({ error, forgotPasswordUrl = '/forgot-password', onSubmit }: SignInFormProps) => {
  const email = useField('email', { formId: 'sign-in' });
  const password = useField('password', { formId: 'sign-in' });

  const isValid = useIsValid('sign-in');
  const isSubmitting = useIsSubmitting('sign-in');

  const disabled = isSubmitting || !isValid;
  const isLoading = isSubmitting;

  return (
    <ValidatedForm
      id="sign-in"
      validator={SignInFormValidator}
      onSubmit={(data, event) => {
        event.preventDefault();
        onSubmit(data);
      }}
      method="POST"
    >
      <div className="space-y-6">
        <div className="space-y-4">
          {error && (
            <Alert.Root color="danger">
              <Alert.Title>Something went wrong!</Alert.Title>
              <Alert.Description>{error}</Alert.Description>
            </Alert.Root>
          )}

          <Input.Root {...email.getInputProps()} type="email" placeholder="name@example.com">
            <Input.Header>
              <Input.Label>Email</Input.Label>
            </Input.Header>

            <Input.Field />

            {email.error && <Input.Error>{email.error}</Input.Error>}
          </Input.Root>

          <Input.Root {...password.getInputProps()} type="password">
            <Input.Header>
              <Input.Label>Password</Input.Label>
              <Input.CornerHint>
                <a href={forgotPasswordUrl}>Forgot Password?</a>
              </Input.CornerHint>
            </Input.Header>

            <Input.Field />

            {password.error && <Input.Error>{password.error}</Input.Error>}
          </Input.Root>
        </div>

        <Button name="sign-in-submit" type="submit" size="lg" disabled={disabled} isLoading={isLoading} isFullWidth>
          Sign In
        </Button>
      </div>
    </ValidatedForm>
  );
};
