import { createUser } from '@packages/firebase/client';
import { Alert, Button, Input } from '@packages/ui/core';
import { Muted, Paragraph } from '@packages/ui/typography';
import { Link, useSubmit } from '@remix-run/react';
import { withZod } from '@remix-validated-form/with-zod';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';
import { ValidatedForm, useField, useIsSubmitting, useIsValid } from 'remix-validated-form';
import { z } from 'zod';
import { FirebaseManager } from '~/firebase-manager';
import { getFirebaseAuthErrorMessage } from '~/shared/utils/get-firebase-auth-error-message';

export const SignUpFormValidator = withZod(
  z.object({
    firstname: z
      .string()
      .trim()
      .min(1, { message: 'First name is required.' })
      .refine(value => value.trim().length > 0, { message: 'First name is required' }),
    lastname: z
      .string()
      .trim()
      .min(1, { message: 'Last name is required.' })
      .refine(value => value.trim().length > 0, { message: 'Last name is required' }),
    email: z.string().trim().toLowerCase().min(1, { message: 'Email is required.' }).email('Enter a valid email.'),
    password: z.string().min(6, { message: 'Password must be at least 6 characters.' }),
  }),
);

type SignUpFormProps = {
  email?: string;
  error?: string;
  onSubmit: (data: SignUpFormData) => void;
};

type SignUpFormData = {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
};

export function useSignUpFormSubmit() {
  const submit = useSubmit();

  const [error, setError] = useState<string>();

  return {
    onSubmit: async (data: SignUpFormData, action = '/sign-up') => {
      const client = FirebaseManager.getApp(data.email);

      try {
        const { user } = await createUserWithEmailAndPassword(client.auth, data.email, data.password);

        if (!user.email) {
          throw new Error('User email is missing.');
        }

        await createUser(client.firestore, user.uid, {
          email: user.email,
          firstname: data.firstname,
          lastname: data.lastname,
        });

        const token = await user.getIdToken();

        submit({ token }, { method: 'POST', action });
      } catch (err: any) {
        setError(getFirebaseAuthErrorMessage(err.code ?? 'auth/invalid-email'));
      }
    },
    error,
  };
}

export const SignUpForm = ({ email: _email, error, onSubmit }: SignUpFormProps) => {
  const firstname = useField('firstname', { formId: 'sign-up' });
  const lastname = useField('lastname', { formId: 'sign-up' });
  const email = useField('email', { formId: 'sign-up' });
  const password = useField('password', { formId: 'sign-up' });

  const isValid = useIsValid('sign-up');
  const isSubmitting = useIsSubmitting('sign-up');

  const disabled = isSubmitting || !isValid;
  const isLoading = isSubmitting;

  return (
    <ValidatedForm
      id="sign-up"
      validator={SignUpFormValidator}
      onSubmit={(data, event) => {
        event.preventDefault();
        onSubmit(data);
      }}
      method="POST"
    >
      <div className="space-y-6">
        <div className="space-y-4">
          {error && (
            <Alert.Root color="danger">
              <Alert.Title>Something went wrong!</Alert.Title>
              <Alert.Description>{error}</Alert.Description>
            </Alert.Root>
          )}

          <div className="flex space-x-4">
            <Input.Root {...firstname.getInputProps()} type="text" placeholder="John">
              <Input.Header>
                <Input.Label>First name</Input.Label>
              </Input.Header>

              <Input.Field />

              {firstname.error && <Input.Error>{firstname.error}</Input.Error>}
            </Input.Root>

            <Input.Root {...lastname.getInputProps()} type="text" placeholder="Doe">
              <Input.Header>
                <Input.Label>Last name</Input.Label>
              </Input.Header>

              <Input.Field />

              {lastname.error && <Input.Error>{lastname.error}</Input.Error>}
            </Input.Root>
          </div>

          <Input.Root {...email.getInputProps()} type="email" placeholder="name@example.com" defaultValue={_email}>
            <Input.Header>
              <Input.Label>Email</Input.Label>
            </Input.Header>

            <Input.Field />

            {email.error && <Input.Error>{email.error}</Input.Error>}
          </Input.Root>

          <Input.Root {...password.getInputProps()} type="password">
            <Input.Header>
              <Input.Label>Password</Input.Label>
            </Input.Header>

            <Input.Field />

            {password.error && <Input.Error>{password.error}</Input.Error>}
          </Input.Root>
        </div>

        <div>
          <Paragraph>
            <Muted className="text-sm">
              By creating an account you agree to our{' '}
              <Link to="/terms-of-use" target="_blank">
                Terms of Use
              </Link>{' '}
              and{' '}
              <Link to="/privacy-policy" target="_blank">
                Privacy Policy
              </Link>
              .
            </Muted>
          </Paragraph>
        </div>

        <Button type="submit" size="lg" disabled={disabled} isLoading={isLoading} isFullWidth>
          Sign Up
        </Button>
      </div>
    </ValidatedForm>
  );
};
